/**
 * Created by Administrator on 2017/5/27.
 */

#create-topic {
  margin-top: 20px;
  margin-bottom: 50px;
  .topic-form-area {
    padding: 15px;
    .breadcrumb {
      background-color: #fff;
      padding-left: 0;
    }
    .editor {
      height: 500px !important;
    }
    .topic-submit-btn {
      padding: 3px 30px;
      border-radius: 10px;
    }
  }
}

#topic-view-page {
  margin-top: 20px;
  .button-area {
    position: fixed;
    right: 200px;
    bottom: 400px;
    z-index: 10;
    a {
      display: block;
      margin: 10px 0;
    }
  }
  @media (max-width: 1366px) {
    .button-area {
      position: fixed;
      right: 5px;
      bottom: 200px;
      z-index: 10000;
    }
  }
  @media (max-width: 1280px) {
    .button-area {
      position: fixed;
      right: 5px;
      bottom: 200px;
      z-index: 10000;
    }
  }
  .topic-content {
    padding: 20px;
    .topic-title {
      margin-top: 0;
      display: inline-block;
    }
    .topic-info {
      border-bottom: 1px solid #eee;
      p {
        display: inline-block;
      }
      .view-comment-counts {
        float: right;
        span {
          padding: 0 15px;
          font-size: 16px;
          i {
            font-size: 18px;
          }
        }
      }
      .topic-status {
        padding-right: 0;
      }
      span {
        &.is-top {
          color: #f65732;
          margin-right: 10px;
          font-weight: bold;
        }
        &.is-prime {
          color: #f65732;
          margin-right: 10px;
          font-weight: bold;
        }
      }
      a {
        color: $blue;
        font-weight: bold;
        margin-right:20px;
      }
    }
    .detail {
      padding: 10px;
      word-break: break-all;
    }
    .praise-area {
      text-align: center;
      a {
        color: $blue;
        width: 38px;
        height: 38px;
        border-radius: 19px;
        border: 1px solid $blue;
        display: inline-block;
        i {
          font-size: 24px;
          line-height: 36px;
        }

        &.rewards {
          i {
            font-style: normal;
            font-size: 20px;
            font-weight: 600;
          }
        }

        &:hover, &.has-praised {
          color: #fff;
          background-color: $blue;
        }
      }
    }
  }

  .comment-area {
    margin-top: 20px;
    padding: 10px;
  }
}

#reward-modal {
  .modal-content {
    width:380px;
    .modal-header {
      padding:10px 20px;
      background-color:$blue;
      color: #fff;
      button span {
        color: #fff;
      }
    }
    .modal-body {
      width:250px;
      margin:auto;
      p {
        color: #666;
        span {
          color: $blue;
        }
      }
      #reward-point-form {
        .reward-input {
          margin-top:20px;
          label {
            font-weight: normal;
            color: #666;
          }
          input {
            width:140px;
          }
        }
        .submit-btn {
          margin-top:20px;
          text-align: center;
        }
      }
    }
  }
}
.author-other-topics {
  padding: 20px 20px 18px 20px;
  margin-top: 30px;

  .title {
    margin-top: 0;
    font-size: 16px;
  }

  .author-other-topic-list {
    padding-left: 0;
    li {
      list-style: none;
      a {
        color: #666;
        &:hover {
          color: $blue;
        }
      }
      .topic-title {
        float: left;
        width: 85%;
        font-size: 14px;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
      }

      p {
        margin-bottom: 0;
        display: block;
        margin-top: 8px;
        float: right;
        span {
          &.like-count {
            color: $blue;
            font-size: 16px;
          }
        }
      }
    }
  }
}

//topic manager
.forum-topic-content {
  .search-form {
    padding: 10px 30px;
    label {
      font-weight: 500;
    }
    .btn {
      border-radius: 4px;
      padding: 3px 20px;
    }
    select {
      padding: 2px 10px;
    }
    .form-control {
      height: 30px;
    }
  }
  .table {
    margin: 10px 20px;
    width: auto;
    th {
      font-weight: 500;
      text-align: center;
      border-bottom: 0;
    }
    td {
      text-align: center;
      border-top: 0;
      i {
        font-style: normal;
        display: inline-block;
        width: 28px;
        height: 28px;
        color: #fff;
        border-radius: 14px;
        line-height: 29px;
        &.is-prime {
          background-color: $blue;
        }
        &.is-top {
          background-color: $green;
        }
      }
    }
  }
  p {
    &.no-record {
      font-size: 34px;
      color: #999;
      text-align: center;
      padding: 30px;
    }
  }
}

//forum member content
.forum-member-content {
  padding: 15px 30px;
  .set-role-btn {
    margin-top: 20px;
    a {
      margin-right: 10px;
    }
  }

  .table {
    margin-top: 15px;
    th {
      font-weight: 500;
      text-align: center;
      border-bottom: 0;
    }
    td {
      text-align: center;
      border-top: 0;
      i {
        font-style: normal;
        display: inline-block;
        width: 28px;
        height: 28px;
        color: #fff;
        border-radius: 14px;
        line-height: 29px;
        &.is-prime {
          background-color: $blue;
        }
        &.is-top {
          background-color: $green;
        }
      }
      a {
        &.not-rm-member {
          color: #999;
        }
      }
    }
  }
}

//forum-user-apply-content
.forum-user-apply-content {
  padding: 15px 30px;
  .check-btn {
    margin-top: 20px;
    a {
      margin-right: 10px;
      padding: 3px 20px;
      border-radius: 4px;
    }
  }
}

//ban talk list content
.forum-ban-talk-content {
  padding: 15px 30px;

  #ban-talk-modal {
    .modal-content {
      width: 350px;
      .modal-header {
        padding: 10px 15px;
        color: #fff;
        background-color: $blue
      }
      .modal-body {
        padding: 20px;
        form {
          margin: auto;
          width: 275px;
          .form-inline {
            margin-bottom: 10px;
            label {
              font-weight: 500;
              font-size: 16px;
            }
            .form-control {
              height: 30px;
            }
            select {
              padding: 0 10px;
            }
          }
          .btn {
            padding: 3px 20px;
          }
          .submit-btn {
            text-align: center;
          }
        }
      }
    }
  }
}

.forum-manager {
  .search-form {
    label {
      font-weight: 500;
    }
    .btn {
      border-radius: 4px;
      padding: 3px 20px;
    }
    select {
      padding: 2px 10px;
    }
    .form-control {
      height: 30px;
    }
  }

  .table {
    margin-top: 15px;
    th {
      font-weight: 500;
      text-align: center;
      border-bottom: 0;
    }
    td {
      text-align: center;
      border-top: 0;
      i {
        font-style: normal;
        display: inline-block;
        width: 28px;
        height: 28px;
        color: #fff;
        border-radius: 14px;
        line-height: 29px;
        &.is-prime {
          background-color: $blue;
        }
        &.is-top {
          background-color: $green;
        }
      }
      a {
        color: $blue;
        &.not-rm-member {
          color: #999;
        }
      }
    }
  }

  p {
    &.no-record {
      font-size: 34px;
      color: #999;
      text-align: center;
      padding: 30px;
    }
  }
}

//forum manager top menu
.forum-manager-menu-list {
  margin-top: 10px;
  background-color: #fff;
  //padding-bottom: 1px;
  border-bottom: 1px solid #eee;
  li {
    float: left;
    &:hover {
      background-color: #fff;
    }
    a {
      padding:10px 20px;
      color: #333;
      &:hover {
        color: $blue;
      }
    }
    &.active {
      background-color: #fff;
      a {
        color: $blue;
        background: #fff;
      }
    }

    &.return-back-forum {
      margin-top: 8px;
      margin-right:50px;
      a {
        border: #2758b9 1px solid;
        color: #2758b9;
        border-radius: 8px;
        padding: 3px 15px;
        background: #fff;
        &:hover, &:active, &:focus {
          background: #2758b9;
          color: #ffffff;
        }
      }
    }
  }
}
i.new-message {
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 6px;
    background: #6fc77f;
    right: 4px;
    top: 7px;
    position: absolute;
}

span.is-draft {
  color: #b14949;
  font-size: 18px;
}

.topic-content,.comment-area {
  img {
    max-width: 100%;
  }
}