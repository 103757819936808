.user-base-top {
  margin-bottom: 20px;
  padding-bottom: 15px;
  .top-img {
    img{
      width: 100%;
      height: auto;
    }
  }
  .base-info {
    position: relative;
    overflow: hidden;
    padding-left: 90px;
    margin-top: -170px;
    z-index: 2;

    & > * {
      display: inline-block;
      vertical-align: bottom;
    }
    .user-avatar {
      margin-right: 15px;
      img {
        width: 194px;
        height: 194px;
      }
    }
    .user-about {
      color: #333333;
      font-size: 14px;
      .nickname {
        font-size: 24px;
      }
      .about {
        margin-left: 15px;
      }
    }

    .user-action {
      position: absolute;
      right: 15px;
      bottom: 0;
      width: 140px;
      text-align: center;
      .btn:first-child {
        margin-bottom: 25px;
      }
      .btn {
        font-size: 16px;
        //font-weight: 600;
      }
    }
  }

  .blog-info {
    padding: 10px 20px;

    .honor-area, .point-area {
      font-size: 16px;
      width: 50%;
      float: left;
      height: 40px;
      padding: 3px 0;
      border-right: 1px solid #bdb9b7;
      & > * {
        display: inline-block;
        vertical-align: bottom;
      }

      .level {
        width: 20%;
        text-align: right;
      }
      .point {
        width: 25%;
        padding-left: 5%;
        color: #2758b9;
        a:hover {
          text-decoration: underline;
        }
      }
      .progress-box {
        width: 37%;
        .rate {
          font-size: 12px;
          text-align: right;
        }
        .progressbar {
          width: 100%;
          height: 15px;
          background: #dedede;
          border: 1px inset #acacac;
          border-radius: 8px;
          .complete-progress {
            background: #2758b9;
            border-radius: 8px;
          }
        }
      }
      .qa-area {
        width: 10%;
        text-align: center;
        font-size: 24px;
        color: #2758b9;
        vertical-align: bottom;
        padding-top: 4px;
        height: 34px;
      }
    }

    .point-area {
      border-right: none;

      .progress-box {
        .progressbar {
          .complete-progress {
            background: #ece62c;
          }
        }
      }
    }

  }
}

.static-title {
  font-size: 20px;
  //margin-bottom: 10px;
}

.achievement-area {
  padding: 15px 15px 50px;
  background: #ffffff;
  margin-bottom: 15px;

  .like-count, .mark-count {
    margin: 10px 0;
    i {
      font-size: 22px;
    }
    span {
      font-size: 16px;
      color: #2758b9;
    }
  }
}

.my-forum-area {
  margin-bottom: 15px;
  padding: 15px;

  .no-forum {
    color: #ff7272;
    text-align: center;
    height: 300px;
    font-size: 20px;
  }

  .fade-btn {
    a {
      color: #e2e2e2;
      font-size: 20px;
      display: block;
      text-align: center;

      &.hide {
        display: none;
      }
    }
  }

  .forum-list {
    height: 150px;
    overflow: hidden;

    &.open {
      height: auto;
    }

    .forum-item {
      color: $blue;
      height: 30px;
      line-height: 30px;
      font-size: 16px;

      &:hover {
        color: #999999;
      }

      & > * {
        display: inline-block;
      }
      img {
        width: 20px;
        height: 20px;
      }
      .role {
        color: #333333;
      }
    }
  }
}

.work-data-area {
  padding: 15px;
  .table {
    th, td {
      border: hidden;
      text-align: center;
    }
    td {
      color: #2758b9;
      font-size: 20px;
    }
  }
}

.action-bar {
  padding: 5px 20px;
  margin-bottom: 10px;
  .action {
    font-size: 18px;
    color: #333;
    margin-right: 40px;
    text-decoration: none;

    &.active, &:hover {
      color: #2758b9;
      font-size: 22px;
    }
  }
}

.sub-action {
  padding-left: 10px;
  margin-bottom: 10px;
  .action {
    padding: 5px 10px;
    display: inline-block;
    font-size: 16px;
    color: #333333;
    text-decoration: none;
    margin-right: 10px;

    &.active, &:hover {
      color: #2758b9;
    }
  }
}

.user-work-list {
  padding: 10px 15px;
  ul {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .user-work-item {
    width: calc(33.3% - 10px);
    float: left;
    list-style: none;
    margin: 0 5px 10px;
    background: #f2f2f2;
    padding: 0 10px 10px;
    border-radius: 8px;
    position: relative;

    &:hover {
      -webkit-box-shadow: 0 0 15px #999;
      box-shadow: 0 0 15px #999;
      .image-area .hover-area {
        display: block;
      }
      .author-area .author-name {
        color: $blue;
      }
      .description-area {
        color: #444444;
      }
    }

    .title-area {
      font-size: 15px;
      padding: 5px 0;
      position: relative;
      font-weight: 600;
    }
    .image-area {
      margin: 0 -10px;
      z-index: 2;
      position: relative;
      .hover-area {
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, .6);
        color: #ffffff;
        padding: 10px 20px;
        display: none;
        border-radius: 8px 8px 0 0;
        .date {
          font-size: 16px;
          p {
            margin: 0;
          }
          .year {
            font-size: 18px;
          }
        }
        .work-link {
          text-align: center;
          padding: 15px;
          a {
            padding: 2px 6px;
            color: $blue;
            background: #ffffff;
            text-decoration: none;
            font-size: 16px;

            &:hover {
              color: #ffffff;
              background: $blue;
            }
          }
        }
        .share {
          position: absolute;
          bottom: 10px;
          right: 20px;

          & > * {
            display: inline-block;
          }
          label {
            margin: 0;
          }
        }
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 8px 8px 0 0;
        max-height: 181px;
      }
    }
    .description-area {
      font-size: 16px;
      margin-top: 5px;
      padding: 10px 0;
      height: 60px;
      color: #999;
    }

    .data-area {
      z-index: 2;
      font-size: 16px;
      color: #999;
      cursor: auto;
      span {
        display: inline-block;
        width: 31%;
        label {
          display: inline;
          margin-bottom: 0;
          font-weight: normal;
          margin-left: 5px;
        }
      }
    }

    .author-area {
      margin: 0 -10px;
      padding: 10px 10px 0;
      border-top: 1px solid #999;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .border-decoration {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: 10px solid #05286d;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #05286d;
      border-right: 10px solid transparent;
      border-radius: 8px 0 0 0;
      text-align: center;
      z-index: 3;
    }

  }
}

.user-topic-list{
  padding: 0 20px;
  margin-bottom: 10px;

  .topic-item {
    padding: 15px 0;
    color: #666666;
    position: relative;
    border-bottom: 1px solid #e2e2e2;

    &:last-child {
      border-bottom: hidden;
    }

    &:hover .info .title,
    &:hover .info .abstract,
    &:hover .info .forum-info .author,
    &:hover .info .forum-info .forum {
      color: $blue;
    }
    .author-avatar {
      width: 60px;
      height: 60px;
      display: inline-block;
      margin-right: 20px;
      vertical-align: bottom;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      display: inline-block;
      width: 80%;
      vertical-align: bottom;
      .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 24px;
        .author {
          font-size: 12px;
          margin-left: 20px;
        }
      }
      .abstract {
        font-size: 12px;
      }
      .last-comment {
        font-size: 12px;
        margin-top: 5px;
      }

      .forum-info {
        font-size: 12px;
        margin-top: 5px;
        span {
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .data-area {
      font-size: 18px;
      position: absolute;
      bottom: 15px;
      right: 0;
      width: 240px;
      span {
        //margin-left: 30px;
        width: 80px;
        float: left;
        text-align: left;
        i {
          font-size: 22px;
        }
        label {
          font-weight: normal;
          color: $blue;
          margin-left: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.add-more-work, .add-more-topic {
  margin-top: 10px;
  font-size: 20px;
  text-align: center;
  padding: 10px;
  a {
    display: block;
    color: #333333;
    text-decoration: none;
  }
}

.follow-user-list {
  .follow-user-item {
    margin-top: 10px;
    padding: 8px 20px;
    overflow: hidden;
    position: relative;
    height: 80px;

    &.no-follow {
      font-size: 24px;
      text-align: center;
      line-height: 64px;
    }
    .user-image {
      width: 64px;
      float: left;
      img {
        width: 100%;
        height: auto;
      }
    }

    .user-info {
      float: left;
      margin-left: 20px;
      .first-line {
        font-size: 12px;
        .name {
          font-size: 24px;
          margin-right: 20px;
        }
      }
      .second-line {
        font-size: 12px;
        margin-top: 5px;
        span {
          margin-right: 20px;
        }
      }
    }
    .cancel-follow-btn, .add-follow-btn {
      position: absolute;
      font-size: 18px;
      padding: 0;
      display: block;
      width: 130px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      top: 25px;
      right: 20px;
      z-index: 2;
      border-radius: 8px;
    }
    .profile-link {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

#point-page {
  .menu {
    a {
      font-size: 18px;
      color: #999;
      text-decoration: none;
    }
    .active {
      color: #2758b9;
    }
  }
  .col-md-3 {
    padding:0 20px;

  }
}