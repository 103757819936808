.scratchCreativationGame-game1-page {
    width: 100%;
    min-height: 1000px;
    background-image: url('../img/game/bk.jpg');
    background-size: cover;
    padding-bottom: 18rem;
    .sign-up-modal {
        margin: 0 auto;
        margin-top: calc( 50vh - 280px);
        height: 500px;
        width: 760px;
        background-color: #337dff;
        padding: 12px;
        border-radius: 8px;
        .second-floor-modal {
            width: 100%;
            height: 100%;
            background-color: #1253bb;
            padding: 11px;
            border-radius: 8px;
            .third-floor-modal {
                width: 100%;
                height: 100%;
                background-color: #fff;
                border-radius: 8px;
                .navibar {
                    width: 100%;
                    height: 60px;
                    background-color: #fff;
                    zoom: 1;
                    a {
                        display: block;
                        float: left;
                        width: 50%;
                        height: 100%;
                        line-height: 60px;
                        color: #1253bb;
                        font-size: 24px;
                        text-align: center;
                        text-decoration: none;
                    }
                    .unactivel {
                        background-color: #1253bb;
                        border-bottom-left-radius: 8px;
                        color: #fff;
                    }
                    .unactiver {
                        background-color: #1253bb;
                        border-bottom-right-radius: 8px;
                        color: #fff;
                    }
                }
                .navibar:after {
                    clear: both;
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                }
                .text-area {
                    width: 100%;
                    height: 394px;
                    .input-cell {
                        width: 300px;
                        height: 36px;
                        padding: 10px 20px;
                        border: 3px solid #1253bb;
                        color: #696969;
                        font-size: 20px;
                        margin: 0 auto;
                        display: block;
                        margin-top: 30px;
                        border-radius: 20px;
                        outline: none;
                    }
                    .submit-cell {
                        background-color: #1253bb;
                        color: #fff;
                        line-height: 36px;
                        padding: 0;
                    }
                    .person-sign-up {
                        width: 100%;
                        height: 100%;
                        .first-input {
                            margin-top: 100px;
                        }
                    }
                    .grounp-sign-up {
                        width: 100%;
                        height: 100%;
                        .first-input {
                            margin-top: 45px;
                        }
                    }
                }
            }
        }
    }
    .un-sign-up-modal {
        margin: 0 auto;
        margin-top: calc( 50vh - 180px);
        height: 355px;
        width: 560px;
        background-color: #337dff;
        padding: 8px;
        border-radius: 8px;
        .second-floor-modal {
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 8px;
            text-align: center;
            .info {
                height: 262px;
                line-height: 262px;
                width: 100%;
                font-size: 24px;
                color: #1253bb;
            }
            .button-cell {
                width: 300px;
                height: 36px;
                line-height: 36px;
                background-color: #1253bb;
                color: #fff;
                margin: 0 auto;
                font-size: 22px;
                outline: none;
                border: 0;
                border-radius: 10px;
            }
        }
    }
    .mod-funny-door {
        width: 100%;
        background: url('../img/game/729313177059543160.png') no-repeat center 0;
        min-height: 764px;
        padding-top: 577px;
        .mod-title-btn {
            height: 110px;
            margin: 0 auto;
            width: 840px;
            zoom: 1;
            padding-left: 10px;
            text-align: left;
            .game-name {
                font-size: 30px;
                color: #333;
                text-shadow: 1px 1px 2px #ddd;
            }
            .game-subname {
                font-size: 24px;
                color: #333;
                width: 50%;
                text-align: right;
                display: inline-block;
                height: 44px;
            }
            .cell-btn {
                float: right;
                width: 155px;
                position: relative;
                height: 44px;
                .hover-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: -12px;
                    left: 0;
                    z-index: 1;
                    transition: transform 0.3s;
                }
                .bg-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 8px;
                    left: 0;
                    z-index: 0;
                }
            }
            .game-subname-btb {
                width: 100%;
                height: 44px;
            }

            .last-of-type {
                margin-right: 20px;
            }
            .cell-btn:hover .hover-img {
                transform: translateY(12px);
            }
        }
        .mod-title-btn:after {
            clear: both;
            content: '';
            display: block;
            width: 0;
            height: 0;
            visibility: hidden;
        }
    }
    .mod-game-basic-situation {
        width: 100%;
        background: url('../img/game/1_222.png') no-repeat center 0;
        min-height: 1000px;
        margin-top: 80px;
        position: relative;
        padding-top: 217px;
        .game-bright-spot {
            width: 100%;
            height: 67px;
            margin-bottom: 17px;
            .ea-bright-spot {
                width: 31.46%;
                height: 100%;
                float: left;
                margin-right: 2.81%;
                .img-icon {
                    height: 59px;
                    margin-right: 0.417vw;
                    float: left;
                }
                .bring-spot {
                    height: 100%;
                    font-size: 14px;
                    overflow: hidden;
                    line-height: 24px;
                }
            }
            .ea-bright-spot:last-of-type {
                margin-right: 0;
            }
        }
        .cell-as {
            width: 1016px;
            height: 110px;
            position: absolute;
            top: -20px;
            left: calc( 50vw - 508px);
            padding-left: 0;
            li {
                float: left;
                list-style: none;
                width: 17.39%;
                height: 100%;
                margin-right: 3.2625%;
                text-align: center;
                position: relative;
                background-size: 100%;
                background-repeat: no-repeat;
                .a {
                    width: 100%;
                    margin: 0 auto;
                    text-decoration: none;
                    height: 60px;
                    display: block;
                    line-height: 60px;
                    color: #fff;
                    font-size: calc( 100vw * 0.0114);
                }
            }
            .unactive-li {
                background-image: url('../img/game/790736632879448205.png');
            }
            .active-li {
                background-image: url('../img/game/73386272644315799.png');
            }
            li:last-of-type {
                margin-right: 0;
            }
        }
        .cell-title {
            width: 380px;
            height: 52px;
            line-height: 52px;
            margin: 0 auto;
            color: #fff000;
            font-size: 24px;
            text-align: center;
            letter-spacing: 2px;
        }
        .cell-text {
            width: 876px;
            height: 500px;
            margin: 0 auto;
            margin-top: 96px;
            &-title {
                text-align: left;
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 24px;
            }
            .game-intro {
                margin-top: 20px;
                font-size: 14px;
                line-height: 24px;
                p {
                    text-indent: 20px;
                }
            }
        }
        .cell-text-subtitle {
            font-size: calc( 100vw * 0.0125);
            margin-top: 20px;
            margin-bottom: calc( 100vw * 0.0052);
        }
        .cell-process {
            margin-top: 18px;
            font-size: 14px;
            margin-bottom: 40px;
            i {
                margin: 0 calc( 100vw * 0.0033);
                font-size: 1rem;
                color: #317efe;
            }
            .big-xu {
                font-size: 14px;
                display: inline-block;
                width: 14%;
            }
        }
        .time-manage {
            width: 100%;
            margin-top: 1rem;
            position: relative;
            font-size: 14px;
            zoom: 1;
            vertical-align: middle;
            .big-xu {
                font-size: 14px;
                display: block;
                width: 14%;
                float: left;
            }
            .cicle-div {
                position: absolute;
                top: -5px;
                left: calc( 100vw * 0.0625);
                height: calc( 100vw * 0.01354);
                width: calc( 100vw * 0.1302);
                overflow: hidden;
                .cicle {
                    position: absolute;
                    top: 0px;
                    left: calc( 100vw * -0.0677);
                    width: calc( 100vw * 0.2708);
                    height: calc( 100vw * 0.2708);
                    border: 1px dashed #2758b9;
                    border-radius: 50%;
                }
            }
            .cicle-second-div {
                position: absolute;
                top: -5px;
                left: calc( 100vw * 0.25);
                height: calc( 100vw * 0.01354);
                width: calc( 100vw * 0.1302);
                overflow: hidden;
                .cicle {
                    position: absolute;
                    top: 0px;
                    left: calc( 100vw * -0.0677);
                    width: calc( 100vw * 0.2708);
                    height: calc( 100vw * 0.2708);
                    border: 1px dashed #2758b9;
                    border-radius: 50%;
                }
            }
            .each-time {
                float: left;
                width: 23.744%;
                margin-right: 5%;
                text-align: left;
                vertical-align: middle;
                font-size: 14px;
                line-height: 24px;
            }
            .not-margin-right {
                margin-right: 0;
            }
        }
        .time-manage:after {
            clear: both;
            content: '';
            display: block;
            width: 0;
            height: 0;
            visibility: hidden;
        }
        .no-margin {
            margin-top: 0;
        }
    }
    .mod-game-basic-situation-without-text {
        width: 100%;
        background: url('../img/game/163515252390175154.png') no-repeat center 0;
        height: 302px;
        margin-top: 80px;
        position: relative;
        padding-top: 217px;
        .cell-title {
            width: 380px;
            height: 52px;
            line-height: 52px;
            margin: 0 auto;
            color: #fff000;
            font-size: 24px;
            text-align: center;
            letter-spacing: 2px;
        }
        .cell-as {
            width: 1016px;
            height: 110px;
            position: absolute;
            top: -20px;
            left: calc( 50vw - 508px);
            padding-left: 0;
            li {
                float: left;
                list-style: none;
                width: 17.39%;
                height: 100%;
                margin-right: 3.2625%;
                text-align: center;
                position: relative;
                background-size: 100%;
                background-repeat: no-repeat;
                .a {
                    width: 100%;
                    margin: 0 auto;
                    text-decoration: none;
                    height: 60px;
                    display: block;
                    line-height: 60px;
                    color: #fff;
                    font-size: calc( 100vw * 0.0114);
                }
            }
            .unactive-li {
                background-image: url('../img/game/790736632879448205.png');
            }
            .active-li {
                background-image: url('../img/game/73386272644315799.png');
            }
            li:last-of-type {
                margin-right: 0;
            }
        }
    }
    .mod-game-text {
        width: 1160px;
        height: 100%;
        background-color: #317efe;
        margin: 0 auto;
        padding: 20px;
        border-radius: 8px; //border-top-right-radius: 20px;
        //margin-top: -20px;
        .second-floor {
            padding: 10px;
            width: 100%;
            height: 100%;
            background-color: #1352bb;
            border-radius: 8px;
            .navibars {
                width: 100%;
                height: 60px;
                margin-bottom: 10px;
                .left-navibar {
                    width: 390px;
                    height: 100%;
                    line-height: 30px;
                    float: left;
                    padding-left: 10px;
                    .a {
                        margin-right: 45px;
                        display: inline-block;
                        height: 30px;
                        line-height: 30px;
                        text-align: left;
                        width: 70px;
                        color: #fff;
                        text-decoration: none;
                        font-size: .8rem;
                    }
                    .active {
                        color: #fff000;
                    }
                }
                .cell-search {
                    width: 300px;
                    height: 30px;
                    float: right;
                    padding: 2px 8px 5px 5px;
                    border-radius: 8px;
                    background-color: #fff;
                    margin-top: 16px;
                    input {
                        border: 0;
                        height: 100%;
                        width: 260px;
                        outline: none;
                        color: #696969;
                    }
                    i {
                        width: 20px;
                        height: 100%;
                    }
                }
            }
            .third-floor {
                width: 100%;
                min-height: 590px;
                background-color: #fff;
                border-radius: 8px;
                padding: 20px;
                .game-detail-subtitle {
                    font-size: 16px;
                    text-indent: 24px;
                }
                .game-detail-title {
                    font-size: 32px;
                    margin-top: 60px;
                }
                .cell-game-detail {
                    margin-top: 30px;
                }
                .cell-works-title {
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 22px;
                    color: #1352bb;
                }
                .cell-works {
                    width: 100%;
                    height: 360px;
                    margin-top: 50px;
                    .work {
                        width: 23.5%;
                        height: 100%;
                        margin-right: 2%;
                        float: left;
                        position: relative;
                        border: 3px solid #2758b9;
                        background-color: #fff;
                        border-radius: 10px;
                        .workbg {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            z-index: 10;
                            .left-top-sign-bg {
                                position: absolute;
                                top: 0px;
                                left: 1px;
                                width: 0;
                                height: 0;
                                z-index: 0;
                                border-style: solid;
                                border-width: 65px 65px 0 0;
                            }
                            .person-sign {
                                border-color: #e58c00 transparent transparent transparent;
                            }
                            .cos-sign {
                                border-color: #05296d transparent transparent transparent;
                            }
                            .left-top-sign {
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                z-index: 1;
                                width: 65px;
                                height: 65px;
                                color: #fff;
                                padding-left: 5px;
                                i {
                                    display: block;
                                }
                                p {
                                    margin-bottom: 3px;
                                }
                                background-color: transparent;
                            }
                            .img {
                                height: 175px;
                                width: 100%;
                                .realImg {
                                    width: 100%;
                                    height: 100%;
                                    border-top-left-radius: 7px;
                                    border-top-right-radius: 7px;
                                }
                            }
                            .title {
                                width: 92%;
                                margin: 0 auto;
                                font-size: 18px;
                                color: #000;
                                text-align: left;
                            }
                            .detail {
                                width: 92%;
                                min-height: 40px;
                                line-height: 40px;
                                margin: 0 auto;
                                text-align: left;
                                .num {
                                    width: 92%;
                                    margin: 0 auto;
                                    font-size: 16px;
                                    color: #999;
                                    text-align: left;
                                }
                                .firstIcon {
                                    color: #999;
                                }
                                .icon {
                                    color: #999;
                                    margin-left: 5%;
                                }
                            }
                            .declaration {
                                width: 92%;
                                margin: 0 auto;
                                font-size: 14px;
                                color: #999;
                                text-align: left;
                            }
                            .line {
                                height: 6px;
                                border-bottom: 1px solid #e3e3e3;
                                clear: both;
                            }
                            .authorTime {
                                min-height: 50px;
                                line-height: 50px;
                                width: 92%;
                                margin: 0 auto;
                                width: 92%;
                                margin: 0 auto;
                                text-align: left;
                                color: #999;
                                .author {
                                    margin-left: 8px;
                                }
                                .img {
                                    width: 24px;
                                    height: 24px;
                                }
                                .time {
                                    float: right;
                                }
                            }
                        }
                        .gray {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 175px;
                            width: 100%;
                            z-index: 20;
                            background-color: #000;
                            opacity: 0.6;
                            border-top-left-radius: 7px;
                            border-top-right-radius: 7px;
                            color: #fff;
                        }
                        .graytxt {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 175px;
                            width: 100%;
                            z-index: 30;
                            border-top-left-radius: 7px;
                            border-top-right-radius: 7px;
                            text-align: center;
                            .data {
                                margin: 0 auto;
                                margin-top: 16px;
                                width: 92%;
                                text-align: left;
                                color: #fff;
                            }
                            .year {
                                margin: 0 auto;
                                height: 30px;
                                line-height: 30px;
                                width: 92%;
                                text-align: left;
                                color: #fff;
                                font-size: 18px;
                            }
                        }
                        .intoDetail {
                            margin-top: 15px;
                            width: 100%;
                            text-align: center;
                        }
                        .shares {
                            margin: 0 auto;
                            margin-top: 15px;
                            height: 40px;
                            line-height: 40px;
                            width: 92%;
                            text-align: right;
                            .share {
                                color: #fff;
                                font-size: 18px;
                                margin-top: 5px;
                            }
                        }
                    }
                    .work:last-of-type {
                        margin-right: 0;
                    }
                }
                .cell-works:after {
                    clear: both;
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                }
            }
            .big-padding {
                padding: 40px;
            }
        }
    }
    .mod-game-info {
        margin: 0 auto;
        margin-top: 20px;
        width: 990px;
        min-height: 1140px;
        border: 3px solid #eaec2f;
        border-radius: 8px;
        padding: 12px;
        .mod-real-game-info {
            width: 100%;
            height: 100%;
            background-color: #e1ecfe;
            border-radius: 8px;
            text-align: left;
            padding-top: 0;
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 40px;
            .cell-game-info-title {
                width: 470px;
                height: 70px;
                margin: 0 auto;
                position: relative;
                .bg-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                }
                .text {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    color: #fff000;
                    font-size: 2.0rem;
                    line-height: 3.0rem;
                    text-align: center;
                    letter-spacing: .2rem;
                }
            }
            .cell-title {
                text-align: left;
                font-size: 24px;
            }
            .awards {
                width: 100%;
                min-height: 120px;
                margin-top: 20px;
                margin-bottom: 20px;
                zoom: 1;
                padding-left: 4%;
                .award {
                    width: 100%;
                    height: 100%;
                    margin-right: 2rem;
                    &-title {
                        font-size: 18px;
                    }
                    &-subtitle {
                        font-size: 14px;
                        line-height: 24px;
                        .prize {
                            width: 18%;
                            display: inline-block;
                        }
                        .big-money {
                            font-size: 14px;
                        }
                    }
                    &-subtitle:first-of-type {
                        margin-top: 20px;
                    }
                    .tip {
                        color: #317efe;
                    }
                }
                .award:last-of-type {
                    margin-right: 0;
                }
            }
            .awards:after {
                clear: both;
                content: '';
                display: block;
                width: 0;
                height: 0;
                visibility: hidden;
            }
            .cell-info-intro {
                margin-top: 20px;
                font-size: 14px;
                line-height: 24px;
            }
            .work-type {
                margin-top: 20px;
                font-size: 18px;
            }
            .work-claim {
                margin-top: 20px;
                font-size: 18px;
            }
            .area-rule {
                margin-top: 20px;
            }
        }
    }
    .mod-judges-works {
        margin: 0 auto;
        margin-top: 45px;
        width: 990px;
        min-height: 1080px;
        border: 3px solid #eaec2f;
        border-radius: 8px;
        padding: 15px;
        .mod-real-judges-works {
            width: 100%;
            height: 100%;
            background-color: #e1ecfe;
            border-radius: 8px;
            text-align: left;
            padding-top: 40px;
            padding-left: 76px;
            padding-right: 76px;
            padding-bottom: 40px;
            .cell-judges-title {
                width: 470px;
                height: 70px;
                margin: 0 auto;
                position: relative;
                .bg-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                }
                .text {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    color: #fff000;
                    font-size: 40px;
                    line-height: 60px;
                    text-align: center;
                    letter-spacing: 4px;
                }
            }
            .cell-judges {
                width: 812px;
                margin: 0 auto;
                margin-top: 45px;
                min-height: 214px;
                zoom: 1;
                text-align: center;
                .cell-judge {
                    width: 20%;
                    height: 100%; //margin-right: 3.7rem;
                    float: left;
                    .judge-img {
                        display: block;
                        width: 75%;
                        margin: auto;
                    }
                    .judge-name {
                        margin-top: 10px;
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                    .judge-job {
                        font-size: 16px;
                        margin-bottom: 2px;
                    }
                }
                .cell-judge:last-of-type {
                    margin-right: 0;
                }
            }
            .cell-judges:after {
                clear: both;
                content: '';
                display: block;
                width: 0;
                height: 0;
                visibility: hidden;
            }
            .cell-works-title {
                margin: 0 auto;
                margin-top: 90px;
                width: 470px;
                height: 70px;
                position: relative;
                .bg-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                }
                .text {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    color: #fff000;
                    font-size: 40px;
                    line-height: 60px;
                    text-align: center;
                    letter-spacing: 4px;
                }
            }
            .cell-works {
                width: 100%;
                height: 360px;
                margin-top: 50px;
                .work {
                    width: 32%;
                    height: 100%;
                    margin-right: 2%;
                    float: left;
                    position: relative;
                    border: 3px solid #2758b9;
                    background-color: #fff;
                    border-radius: 10px;
                    .workbg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        z-index: 10;
                        .left-top-sign-bg {
                            position: absolute;
                            top: 0px;
                            left: 1px;
                            width: 0;
                            height: 0;
                            z-index: 0;
                            border-style: solid;
                            border-width: 65px 65px 0 0;
                        }
                        .person-sign {
                            border-color: #e58c00 transparent transparent transparent;
                        }
                        .cos-sign {
                            border-color: #05296d transparent transparent transparent;
                        }
                        .left-top-sign {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            z-index: 1;
                            width: 65px;
                            height: 65px;
                            color: #fff;
                            padding-left: 5px;
                            i {
                                display: block;
                            }
                            p {
                                margin-bottom: 3px;
                            }
                            background-color: transparent;
                        }
                        .img {
                            height: 175px;
                            width: 100%;
                            .realImg {
                                width: 100%;
                                height: 100%;
                                border-top-left-radius: 7px;
                                border-top-right-radius: 7px;
                            }
                        }
                        .title {
                            width: 92%;
                            margin: 0 auto;
                            font-size: 18px;
                            color: #000;
                            text-align: left;
                        }
                        .detail {
                            width: 92%;
                            min-height: 40px;
                            line-height: 40px;
                            margin: 0 auto;
                            text-align: left;
                            .num {
                                width: 92%;
                                margin: 0 auto;
                                font-size: 16px;
                                color: #999;
                                text-align: left;
                            }
                            .firstIcon {
                                color: #999;
                            }
                            .icon {
                                color: #999;
                                margin-left: 5%;
                            }
                        }
                        .declaration {
                            width: 92%;
                            margin: 0 auto;
                            font-size: 14px;
                            color: #999;
                            text-align: left;
                        }
                        .line {
                            height: 6px;
                            border-bottom: 1px solid #e3e3e3;
                            clear: both;
                        }
                        .authorTime {
                            min-height: 50px;
                            line-height: 50px;
                            width: 92%;
                            margin: 0 auto;
                            width: 92%;
                            margin: 0 auto;
                            text-align: left;
                            color: #999;
                            .author {
                                margin-left: 8px;
                            }
                            .img {
                                width: 24px;
                                height: 24px;
                            }
                            .time {
                                float: right;
                            }
                        }
                    }
                    .gray {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 175px;
                        width: 100%;
                        z-index: 20;
                        background-color: #000;
                        opacity: 0.6;
                        border-top-left-radius: 7px;
                        border-top-right-radius: 7px;
                        color: #fff;
                    }
                    .graytxt {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 175px;
                        width: 100%;
                        z-index: 30;
                        border-top-left-radius: 7px;
                        border-top-right-radius: 7px;
                        text-align: center;
                        .data {
                            margin: 0 auto;
                            margin-top: 16px;
                            width: 92%;
                            text-align: left;
                            color: #fff;
                        }
                        .year {
                            margin: 0 auto;
                            height: 30px;
                            line-height: 30px;
                            width: 92%;
                            text-align: left;
                            color: #fff;
                            font-size: 18px;
                        }
                    }
                    .intoDetail {
                        margin-top: 15px;
                        width: 100%;
                        text-align: center;
                    }
                    .shares {
                        margin: 0 auto;
                        margin-top: 15px;
                        height: 40px;
                        line-height: 40px;
                        width: 92%;
                        text-align: right;
                        .share {
                            color: #fff;
                            font-size: 18px;
                            margin-top: 5px;
                        }
                    }
                }
                .work:last-of-type {
                    margin-right: 0;
                }
            }
            .cell-works:after {
                clear: both;
                content: '';
                display: block;
                width: 0;
                height: 0;
                visibility: hidden;
            }
            .cell-see-more-work {
                width: 100%;
                text-align: right;
                margin-top: 44px;
                color: #347aec;
                font-size: 24px;
                i {
                    font-weight: bold;
                }
            }
        }
    }
    .mod-cos {
        margin: 0 auto;
        margin-top: 32px;
        width: 990px;
        min-height: 508px;
        border: 3px solid #eaec2f;
        border-radius: 8px;
        padding: 6px 15px;
        .mod-real-cos {
            width: 100%;
            height: 100%;
            background-color: #e1ecfe;
            border-radius: 8px;
            text-align: left;
            padding-top: 34px;
            padding-left: 66px;
            padding-right: 66px;
            padding-bottom: 40px;
            .cell-cos-title {
                width: 470px;
                height: 70px;
                margin: 0 auto;
                position: relative;
                .bg-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                }
                .text {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    color: #fff000;
                    font-size: 40px;
                    line-height: 60px;
                    text-align: center;
                    letter-spacing: 4px;
                }
            }
            .cell-cos-imgs {
                width: 100%;
                height: 86px;
                margin-top: 50px;
                .cell-cos-img {
                    width: 30%;
                    height: 100%;
                    margin-right: 5%;
                    display: block;
                    float: left;
                }
                .cell-cos-img:last-of-type {
                    margin-right: 0;
                }
            }
            .cell-cos-imgs:after {
                clear: both;
                content: '';
                display: block;
                width: 0;
                height: 0;
                visibility: hidden;
            }
            .cell-cos-imgs:last-of-type {
                margin-top: 20px;
            }
            .cell-info {
                margin-top: 20px;
                p {
                    margin-bottom: 20px;
                    font-size: 14px;
                    line-height: 24px;
                }
                b {
                    font-size: 18px;
                }
            }
        }
    }
}

.scratchCreativationGame-phoneGame-page {
    width: 100%;
    min-height: 1000px;
    background-image: url('../img/game/mbk.png');
    background-size: cover;
    .sign-up-modal {
        margin: 0 auto;
        margin-top: calc( 50vh - 280px);
        height: 500px;
        width: 90%;
        background-color: #337dff;
        padding: 12px;
        border-radius: 8px;
        .second-floor-modal {
            width: 100%;
            height: 100%;
            background-color: #1253bb;
            padding: 11px;
            border-radius: 8px;
            .third-floor-modal {
                width: 100%;
                height: 100%;
                background-color: #fff;
                border-radius: 8px;
                .navibar {
                    width: 100%;
                    height: 60px;
                    background-color: #fff;
                    zoom: 1;
                    a {
                        display: block;
                        float: left;
                        width: 50%;
                        height: 100%;
                        line-height: 60px;
                        color: #1253bb;
                        font-size: 24px;
                        text-align: center;
                        text-decoration: none;
                    }
                    .unactivel {
                        background-color: #1253bb;
                        border-bottom-left-radius: 8px;
                        color: #fff;
                    }
                    .unactiver {
                        background-color: #1253bb;
                        border-bottom-right-radius: 8px;
                        color: #fff;
                    }
                }
                .navibar:after {
                    clear: both;
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                }
                .text-area {
                    width: 100%;
                    height: 394px;
                    .input-cell {
                        width: 80%;
                        height: 36px;
                        padding: 10px 20px;
                        border: 3px solid #1253bb;
                        color: #696969;
                        font-size: 20px;
                        margin: 0 auto;
                        display: block;
                        margin-top: 30px;
                        border-radius: 20px;
                        outline: none;
                    }
                    .submit-cell {
                        background-color: #1253bb;
                        color: #fff;
                        line-height: 36px;
                        padding: 0;
                    }
                    .person-sign-up {
                        width: 100%;
                        height: 100%;
                        .first-input {
                            margin-top: 100px;
                        }
                    }
                    .grounp-sign-up {
                        width: 100%;
                        height: 100%;
                        .first-input {
                            margin-top: 45px;
                        }
                    }
                }
            }
        }
    }
    .un-sign-up-modal {
        margin: 0 auto;
        margin-top: calc( 50vh - 180px);
        height: 355px;
        width: 90%;
        background-color: #337dff;
        padding: 8px;
        border-radius: 8px;
        .second-floor-modal {
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 8px;
            text-align: center;
            .info {
                height: 262px;
                line-height: 262px;
                width: 100%;
                font-size: 24px;
                color: #1253bb;
            }
            .button-cell {
                width: 300px;
                height: 36px;
                line-height: 36px;
                background-color: #1253bb;
                color: #fff;
                margin: 0 auto;
                font-size: 22px;
                outline: none;
                border: 0;
                border-radius: 10px;
            }
        }
    }
    .mod-funny-door {
        width: 100%;
        background-image: url('../img/game/移动端头图.png');
        background-size: 100%;
        background-repeat: no-repeat;
        min-height: 100vw;
        padding-top: calc( 100vw * 0.72);
        .mod-title-btn {
            height: 5.5rem;
            margin: 0 auto;
            width: calc( 100vw * 0.83);
            zoom: 1;
            padding-left: 0.5rem;
            text-align: left;
            text-align: center;
            .game-name {
                font-size: 1.4rem;
                color: #3a82f8;
                text-shadow: 1px 1px 2px #ddd;
                margin-top: 0;
                margin-bottom: .6rem;
            }
            .special-game-name {
                width: 80%;
                text-align: right;
            }
            .cell-btn {
                float: right;
                width: calc( 100vw * 0.07812);
                position: relative;
                height: calc( 100vw * 0.0229);
                .hover-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: -.6rem;
                    left: 0;
                    z-index: 1;
                    transition: transform 0.3s;
                }
                .bg-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: .4rem;
                    left: 0;
                    z-index: 0;
                }
            }
            .last-of-type {
                margin-right: 1rem;
            }
            .cell-btn:hover .hover-img {
                transform: translateY(.6rem);
            }
        }
        .mod-title-btn:after {
            clear: both;
            content: '';
            display: block;
            width: 0;
            height: 0;
            visibility: hidden;
        }
    }
    .mod-game-info {
        margin: 0 auto;
        margin-top: 5rem;
        width: 96%;
        min-height: 55rem;
        border: 3px solid #eaec2f;
        border-radius: 8px;
        padding: .6rem;
        .mod-real-game-info {
            width: 100%;
            height: 100%;
            background-color: #e1ecfe;
            border-radius: 8px;
            text-align: left;
            padding-top: 1.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 2rem;
            position: relative;
            .cell-game-info-title {
                width: 23.5rem;
                height: 3.5rem;
                margin: 0 auto;
                position: absolute;
                top: -2rem;
                left: calc( 50vw - 12.75rem);
                .bg-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                }
                .text {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    color: #fff000;
                    font-size: 2.0rem;
                    line-height: 3.0rem;
                    text-align: center;
                    letter-spacing: .2rem;
                }
            }
            .cell-title {
                text-align: left;
                font-size: 1.8rem;
            }
            .cell-subtitle {
                font-size: 1.2rem;
            }
            .clear-float-div {
                zoom: 1;
            }
            .clear-float-div:after {
                clear: both;
                content: '';
                display: block;
                width: 0;
                height: 0;
                visibility: hidden;
            }
            .cell-game-time {
                text-align: left;
                width: 49%;
                font-size: 1.4rem;
                margin-top: 0;
            }
            .inline-block {
                display: inline-block;
            }
            .awardss {
                width: 100%;
                min-height: 6rem;
                margin-top: 0.5rem;
                margin-bottom: 3.5rem;
                zoom: 1;
                .award {
                    width: 45%;
                    height: 100%;
                    margin-right: 5%;
                    float: left;
                    &-title {
                        font-size: 1.8rem;
                    }
                    &-subtitle {
                        font-size: .9rem;
                        .big-money {
                            font-size: 1.2rem;
                        }
                    }
                    &-subtitle:first-of-type {
                        margin-top: .9rem;
                    }
                }
                .award:last-of-type {
                    margin-right: 0;
                }
            }
            .awardss:after {
                clear: both;
                content: '';
                display: block;
                width: 0;
                height: 0;
                visibility: hidden;
            }
            .awards {
                width: 100%;
                min-height: 6rem;
                margin-top: 1rem;
                margin-bottom: 3.5rem;
                zoom: 1;
                .award {
                    width: 100%;
                    height: 100%;
                    margin-right: 2rem;
                    &-title {
                        font-size: 1.2rem;
                    }
                    &-subtitle {
                        font-size: .9rem;
                        .prize {
                            width: 20%;
                            display: inline-block;
                        }
                        .big-money {
                            font-size: 1.2rem;
                        }
                    }
                    &-subtitle:first-of-type {
                        margin-top: .9rem;
                    }
                }
                .award:last-of-type {
                    margin-right: 0;
                }
            }
            .cell-info-intro {
                margin-top: 1rem;
                font-size: 1rem;
            }
            .work-type {
                margin-top: 3rem;
                font-size: 1rem;
            }
            .work-claim {
                margin-top: 1.5rem;
                font-size: 1rem;
            }
            .area-rule {
                margin-top: 3rem;
            }
        }
    }
    .mod-game-basic-situation-without-text {
        width: 100%;
        background-image: url('../img/game/808591433328980018.png');
        background-size: 100%;
        background-repeat: no-repeat;
        height: calc( 100vw * 0.2586);
        margin-top: 4rem;
        padding-top: 10px;
        .cell-btns {
            width: 100%;
            height: 46px;
            .cell-btn {
                width: 50%;
                text-align: center;
                height: 100%;
                line-height: 46px;
                float: left;
                .btn {
                    width: 90px;
                    height: 27.2px;
                    position: relative;
                    .hover-img {
                        width: 100%;
                        position: absolute;
                        top: -10px;
                        left: 0;
                        z-index: 1;
                    }
                    .bg-img {
                        width: 100%;
                        position: absolute;
                        top: .4rem;
                        left: 0;
                        z-index: 0;
                    }
                }
            }
        }
        .cell-search {
            width: 81.6%;
            margin: 0 auto;
            height: 25px;
            text-align: center;
            .search-input {
                width: 81.69%;
                float: left;
                height: 25px;
                padding: 6px 7px;
                margin-right: 6px;
                border: 0;
                outline: none;
                border-radius: 4px;
            }
            .search-btn {
                width: 16.33%;
                float: right;
                color: #2758b9;
                height: 25px;
                border: 1px solid #ddd;
                background-color: #f7eec4;
                display: inline-block;
                border-radius: 4px;
            }
        }
        .cell-title {
            text-align: center;
            font-size: 2.4rem;
            color: #fff000;
        }
    }
    .mod-game-text {
        width: 96%;
        height: 100%;
        background-color: #317efe;
        margin: 0 auto;
        padding: 20px;
        border-radius: 8px; //border-top-right-radius: 20px;
        //margin-top: -20px;
        .second-floor {
            padding: 10px;
            width: 100%;
            height: 100%;
            background-color: #1352bb;
            border-radius: 8px;
            .third-floor {
                width: 100%;
                background-color: #fff;
                border-radius: 8px;
                padding: 20px;
                .game-detail-subtitle {
                    font-size: .8rem;
                }
                .game-detail-title {
                    font-size: 1.6rem;
                    margin-top: 3rem;
                }
                .cell-game-detail {
                    margin-top: 1.5rem;
                }
                .cell-text {
                    width: 100%;
                    margin: 0 auto;
                    margin-top: .8rem;
                    text-align: center;
                    &-title {
                        text-align: left;
                        margin-top: 1rem;
                        margin-bottom: calc( 100vw * 0.0052);
                        font-size: 1.8rem;
                    }
                    .game-intro {
                        margin-top: calc( 100vw * 0.0104);
                        font-size: 1.4rem;
                        text-align: left;
                        p {
                            text-indent: 1.2rem;
                        }
                    }
                    .cell-process {
                        font-size: 1.4rem;
                        text-align: left;
                    }
                    i {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
    .mod-judges-works {
        margin: 0 auto;
        margin-top: 2.25rem;
        width: 96%;
        min-height: 54rem;
        border: 3px solid #eaec2f;
        border-radius: 8px;
        padding: .75rem;
        .mod-real-judges-works {
            width: 100%;
            height: 100%;
            background-color: #e1ecfe;
            border-radius: 8px;
            text-align: left;
            padding-top: 2rem;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 2rem;
            .cell-judges-title {
                width: 18.5rem;
                height: 3.5rem;
                margin: 0 auto;
                position: relative;
                .bg-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                }
                .text {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    color: #fff000;
                    font-size: 2.0rem;
                    line-height: 3.0rem;
                    text-align: center;
                    letter-spacing: .2rem;
                }
            }
            .cell-judges {
                width: 100%;
                margin: 0 auto;
                margin-top: 2.25rem;
                min-height: 8.7rem;
                zoom: 1;
                text-align: center;
                .cell-judge {
                    width: 33%;
                    height: 100%; //margin-right: 3.7rem;
                    float: left;
                    .judge-img {
                        display: block;
                        width: 50%;
                        margin: 0 auto;
                    }
                    .judge-name {
                        margin-top: 0.5rem;
                        font-size: 1rem;
                        margin-bottom: 0;
                    }
                    .judge-job {
                        font-size: .8rem;
                    }
                }
                .cell-judge:last-of-type {
                    margin-right: 0;
                }
            }
            .cell-judges:after {
                clear: both;
                content: '';
                display: block;
                width: 0;
                height: 0;
                visibility: hidden;
            }
            .cell-works-title {
                margin: 0 auto;
                margin-top: 1.5rem;
                width: 18.5rem;
                height: 3.5rem;
                position: relative;
                .bg-img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                }
                .text {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    color: #fff000;
                    font-size: 2.0rem;
                    line-height: 3.0rem;
                    text-align: center;
                    letter-spacing: .2rem;
                }
            }
            .cell-works {
                width: 100%;
                margin-top: 1rem;
                .cell-work {
                    width: 100%;
                    height: 90px;
                    border: 2px solid #114aa8;
                    margin-bottom: 5px;
                    background-color: #fff;
                    border-radius: 10px;
                    .img {
                        height: 100%;
                        float: left;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        display: block;
                    }
                    .right-text {
                        height: 100%;
                        overflow: hidden;
                        padding: 5px 8px;
                        .title {
                            margin-top: 0;
                            margin-bottom: .3rem;
                            font-size: 1.4rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .subtitle {
                            font-size: 1.2rem;
                            margin-bottom: .2rem;
                            color: #666666;
                        }
                        .more-detail {
                            width: 100%;
                            color: #666666;
                            .avatar {
                                height: 21px;
                                width: 21px;
                                float: left;
                                margin-right: 2px;
                            }
                            .author {
                                font-size: 1.2rem;
                                float: left;
                                height: 21px;
                                line-height: 21px;
                                display: inline-block;
                            }
                            .favor-like {
                                float: right;
                                i {
                                    margin-left: 2px;
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                }
            }
            .cell-works:after {
                clear: both;
                content: '';
                display: block;
                width: 0;
                height: 0;
                visibility: hidden;
            }
            .cell-see-more-work {
                width: 100%;
                text-align: center;
                margin-top: 2.2rem;
                color: #347aec;
                font-size: 1.2rem;
                i {
                    font-weight: bold;
                }
            }
        }
    }
    .mod-cos {
        margin: 0 auto;
        margin-top: 1.6rem;
        width: 96%;
        min-height: 11.4rem;
        border: 3px solid #eaec2f;
        border-radius: 8px;
        padding: .3rem .75rem;
        .mod-real-cos {
            width: 100%;
            height: 100%;
            background-color: #e1ecfe;
            border-radius: 8px;
            text-align: left;
            padding-top: .7rem;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 2rem;
            .cell-info {
                padding-top: 1rem;
                p {
                    margin-bottom: 0;
                    font-size: 1rem;
                }
                .ea-info {
                    margin-top: 1.5rem;
                    &-title {
                        font-size: 16px;
                        margin-bottom: 2px;
                    }
                    &-subtitle {
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .mod-navibars {
        width: 100%;
        margin-top: 2rem;
        background-color: #fff;
        position: fixed;
        bottom: 0;
        ul {
            width: 100%;
            min-height: 66px;
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style: none;
                width: 20%;
                height: 100%;
                float: left;
                text-align: center;
                font-size: 1.4rem;
                a {
                    color: #000;
                }
                img {
                    width: 50%;
                    margin: 0 auto;
                    margin-top: .8rem;
                    margin-bottom: .4rem;
                    display: block;
                }
            }
            .active {
                background-color: #1352ba;
                a {
                    color: #fff;
                }
            }
        }
        ul:after {
            clear: both;
            content: '';
            display: block;
            width: 0;
            height: 0;
            visibility: hidden;
        }
    }
    .mod-works {
        margin: 0 auto;
        width: 96%;
        background-color: #317fff;
        padding: 3px;
        border-radius: 8px;
        .cell-navis {
            width: 100%;
            zoom: 1;
            height: 35px;
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style: none;
                float: left;
                text-align: left;
                width: 25%;
                height: 100%;
                line-height: 35px;
                a {
                    color: #fff000;
                    text-decoration: none;
                }
            }
        }
        .line {
            border-bottom: 1px solid #fff;
        }
        .cell-works {
            width: 100%;
            margin-top: 1rem;
            .cell-work {
                width: 100%;
                height: 90px;
                border: 2px solid #114aa8;
                margin-bottom: 5px;
                background-color: #fff;
                border-radius: 10px;
                .img {
                    height: 100%;
                    float: left;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    display: block;
                }
                .right-text {
                    height: 100%;
                    overflow: hidden;
                    padding: 5px 8px;
                    .title {
                        margin-top: 0;
                        margin-bottom: .3rem;
                        font-size: 1.4rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .subtitle {
                        font-size: 1.2rem;
                        margin-bottom: .2rem;
                    }
                    .more-detail {
                        width: 100%;
                        color: #666666;
                        .avatar {
                            height: 21px;
                            width: 21px;
                            float: left;
                            margin-right: 2px;
                        }
                        .author {
                            font-size: 1.2rem;
                            float: left;
                            height: 21px;
                            line-height: 21px;
                            display: inline-block;
                        }
                        .favor-like {
                            float: right;
                            i {
                                margin-left: 2px;
                                margin-right: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.clear {
    clear: both;
}

@-moz-keyframes right-down {

    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(90deg);
    }
}

@-webkit-keyframes right-down {

    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(90deg);
    }
}

@-moz-keyframes down-right {

    from {
        -moz-transform: rotate(90deg);
    }
    to {
        -moz-transform: rotate(0deg);
    }
}

@-webkit-keyframes down-right {
    from {
        -webkit-transform: rotate(90deg);
    }
    to {
        -webkit-transform: rotate(0deg);
    }
}

@media (min-width: 1000px) {
    html {
        font-size: 80%!important;
        /* 20÷16=125% */
    }
}

@media (min-width: 1200px) {
    html {
        font-size: 90%!important;
        /* 20÷16=125% */
    }
}

@media (min-width: 1300px) {
    html {
        font-size: 100%!important;
        /* 20÷16=125% */
    }
}

@media (min-width: 1900px) {
    html {
        font-size: 125%!important;
        /* 20÷16=125% */
    }
}

.match-page {
    .pc-match {
        .game-item {
            margin: 50px auto;
            padding: 20px;
            border: 1px solid #f4f4f4;
            background: #fff;
            position: relative;
            .match-status {
                position: absolute;
                left: 30px;
                img {
                    width: 90%;
                }
            }
        }
    }

    .mobile-match {
        .match-content {
            margin: 20px auto;
            padding: 20px;
            border: 1px solid #f4f4f4;
            background: #fff;
            position: relative;
            .match-status {
                position: absolute;
                left: 13px;
                top: 0px;
                img {
                    width: 70%;
                }
            }
        }
    }
}