.mobile-message {
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 500px) {
    .header {
      height: 7rem !important;
      text-align: center;
      border-bottom: 1px solid #ccc;
      .whole {
        margin: 0 auto;
        i {
          font-size: 1.3rem;
          color: #ccc;
          line-height: 8rem;
          font-weight: 600;
          display: inline;
          vertical-align: middle;
        }
        h3 {
          font-size: 1.3rem;
          font-weight: 400;
          line-height: 7rem;
          display: inline-block;
          margin: 0;
        }
      }
    }
    /*content*/
    .content {
      .information_list {
        margin: 0;
        .the_information {
          text-align: left;
          padding-left: 1.3rem;
          line-height: 2rem;
          font-size: 1rem;
          &:before {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 10px;
            background-color: #ccc;
            border-radius: 50%;
            position: relative;
            top: 0.1rem;
          }
          li {
            line-height: 2rem;
            font-size: 0.8rem;
          }
          ul {
            margin-left: 1.5rem;
            padding-right: 1rem;
            li {
              a {
                color: blue !important;
              }
              .right-span {
                margin-right: 1rem;
                float: right;
              }
            }
          }
        }
        .fold {
          height: 2.35rem;
          text-align: right;
          line-height: 2.35rem;
          font-size: 0.8rem;
          color: #3856c8;
          margin-right: 1.8rem;
        }
      }
      .the_date {
        text-align: left;
        padding-left: 1.3rem;
        line-height: 3rem;
        font-size: 1.3rem;
      }
    }
    .header {
      display: -webkit-box;
      font-size: 2rem;
      display: flex;
      flex-direction: row;
      & * {
        display: inline !important;
      }
    }
  }
}