//  编程部落主页
.mobile-forums {
  width: 100%;
  height: 100%;
  * {
    box-sizing: content-box;
  }
  /*------------------------------------------------------------------------------------flex最外框*/
  .page {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 100%;
    /*---------------------------------------------------------------------------------------flex内容区*/
    .content {
      height: 100%;
      position: relative;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      overflow: auto;
      -webkit-overflow-scrolling: touch;

    }
  }

  /*---------------------------------------------------------------------------------------------适配开始*/
  @media only screen and (max-width: 500px) {

    .header {
      height: 3.5rem;
      border-bottom: 1px solid #ccc;
      background-color: #F8F8FA;
      .tribe_nav {
        width: 100%;
        height: 3rem;
        position: relative;
        color: #686868;
        li {
          width: 30%;
          height: 100%;
          float: left;
          line-height: 3rem;
          text-align: center;
          margin-left: 0.6rem;
          font-size: 0.8rem;
        }
      }
    }
    li.theStyle {
      border-bottom: 0.25rem solid #83afba;
      color: #353535;
    }
    .slide1 .swiper-container-son {
      height: 10rem;
    }

    #pastone {
      img {
        width: 5rem;
        height: 5rem;
        position: absolute;
        top: 0.5rem;
        left: 0.8rem;
      }
      h4 {
        width: 100%;
        height: 4rem;
        line-height: 2.5rem;
        background: #9ec5d6;
        position: absolute;
        bottom: 0;
        color: #03003d;
        letter-spacing: 0.1rem;
        span {
          color: #2357c7;
        }
      }
    }

    /*------------------------------------------------------------*/
    .slide1 {
      .column_hot {
        background-color: #cee9f4;
        height: 2.35rem;
        line-height: 2.35rem;
        position: relative;
        h5 {
          font-size: 1rem;
          color: #676a71;
          font-weight: 400;
          line-height: 2.35rem;
        }
        span {
          font-size: 0.8rem;
          color: #2d59a4;
          float: right;
          display: block;
          height: 100%;
          line-height: 2.35rem;
          position: absolute;
          top: 50%;
          margin-top: -1.175rem;
          right: 1.2rem;
        }
      }
      .ul_content {
        p {
          font-size: 0.8rem;
          height: 2.35rem;
          color: #656565;
          border-bottom: 0.1rem solid #f3f3f3;
          line-height: 2.35rem;
          text-align: left;
          padding-left: 1rem;
          i {
            float: right;
            margin-right: 1.6rem;
            font-size: 1rem;
            color: #787da3;
          }
        }
      }
      .column_groom {
        background-color: #cee9f4;
        height: 2.35rem;
        line-height: 2.35rem;
        position: relative;
        h5 {
          font-size: 1rem;
          color: #676a71;
          font-weight: 400;
          line-height: 2.35rem;
        }
      }
      .Label_A {
        height: 3.5rem;
        font-size: 0.8rem;
        span {
          display: block;
          height: 100%;
          color: #656565;
          float: left;
          margin: 0 1rem;
          line-height: 3.5rem;
        }
        div {
          height: 45%;
          float: left;
          margin: 0 0.3rem;
          line-height: 1.6rem;
          text-align: right;
          background: url(../img/button_3.jpg) no-repeat left 0, url(../img/button_4.jpg) repeat;
          background-size: 0.8rem 100%;
          margin-top: 1rem;
          padding-right: 0.7rem;
          &:nth-of-type(1) {
            width: 2.8rem;
            /*background: url(img/button_img.jpg) no-repeat left 0,url(img/button_2.jpg) repeat;
           background-size: 0.8rem 100%;*/
          }
          &:nth-of-type(2) {
            width: 2.8rem;
          }
          &:nth-of-type(3) {
            width: 3.2rem;
          }
          &:nth-of-type(4) {
            width: 2.8rem;
          }
          &.lable_A_on {
            color: #efffff;
            background: url(../img/button_img.jpg) no-repeat left 0, url(../img/button_2.jpg) repeat;
            background-size: 0.8rem 100%;
          }
        }
      }
      .Label_B {
        font-size: 0.8rem;
        text-align: left;
        line-height: 1.6rem;
        padding: 0 0 1rem 1rem;
        border-bottom: 0.1rem solid #f3f3f3;
        span {
          padding-bottom: 0.05rem;
          border-bottom: 0.01rem solid #000;
          margin: 0 0.4rem;
          &.lable_B_on {
            color: #00a1e9;
            border-bottom: 0.01rem solid #00a1e9;
          }
        }
        div {
          color: #656565;
          margin: 0;
          border: none;
          display: inline;
        }
      }
    }
    /*部落样式*/
    .label_content {
      .lable_content_ul {
        padding: 0.6rem 1.1rem;
        display: flex;
        flex-direction: row;
        font-size: 0.8rem;
        border-bottom: 0.1rem solid #f3f3f3;
        img {
          width: 3rem;
          height: 3rem;
          flex: 1;
        }
        .label_right {
          flex: 7;
          text-align: justify;
          margin-left: 0.7rem;
          line-height: 1.5rem;
          span i:nth-of-type(1) {
            font-size: 0.9rem;
            position: relative;
            left: -0.2rem;
            top: -0.1rem;
          }
          span i:nth-of-type(2) {
            font-size: 0.95rem;
            position: relative;
            left: -0.2rem;
          }
          span:nth-last-child(1) span {
            color: #2c53c8;
          }
        }
      }
    }

    /*---slide1完结，slide2开始----------------------------------------------*/
    .slide2 {
      .my_label {
        /*height: 4.2rem;*/
        padding: 0.6rem 1.1rem;
        display: flex;
        flex-direction: row;
        font-size: 0.8rem;
        border-bottom: 0.1rem solid #f3f3f3;
        img {
          flex: 1;
          width: 3.5rem;
          height: 3.6rem;
        }
        .label_center {
          flex: 5;
          text-align: left;
          padding-left: 1.2rem;
          line-height: 1.8rem;
          font-size: 0.8rem;
          h4 {
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
        i {
          flex: 1;
          color: #4463b6;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 3.8rem;
        }
      }
    }

    /*slide3开始--------------------------------------------------*/
    .slide3 {
      .my_runking {
        display: flex;
        padding: 0.6rem 1.1rem;
        flex-direction: row;
        font-size: 0.8rem;
        border-bottom: 0.1rem solid #f3f3f3;
        img {
          width: 3rem;
          height: 3rem;
          flex: 1;
          margin-right: 1rem;
        }
        .label_text {
          text-align: left;
          flex: 5;
          line-height: 1.2rem;
          font-size: 0.8rem;
          h4 {
            color: #2953cc;
            font-size: 0.86rem;
            font-weight: 500;
            display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
            -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
            -webkit-line-clamp: 1; /** 显示的行数 **/
            overflow: hidden; /** 隐藏超出的内容 **/
          }
          span {
            display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
            -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
            -webkit-line-clamp: 1; /** 显示的行数 **/
            overflow: hidden; /** 隐藏超出的内容 **/
          }
        }
        i {
          flex: 1;
          color: #4463b6;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 3.1rem;
        }
        .runk {
          /*width: 1.5rem;*/
          border-top: 1rem solid #2656bc;
          border-right: 1rem solid #2656bc;
          border-left: 1rem solid #2656bc;
          border-bottom: 1rem solid #fff;
          margin-right: 1rem;
          background-color: #2656BC;
          position: relative;
          span {
            width: 1.25rem;
            height: 1.25rem;
            line-height: 1.25rem;
            border-radius: 1.25rem;
            border: 0.05rem solid #fff;
            text-align: center;
            color: #fff;
            position: absolute;
            top: -0.3rem;
            left: -0.65rem;
          }
        }
      }
      .the_list {
        text-align: left;
        padding: 0.6rem 1rem;
        border-bottom: 0.1rem solid #f3f3f3;
        span {
          font-size: 0.86rem;
          color: #2953cc;
          &:nth-of-type(1) {
            margin-left: 0.5rem;
          }
          &:nth-of-type(2) {
            margin-left: 1rem;
          }
        }
        i {
          height: 100%;
          line-height: 1.5rem;
          font-size: 1.2rem;
          font-weight: 500;
          float: right;
          width: 2rem;
          color: #4463b6;
        }
      }
    }
  }
}

.mobile-forum-show {
  width: 100%;
  height: 100%;
  /*------------------------------------------------------------------------------------flex最外框*/
  .page {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 100%;
  }
  /*---------------------------------------------------------------------------------------flex内容区*/
  .page .content {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

  }

  /*---------------------------------------------------------------------------------------------适配开始*/
  @media only screen and (max-width: 500px) {
    .header {
      height: 13.2rem !important;
      border-bottom: 0.0625rem solid #F3F3F3;
      background-size: 100% 38% !important;
      display: block !important;
      font-size: 1rem;
      .button {
        width: 100%;
        height: 4.6rem;
        overflow: hidden;
        padding-top: 0.5rem;
        div {
          width: 20%;
          height: 1.5rem;
          margin: 0.5rem 1rem 0.5rem 75%;
          border-radius: 0.3rem;
          color: white;
          font-size: 0.8rem;
          line-height: 1.5rem;
          background-color: #2858ba;
        }
        a {
          color: white;
        }
      }
    }

    .header {
      * {
        box-sizing: content-box;
      }
      .tribe_title {
        padding: 0 1.2rem;
        height: 4rem;
        &:after {
          content: "\0020";
          display: block;
          height: 0;
          clear: both;
        }
        img {
          width: 3.5rem;
          height: 3.5rem;
          float: left;
          position: relative;
          top: -1rem;
        }
        .title_center {
          float: left;
          text-align: left;
          margin-left: 1rem;
          line-height: 1.5rem;
          font-size: 0.8rem;
          width: 70%;
          h4 {
            font-weight: 400;
            letter-spacing: 0.1rem;
            color: #2755b5;
            font-size: inherit;
            margin: 0;
          }
          span {
            &:nth-of-type(1) {
              display: inline-block;
              color: #636363;
              float: left;
              i {
                position: relative;
                top: -0.1rem;
              }
              span:nth-of-type(2) {
                color: #2a56a1;
                margin-left: 0.5rem;
              }
            }
            &:nth-of-type(2) {
              display: inline-block;
              color: #636363;
              float: right;
              span:nth-of-type(2) {
                color: #2a56a1;
                margin-left: 0.5rem;
              }
            }
            i {
              display: inline-block;
              float: left;
              margin-right: 0.3rem;

            }
          }
        }
      }
      .tribe_introduce {
        text-align: left;
        padding: 0 1.2rem;
        font-size: 0.8rem;
        h4 {
          font-weight: 500;
          color: #636363;
          font-size: inherit;
          margin: 0;
        }
        p {
          text-indent: 2rem;
          color: #636363;
        }
      }
    }
    /*头部结束*/
    .content {
      .tribe_label {
        padding: 0.4rem 1rem;
        display: flex;
        flex-direction: row;
        border-bottom: 0.0625rem solid #f3f3f3;
        font-size: 0.8rem;
        img {
          /*flex: 1;*/
          width: 4rem;
          height: 4rem;
        }
        .tribe_label_title {
          flex: 7;
          line-height: 2rem;
          text-align: left;
          padding-left: 0.5rem;
          h4 {
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0.1rem;
          }
          .icon-jing {
            font-size: 1.6rem;
            position: relative;
            top: 0.2rem;
            color: #2d53b8;
            font-weight: 500;
          }
          .nick {
            color: #666;
          }
          .info {
            float: right;
            margin-right: 0.2rem;
            i {
              font-size: 1rem;
              margin: 0 0.08rem;
              &:nth-of-type(1) {
                position: relative;
                top: 0.05rem;
                margin-right: 0.2rem;
              }
              &:nth-of-type(3) {
                font-size: 0.8rem;
                margin: 0 0.25rem;
              }
            }
            span {
              color: #354fd8;
            }
          }
        }
      }
      .write_comment {
        width: 3rem;
        height: 3rem;
        border-radius: 3rem;
        background-color: #5279c8;
        color: white;
        position: fixed;
        bottom: 5rem;
        right: 2rem;
        i {
          font-size: 2rem;
          position: relative;
          //top: 0.5rem;
        }
      }
    }
  }
}

//  新成员申请
.mobile-user-apply {
  width: 100%;
  height: 100%;
  /*------------------------------------------------------------------------------------flex最外框*/
  .page {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 100%;
    /*---------------------------------------------------------------------------------------flex内容区*/
    .content {
      position: relative;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      overflow: auto;
      -webkit-overflow-scrolling: touch;

    }
  }
  /*---------------------------------------------------------------------------------------------适配开始*/
  @media only screen and (max-width: 500px) {
    .header {
      border-bottom: 0.0625rem solid #F3F3F3;
      font-size: 1rem;
      .card_top {
        width: 100%;
        line-height: 3.2rem;
        i {
          float: left;
          font-size: 1.2rem;
          color: #5c7dca;
          margin-left: 1.5rem;
          font-weight: 600;
        }
      }
    }
    .content {
      h4 {
        width: 100%;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: left;
        text-indent: 1.5rem;
        font-weight: 500;
        color: #323232;
      }
      .member_table {
        width: 92%;
        margin: 0 auto;
        border: 0.01rem solid #f3f3f3;
        text-align: center;
        font-size: 0.8rem;
        min-height: 25rem;
        padding: 0 0.2rem;
        .table_th {
          height: 2rem;
          line-height: 2rem;
          color: #989898;
          border-bottom: 0.01rem solid #f3f3f3;
          content: "\0020";
          display: block;
          height: 0;
          clear: both;
          div {
            float: left;
            text-align: center;
            &:nth-of-type(1) {
              width: 35%;
            }
            &:nth-of-type(2) {
              width: 45%;
            }
            &:nth-of-type(3) {
              width: 20%;
            }
          }
        }
        .animat_table {
          height: 2rem;
          line-height: 2rem;
          color: #989898;
          border-bottom: 0.01rem solid #f3f3f3;
          &:after {
            content: "\0020";
            display: block;
            height: 0;
            clear: both;
          }
          div {
            float: left;
            text-align: center;
            &:nth-of-type(1) {
              width: 35%;
            }
            &:nth-of-type(2) {
              width: 45%;
            }
            &:nth-of-type(3) {
              width: 20%;
              height: 100%;
              position: relative;
              span {
                display: block;
                width: 1rem;
                height: 1rem;
                border: 0.05rem solid #2a56ad;
                border-radius: 0.3rem;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -0.5rem;
                margin-top: -0.5rem;
              }
            }
          }
        }
      }
      .button_authority {
        width: 100%;
        margin-top: 2rem;
        &:after {
          content: "\0020";
          display: block;
          height: 0;
          clear: both;
        }
        div {
          width: 5.5rem;
          height: 2rem;
          line-height: 2rem;
          background-color: #2858ba;
          text-align: center;
          color: #f6f2f1;
          float: right;
          margin: 0 0.5rem;
          border-radius: 0.3rem;
        }
        .refuse {
          margin-right: 1rem;
        }
      }
    }
    .tabel_on {
      background-color: #2858BA;
    }

  }
}