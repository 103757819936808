#mobile {
  //  头部
  .header {
    display: -webkit-box;
    font-size: 2rem;
    height: 3.2rem;
    display: flex;
    flex-direction: row;
    & * {
      display: block;
    }
    .logo img {
      margin-top: 0.8rem;
      width: 100%;
    }
    .user_avatar img {
      width: 55%;
      height: 55%;
      border-radius: 50%;
      border: 1px solid #ccc;
      margin-top: 0.6rem;
    }
    .user_avatar {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    .header_search {
      flex: 1;
      background-color: #f1ebeb;
      border-radius: 1rem;
      box-shadow: -1px -1px 1px #ccc;
      margin: 0.8rem;
      height: 55%;
      i {
        font-size: 1.4rem;
        color: #83AFB7;
        position: relative;
        top: 0.2rem;
        left: 0.3rem;
        float: left;
        line-height: initial;
      }
      input {
        height: 100%;
        width: 80%;
        margin: 0 auto;
        font-size: 0.9rem;
      }
    }
  }
}