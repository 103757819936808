.create-forum-page, .edit-forum-page {
  .create-forum-form, .edit-forum-form {
    //margin-top: 15px;
    background: #ffffff;
    padding-bottom: 30px;

    form {
      width: 85%;
      margin: auto;
      padding-top: 15px;

      input[type=submit] {
        display: block;
        width: 130px;
        margin: auto;
        font-size: 16px;
        text-align: center;
        border-radius: 8px;
      }

      .form-group {
        margin-bottom: 40px;
        label {
          display: inline-block;
          font-weight: normal;
          font-size: 16px;
          width: 80px;
          margin: 0;
          vertical-align: top;
        }
        .form-control {
          display: inline-block;
        }

        .cnAddress {
          display: inline-block;
          #cnAddr_city {
            width: 135px;
          }
        }

        .form-control[type='text'] {
          width: 200px;
        }
        select.form-control {
          width: 100px;
        }
        textarea.form-control {
          width: 500px;
          width: calc(100% - 85px);
          height: 120px;
        }

        input[type=radio] {
          margin-right: 5px;
        }

        input[type=radio]:not(first-child) {
          margin-left: 30px;
        }

        .introduce-alert {
          padding-left: 85px;
          font-size: 16px;
          span {
            color: red;
          }
        }
        .alert {
          padding: 5px 0 0 115px;
          margin: 0;
          color: red;
        }
        .post-image {
          width: 500px;
          width: calc(100% - 85px);
          display: inline-block;
        }

        .logo-image {
          width: 150px;
          display: inline-block;
        }
        .btn-action {
          margin-top: 10px;
          padding-left: 85px;
          .btn {
            font-size: 16px;
            border-radius: 8px;
            padding: 1px 20px;
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .image-choose-modal {
    .modal-content {
      border-radius: 0;
    }
    .modal-body {
      padding: 15px 30px;
      border-radius: 0;
    }

    .nav > li > a:hover, .nav > li > a:focus {
      background: none;
      color: $blue;
    }

    .nav-tabs {
      border: none;
      margin-bottom: 20px;
      li > a {
        color: #555555;
        margin-right: 2px;
        line-height: 1.6;
        border: none;
        border-radius: 0;
        padding: 0 0 0 30px;

      }
      li:first-child a {
        padding: 0 30px 0 0;
        border-right: 1px #666666 solid;
      }

      li.active > a, li.active > a:hover, li.active > a:focus {
        background: none;
        color: $blue;
      }
    }
    .tab-content {
      max-height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .post-box {
      margin-bottom: 10px;
      cursor: pointer;
      .post-name {
        text-align: center;
        color: #666666;
        font-size: 16px;
      }
      .post-box-image {
        border: 3px solid transparent;
        img {
          width: 100%;
          height: auto;
        }

        &.checked {
          border: 3px solid $blue;
        }
      }
    }

    #upload-post {
      .image-area {
        img {
          width: 100%;
          height: auto;
          max-width: 100%;
        }
      }
    }

    #upload-logo {
      .image-area {
        padding: 30px 0 0;
        text-align: center;
        img {
          margin: auto;
        }
      }
    }

    #default-logo {
      margin: 0 -15px;
    }

    .logo-box {
      cursor: pointer;
      float: left;
      margin: 0 2% 40px;
      width: 16%;
      .logo-box-image {
        border: 3px solid transparent;
        img {
          width: 100%;
          height: auto;
        }

        &.checked {
          border: 2px solid $blue;
        }
      }
    }

    .choose-confirm {
      text-align: center;
      margin-top: 40px;
      .btn {
        font-size: 16px;
        padding: 0 40px;
        border-radius: 8px;
      }
    }
  }
}

.forum-detail-page {
  .forum-header {
    margin-top: 20px;
    color: #666666;

    label {
      margin: 0;
      font-weight: normal;
      width: 80px;
    }
    .post-area {
      img {
        width: 100%;
        height: auto;
      }
    }
    .forum-base-info {
      padding: 0 90px;
      position: relative;
      .logo-area {
        float: left;
        margin-right: 20px;
        width: 150px;
        position: relative;
        top: -75px;
        img {
          width: 100%;
          height: auto;
          border-color: #ffffff;
          background: #ffffff;
          padding: 1px;
          border-radius: 0;
        }
      }
      .info-area {
        padding-top: 10px;
        float: left;
        position: relative;
        width: calc(100% - 170px);
        .table {
          width: 75%;
          min-width: 450px;
          margin-bottom: 0;

          td {
            border: hidden;
          }
        }
        .join-btn {
          position: absolute;
          right: 0;
          top: 10px;
          .btn {
            display: block;
            padding: 3px 25px;
            font-size: 16px;
            border-radius: 8px;
            margin-bottom: 10px;
          }
        }
      }

      .introduce-area {
        position: relative;
        top: -20px;
        p {
          text-indent: 2em;
          margin: 0;
        }
      }
    }
  }

  .search-bar {
    padding: 5px;
    font-size: 18px;
    color: #666666;
    margin: 20px 0;
    a {
      color: inherit;
      margin: 0 20px;
      text-decoration: none;
      &.active, &:hover {
        color: $blue;
      }
    }
  }

  .forum-user {
    margin-top: 20px;
    padding: 10px 10px 0;
    font-size: 18px;
    color: #666666;

    .forum-user-list {
      height: 560px;
      overflow: auto;
      overflow-x: hidden;

      .forum-user-item {
        border-bottom: 1px solid #e2e2e2;
        min-height: 80px;
        padding: 10px 0;

        &:last-child {
          border-bottom: hidden;
        }

        .avatar {
          width: 60px;
          height: 60px;
          display: inline-block;
          margin-right: 5px;
          vertical-align: top;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          display: inline-block;
          width: calc(100% - 75px);
          font-size: 12px;
          .name {
            font-size: 18px;
            margin-bottom: 13px;
            span {
              font-size: 12px;
              padding-top: 8px;
            }
          }

          .honor {
            i {
              font-style: normal;
              color: $blue;
            }
          }
        }
      }
    }
  }
}

.forum-page {
  //html, body {
  //  width: 100%;
  //  height: 100%;
  //  background-color: #f4f4f4;
  //}
  //
  //* {
  //  margin: 0;
  //  padding: 0;
  //}
  //
  //a {
  //  text-decoration: none;
  //  color: #666666;
  //}
  ///*部落头部*/
  //
  //.container {
  //  width: 1200px;
  //  margin: 0 auto;
  //  .tribe_top, .subject:after {
  //    content: "\0020";
  //    display: block;
  //    height: 0;
  //    clear: both;
  //  }
  //  .tribe_top {
  //    width: 100%;
  //    height: 60px;
  //    background-color: #fff;
  //    box-shadow: 0.1px 0.1px 0.1px;
  //    margin-top: 20px;
  //    .tribe_name {
  //      height: 60px;
  //      font-size: 24px;
  //      float: left;
  //      margin-left: 20px;
  //      span {
  //        &:nth-of-type(1) {
  //          color: #666666;
  //          line-height: 60px;
  //        }
  //        &:nth-of-type(2) {
  //          border: 1px solid #2758b9;
  //          display: inline-block;
  //          color: #2758b9;
  //          font-size: 16px;
  //          width: 100px;
  //          text-align: center;
  //          height: 28px;
  //          line-height: 30px;
  //          border-radius: 12px;
  //          margin-left: 20px;
  //          cursor: pointer;
  //          position: relative;
  //          top: -2px;
  //        }
  //      }
  //    }
  //    .built {
  //      height: 60px;
  //      width: 300px;
  //      line-height: 60px;
  //      font-size: 18px;
  //      margin-right: 64px;
  //      float: right;
  //      span {
  //        &:nth-of-type(1) {
  //          color: #305db7;
  //          line-height: 60px;
  //          cursor: pointer;
  //        }
  //        &:nth-of-type(1) i {
  //          font-size: 25px;
  //          font-weight: 600;
  //          position: relative;
  //          top: 3px;
  //          left: -8px;
  //        }
  //        &:nth-of-type(2) {
  //          border: 1px solid #2758b9;
  //          display: inline-block;
  //          color: #ffffff;
  //          font-size: 16px;
  //          width: 100px;
  //          text-align: center;
  //          height: 28px;
  //          line-height: 30px;
  //          border-radius: 15px;
  //          background-color: #4b74c1;
  //          margin-left: 70px;
  //          cursor: pointer;
  //          position: relative;
  //          top: -2px;
  //        }
  //        a {
  //          color: #ffffff;
  //        }
  //      }
  //    }
  //  }
  //
  //  /*部落内容*/
  //  .subject {
  //    width: 100%;
  //    margin-top: 20px;
  //    .tribe_left {
  //      width: 880px;
  //      float: left;
  //      text-align: center;
  //      /*background-color: #77ffcc;*/
  //      .title {
  //        font-size: 18px;
  //        color: #2a58b2;
  //        height: 38px;
  //        span {
  //          &:nth-of-type(1) {
  //            width: 75px;
  //            border-bottom: 1px solid #2A58B2;
  //            line-height: 19px;
  //            display: inline-block;
  //            position: relative;
  //            top: -5px;
  //          }
  //          &:nth-of-type(2) {
  //            width: 130px;
  //            line-height: 38px;
  //            display: inline-block;
  //          }
  //          &:nth-of-type(3) {
  //            width: 75px;
  //            border-bottom: 1px solid #2A58B2;
  //            line-height: 19px;
  //            display: inline-block;
  //            position: relative;
  //            top: -5px;
  //          }
  //        }
  //      }
  //    }
  //    .tribe_right {
  //      width: 300px;
  //      float: right;
  //      /*background-color: #d28eff;*/
  //    }
  //  }
  //}
  //
  ///*左边每日部落推荐样式 .container .subject .tribe_left*/
  //.hot {
  //  box-shadow: 0.1px 0.1px 0.1px;
  //  background-color: #ffffff;
  //  height: 265px;
  //  .banner {
  //    .img {
  //      li {
  //        width: 880px;
  //        height: 225px;
  //      }
  //    }
  //  }
  //}
  //
  //.hot {
  //  .banner {
  //    .img {
  //      li {
  //        &:nth-of-type(1) {
  //          background-size: 880px 140px;
  //        }
  //        &:nth-of-type(2) {
  //          background-size: 880px 140px;
  //        }
  //        &:nth-of-type(3) {
  //          background-size: 880px 140px;
  //        }
  //        &:nth-of-type(4) {
  //          background-size: 880px 140px;
  //        }
  //        .banner_img {
  //          position: absolute;
  //          top: 12px;
  //          left: 75px;
  //          img {
  //            width: 110px;
  //            height: 115px;
  //          }
  //        }
  //      }
  //      .message {
  //        position: absolute;
  //        bottom: 0px;
  //        width: 880px;
  //        height: 85px;
  //        padding-top: 5px;
  //        background-color: #FFFFFF;
  //        div {
  //          height: 28px;
  //          line-height: 28px;
  //          width: 780px;
  //          margin: 0 auto;
  //          text-align: left;
  //          font-size: 13px;
  //          span {
  //            display: inline-block;
  //            vertical-align: middle;
  //          }
  //        }
  //        .message_top {
  //          display: flex;
  //          flex-direction: row;
  //          div {
  //            flex: 1;
  //            span {
  //              &:nth-of-type(2) {
  //                margin-left: 20px;
  //              }
  //            }
  //          }
  //        }
  //        .message_text {
  //          span {
  //            &:nth-of-type(2) {
  //              margin-left: 20px;
  //              display: inline-block;
  //              width: 80%;
  //              overflow: hidden;
  //              text-overflow: ellipsis;
  //              white-space: nowrap;
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //}
  //
  ///*推荐热议*/
  //.container {
  //  .subject {
  //    .tribe_left {
  //      .discuss {
  //        margin-top: 10px;
  //        box-shadow: 0.1px 0.1px 0.1px;
  //        padding-bottom: 50px;
  //        position: relative;
  //        background-color: #ffffff;
  //        .mobel_content {
  //          width: 840px;
  //          margin: 0 auto;
  //          padding: 10px 0;
  //          border-bottom: 1px solid #e2e2e2;
  //          cursor: pointer;
  //          &:after {
  //            content: "\0020";
  //            display: block;
  //            height: 0;
  //            clear: both;
  //          }
  //          img {
  //            float: left;
  //            width: 60px;
  //            height: 60px;
  //            margin-top: 10px;
  //          }
  //          .right_txt {
  //            float: left;
  //            height: 80px;
  //            width: 760px;
  //            margin: 1px 0px 0px 20px;
  //            text-align: left;
  //            line-height: 25px;
  //            font-size: 14px;
  //            h4 {
  //              font-weight: 500;
  //              color: #333333;
  //            }
  //            p {
  //              color: #999999;
  //            }
  //            .bottom {
  //              div {
  //                &:nth-of-type(1) {
  //                  color: #666666;
  //                  float: left;
  //                }
  //                &:nth-of-type(2) {
  //                  float: right;
  //                  span {
  //                    color: #2758b9;
  //                  }
  //                  i {
  //                    color: #5e5e5e;
  //                    &:nth-of-type(1) {
  //                      font-size: 18px;
  //                      position: relative;
  //                      top: 2px;
  //                    }
  //                    &:nth-of-type(3) {
  //                      font-size: 14px;
  //                      position: relative;
  //                      top: 2px;
  //                    }
  //                  }
  //                }
  //              }
  //            }
  //          }
  //        }
  //        .more {
  //          color: #2758b9;
  //          position: absolute;
  //          bottom: 20px;
  //          right: 30px;
  //          font-size: 14px;
  //        }
  //      }
  //
  //      //  推荐部落
  //
  //      .recommend {
  //        margin-top: 10px;
  //        box-shadow: 0.1px 0.1px 0.1px;
  //        background-color: #ffffff;
  //        overflow: hidden;
  //        margin-bottom: 26px;
  //        .mobel_content {
  //          width: 830px;
  //          margin: 0 auto;
  //          .Label_A, .Label_B {
  //            text-align: left;
  //            color: #666666;
  //            line-height: 37px;
  //          }
  //          .Label_A div, .Label_B div {
  //            display: inline-block;
  //          }
  //          .Label_A {
  //            span {
  //              width: 65px;
  //              display: inline-block;
  //              text-align: center;
  //              cursor: pointer;
  //            }
  //          }
  //          .Label_B {
  //            span {
  //              margin-left: 20px;
  //              display: inline-block;
  //              text-align: center;
  //              cursor: pointer;
  //            }
  //          }
  //          .label_content {
  //            width: 100%;
  //            margin: 20px 0px;
  //            &:after {
  //              content: "\0020";
  //              display: block;
  //              height: 0;
  //              clear: both;
  //            }
  //            .lable_content_ul {
  //              width: 50%;
  //              float: left;
  //              padding: 14px 0px;
  //              cursor: pointer;
  //              &:after {
  //                content: "\0020";
  //                display: block;
  //                height: 0;
  //                clear: both;
  //              }
  //              img {
  //                float: left;
  //                width: 60px;
  //                height: 60px;
  //              }
  //              .label_right {
  //                float: left;
  //                width: 295px;
  //                text-align: left;
  //                margin-left: 20px;
  //                font-size: 12px;
  //                line-height: 20px;
  //                letter-spacing: 1px;
  //                h4 {
  //                  color: #333333;
  //                  font-weight: 500;
  //                }
  //                p {
  //                  color: #999999;
  //                }
  //              }
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }
  //}
  //
  ///*右边 .container .subject .tribe_right*/
  //
  //.my_lable {
  //  box-shadow: 0.1px 0.1px 0.1px;
  //  width: 290px;
  //  height: 245px;
  //  font-size: 15px;
  //  position: relative;
  //  background-color: #ffffff;
  //  padding: 0px 10px 20px 0px;
  //  h4 {
  //    font-weight: 500;
  //    height: 30px;
  //    line-height: 30px;
  //    padding-left: 20px;
  //    color: #333333;
  //  }
  //  .scroll_top {
  //    overflow: auto;
  //    height: 215px;
  //    /*设置滚动条*/
  //    &::-webkit-scrollbar {
  //      width: 10px;
  //      height: 10px;
  //    }
  //    &::-webkit-scrollbar-button {
  //      /*background-color:red;*/
  //      width: 0px;
  //      height: 0px;
  //    }
  //    &::-webkit-scrollbar-track {
  //      /*background:yellow;*/
  //      border: 1px solid #c9c9c9;
  //      border-radius: 5px;
  //    }
  //    &::-webkit-scrollbar-thumb {
  //      background-color: #305eb8;
  //      border-radius: 5px;
  //    }
  //    &:after {
  //      content: "\0020";
  //      display: block;
  //      height: 0;
  //      clear: both;
  //    }
  //
  //    .my_lablecontent {
  //      width: 255px;
  //      margin: 0 auto;
  //      padding: 10px 0;
  //      border-bottom: 1px solid #e2e2e2;
  //      cursor: pointer;
  //      &:after {
  //        content: "\0020";
  //        display: block;
  //        height: 0;
  //        clear: both;
  //      }
  //      img {
  //        width: 50px;
  //        height: 50px;
  //        float: left;
  //      }
  //      .mylabel_right {
  //        width: 180px;
  //        float: left;
  //        line-height: 20px;
  //        color: #666666;
  //        font-size: 15px;
  //        h5 {
  //          color: #666666;
  //          font-weight: 500;
  //          margin-left: 20px;
  //        }
  //        p {
  //          margin-left: 20px;
  //        }
  //      }
  //    }
  //  }
  //}
  //
  //.activity {
  //  box-shadow: 0.1px 0.1px 0.1px;
  //  width: 290px;
  //  height: 312px;
  //  font-size: 15px;
  //  margin-top: 10px;
  //  background-color: #ffffff;
  //  padding: 0px 10px 20px 0px;
  //  h4 {
  //    font-weight: 500;
  //    height: 30px;
  //    line-height: 30px;
  //    padding-left: 20px;
  //    color: #333333;
  //  }
  //  .scroll_second {
  //    height: 270px;
  //    overflow: auto;
  //
  //    /*设置滚动条*/
  //    &::-webkit-scrollbar {
  //      width: 10px;
  //      height: 10px;
  //    }
  //    &::-webkit-scrollbar-button {
  //      /*background-color:red;*/
  //      width: 0px;
  //      height: 0px;
  //    }
  //    &::-webkit-scrollbar-track {
  //      /*background:yellow;*/
  //      border: 1px solid #c9c9c9;
  //      border-radius: 5px;
  //    }
  //    &::-webkit-scrollbar-thumb {
  //      background-color: #305eb8;
  //      border-radius: 5px;
  //    }
  //    .mylabel_right {
  //      margin: 0px 20px;
  //      font-size: 12px;
  //      padding: 10px 0px;
  //      border-bottom: 1px solid #e2e2e2;
  //      cursor: pointer;
  //      &:after {
  //        content: "\0020";
  //        display: block;
  //        height: 0;
  //        clear: both;
  //      }
  //      .runk {
  //        border-top: 13px solid #2656bc;
  //        border-right: 13px solid #2656bc;
  //        border-left: 13px solid #2656bc;
  //        border-bottom: 7px solid #fff;
  //        height: 23px;
  //        background-color: #2656BC;
  //        position: relative;
  //        float: left;
  //        width: 2px;
  //        span {
  //          width: 20px;
  //          height: 20px;
  //          line-height: 20px;
  //          border-radius: 20px;
  //          border: 1px solid #fff;
  //          text-align: center;
  //          color: #fff;
  //          position: absolute;
  //          top: -6px;
  //          left: -10px;
  //        }
  //      }
  //
  //      .right_explain {
  //        float: left;
  //        width: 200px;
  //        margin-left: 10px;
  //        line-height: 20px;
  //        color: #666666;
  //        h5 {
  //          font-weight: 500;
  //          font-size: 15px;
  //        }
  //      }
  //    }
  //
  //    .label_second {
  //      margin: 0 30px;
  //      font-size: 15px;
  //      padding: 10px 0px;
  //      color: #666666;
  //      cursor: pointer;
  //      span {
  //        &:nth-of-type(2) {
  //          margin-left: 15px;
  //          display: inline-block;
  //        }
  //      }
  //    }
  //  }
  //}
  //
  //.lable_B_on {
  //  color: #2a58b2;
  //  font-weight: 600;
  //}
  //* {
  //  list-style: none;
  //  margin: 0;
  //  padding: 0;
  //  box-sizing: unset;
  //}
  //.banner {
  //  width: 100%;
  //  height: 225px;
  //  position: relative;
  //  overflow: hidden;
  //  .img {
  //    width: 5000px;
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    li {
  //      float: left;
  //    }
  //  }
  //  .num {
  //    width: 100%;
  //    position: absolute;
  //    bottom: 15px;
  //    left: 0;
  //    text-align: center;
  //    font-size: 0px;
  //    li {
  //      width: 22px;
  //      height: 5px;
  //      border-radius: 5px;
  //      display: inline-block;
  //      border: 1px solid #2758B9;
  //      cursor: pointer;
  //      margin: 0 3px;
  //      &.on {
  //        background: #2758B9;
  //      }
  //    }
  //  }
  //  .btn {
  //    width: 32px;
  //    height: 50px;
  //    position: absolute;
  //    top: 90px;
  //  }
  //  .btn_l {
  //    left: 0px;
  //    transform: translate(0%, -100%);
  //    background: url(../img/banner_btn.png) no-repeat 7px 0px;
  //    background-size: 50px 60px;
  //  }
  //  .btn_r {
  //    right: 0px;
  //    transform: translate(0%, -100%);
  //    background: url(../img/banner_btn.png) no-repeat -25px 0px;
  //    background-size: 50px 60px;
  //  }
  //}
}

//  forum-page end

.index-bar {
  margin-top: 20px;
  height: 80px;
  line-height: 80px;
  padding: 0 20px;
  color: #666666;
  margin-bottom: 20px;
  .title {
    font-size: 24px;
  }
  .action-area {

    & > * {
      position: relative;
      display: inline-block;
      margin: 0 20px;
    }

    .search-area {
      margin-right: 0;
    }
    .create-tribe-btn {
      margin-left: 0;
      a {
        color: $blue;
      }
    }
    input {
      line-height: 30px;
      height: 30px;
      border: 2px solid #e0e0e0;
      border-radius: 12px;
      padding: 0 35px 0 5px;
      width: 175px;
      outline: none;
    }
    .search-btn {
      position: absolute;
      right: 2px;
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      color: #999999;
      font-size: 20px;
      top: 24px;
      text-align: center;
      z-index: 2;
    }
    .create-btn {
      a {
        color: inherit;
        font-size: 16px;
        text-decoration: none;
        &.create-tribe {
          margin-right: 20px;
        }
        &.sign-in-btn {
          color: #fff;
          padding: 6px 30px;
        }
        &.has-signed-in {
          color: #fff;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.topic-list {
  padding: 0 20px;
  margin-bottom: 20px;
  .topic-item {
    padding: 15px 0;
    color: #666666;
    position: relative;
    border-bottom: 1px solid #e2e2e2;

    &:last-child {
      border-bottom: hidden;
    }

    &:hover .info .title,
    &:hover .info .abstract,
    &:hover .info .forum-info .author,
    &:hover .info .forum-info .forum {
      color: $blue;
    }
    .author-avatar {
      width: 60px;
      height: 60px;
      display: inline-block;
      margin-right: 20px;
      vertical-align: bottom;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      display: inline-block;
      width: 80%;
      vertical-align: bottom;
      .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 2px;
        .author {
          font-size: 12px;
          margin-left: 20px;
        }
        .topic-decoration {
          position: absolute;
          top: 0;
          left: 70%;
          img {
            margin-right: 5px;
          }
        }
      }
      .abstract {
        width: 60%;
        display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
        -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 1; /** 显示的行数 **/
        overflow: hidden; /** 隐藏超出的内容 **/
        font-size: 12px;
      }
      .last-comment {
        font-size: 12px;
        margin-top: 5px;
      }

      .forum-info {
        font-size: 12px;
        margin-top: 5px;
        span {
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .data-area {
      font-size: 18px;
      position: absolute;
      bottom: 15px;
      right: 0;
      width: 240px;
      span {
        width: 80px;
        text-align: left;
        float: left;
        //margin-left: 30px;
        i {
          font-size: 22px;
        }
        label {
          font-weight: normal;
          color: $blue;
          //margin-left: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.paginate-area {
  font-size: 18px;
  overflow: hidden;

  .paginate-action:first-child:nth-last-child(1) {
    width: 100%;
  }

  .paginate-action:first-child:nth-last-child(2),
  .paginate-action:first-child:nth-last-child(2) ~ .paginate-action {
    width: 50%;
  }

  .paginate-action {
    margin: auto;
    float: left;
    display: block;
    text-align: center;
    color: #666666;
    text-decoration: none;
    padding: 10px;

    &:nth-child(2) {
      border-left: 1px solid #666666;
    }

    &:hover {
      color: #ffffff;
      background: $blue;
    }
  }
}

#search-forum-list {
  padding: 10px 20px;
  ul {
    &.forum-list-group {
      li {
        list-style: none;
        border-bottom: 1px solid #eee;
        padding: 15px 0;
        a {
          color: $blue;
        }
      }
    }
  }
}

//我的部落
.my-forum-box {
  padding: 10px 20px;
  height: 275px;
  margin-bottom: 20px;

  .no-login-text, .no-forum {
    padding: 30px 10px;
  }

  .forum-list {
    overflow: auto;
    overflow-x: hidden;
    height: 240px;

    .forum-item {
      border-bottom: 1px solid #e2e2e2;
      height: 80px;
      padding: 10px 0 20px;
      color: #666666;

      &:hover {
        color: $blue;
      }

      &:last-child {
        border-bottom: hidden;
      }

      .logo {
        width: 50px;
        height: 50px;
        display: inline-block;
        margin-right: 18px;
        vertical-align: top;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        display: inline-block;
        width: calc(100% - 73px);
        .user {
          margin-top: 10px;
        }
      }
    }
  }
}

//部落人气榜
.forum-ranking-box {
  padding: 10px 20px;

  .static-title {
    color: #666;
    font-size: 16px;
  }
  .forum-ranking-list {
    height: 365px;
    overflow: auto;

    .forum-ranking-item {
      color: #666666;
      text-decoration: none;
      display: block;
      font-size: 16px;
      height: 40px;
      line-height: 40px;

      &:hover {
        color: $blue;
      }

      &.top-3-item {
        height: 80px;
        line-height: 1;
        padding: 10px 0 20px 0;
        border-bottom: 1px solid #e2e2e2;

        label {
          vertical-align: top;
          margin-right: 10px;
          width: 35px;
          height: 45px;
          text-align: center;
          line-height: 1;
          color: #ffffff;
          margin-left: 5px;
          margin-top: 5px;
          background: #2758b9;
          border-top: 7px solid #2758b9;
          border-left: 8px solid #2758b9;
          border-right: 8px solid #2758b9;
          border-bottom: 10px solid #ffffff;
          span {
            border-radius: 10px;
            border: 1px solid #ffffff;
            display: block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            margin: auto;
          }
        }

        .info {
          display: inline-block;
          width: 75%;
          .name {
            margin-bottom: 10px;
          }
          .intro {
            margin-bottom: 0;
            font-size: 12px;
          }

        }
      }

      label {
        display: inline-block;
        text-align: right;
        width: 25px;
        margin-right: 20px;
        color: $blue;
      }
    }
  }
}

.select-forum-page {
  padding: 100px 0 150px 0;
  a {
    display: block;
    img {
      width: 100%;
    }
  }
  p {
    margin-top: 20px;
    padding: 0 4px;
    font-size: 16px;
    color: #666;
  }
}

.add-more {
  text-align: right;

  a {
    color: #5075DA;
    text-decoration: none;
    font-size: 14px;
    &:hover {
      color: #3D62C4;
      text-decoration: underline;
    }
  }
}

.code-tribal {
  width: 100%;
  background-color: #f5f5f5;
  min-height: 500px;
  text-align: center;

  .recommend-tribal {
    padding-top: 10px;
    width: 100%;
    overflow: hidden;
    visibility: hidden;
    position: relative;
    &-title {
      margin: 0;
      color: #000;
      font-size: 18px;
    }
    .recommends {
      overflow: hidden;
      position: relative;
      width: 100%;
      margin-top: 20px;
      .recommend {
        float: left;
        width: 100%;
        position: relative;
        min-height: 200px;
        border: 1px solid #e3e3e3;
        background-color: #fff;
        .title-img-text {
          margin: 0 auto;
          margin-top: 5px;
          width: 90%;
          height: 30px;
          position: relative;
          .title-img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            margin: 0 auto;
          }
          .title-text {
            padding-top: 1px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            text-align: center;
          }
        }
        .recommend-a {
          display: block;
          width: 90%;
          margin: 0 auto;
          margin-top: 5px;
          margin-bottom: 5px;
          color: #666666;
        }
      }
    }
  }
  .my-tribal {
    width: 96%;
    margin: 0 auto;
    padding: 3px 8px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    margin-top: 20px;
    &-title {
      margin: 0 auto;
      color: #000;
      font-size: 16px;
    }
    .tribal {
      width: 100%;
      min-height: 40px;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 3px;
      margin-top: 5px;
      img {
        width: 20%;
        float: left;
        height: 100%;
      }
      .tribal-text {
        width: 76%;
        margin-left: 3%;
        float: left;
        height: 100%;
        text-align: left;
        &-title {
          font-size: 16px;
          color: #999999;
        }
        &-subtitle {
          font-size: 14px;
          color: #666;
        }
      }
    }
    .tribal:last-child {
      border-bottom: none;
    }
  }
}