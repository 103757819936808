#mobile {
  .content {
    @media only screen and (max-width: 500px) {
      //  首页文章
      .page-mobile-item {
        .left {
          height: 85px;
          flex: 1;
          img {
            width: 100%;
          }
        }
        .htext {
          flex: 1.6;
          font-size: 1rem;
          text-align: left;
          padding-left: 0.6rem;
          p {
            margin-bottom: 1.5rem;
            /*font-weight: bold;*/
            font-size: 0.95rem;
          }
          i:nth-of-type(1) {
            color: #b7b7b7;
            font-size: 1rem;
            position: relative;
            top: 0.05rem;
            font-weight: 600;
          }
          i:nth-of-type(2) {
            color: #B7B7B7;
            font-size: 1.2rem;
            position: relative;
            top: 0.15rem;
          }
        }
      }
      //  首页作品
      .work-mobile-item {
        .bottom {
          span {
            font-size: 0.8rem;
            color: #a1a1a1;
            margin-left: 0.1rem;
          }
        }
      }
      //  部落列表
      .forum-item {
        border-bottom: 1px solid #6de7ec;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        .left {
          img {
            width: 100%;

          }
        }
        .htext {
          p {
            font-size: 20px;
            margin-bottom: 10px;
          }
          span {
            color: #6b6b6b;
          }
        }
      }
    }
    //  首页文章
    .page-mobile-item {
      border-bottom: 0.1rem solid #f2f2f2;
      padding: 0.5rem;
      display: flex;
      flex-direction: row;
      .left {
        height: 128px;
        flex: 1.8;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .htext {
        flex: 7;
        text-align: left;
        p {
          font-size: 20px;
          margin-bottom: 10px;
        }
        span {
          color: #b7b7b7;
          font-size: 0.85rem;
        }
      }
    }
    //  首页帖子
    .topic-mobile-item {
      border-bottom: 1px solid #6DE7EC;
      padding: 10px;
      height: 7.3rem;
      .htext {
        float: left;
        width: 80%;
        text-align: left;
        font-size: 0.8rem;
        padding-left: 10px;
        i {
          color: #cecece;
          font-size: 1rem;
        }
        span {
          color: #999999;
        }
        p {
          display: block; /*内联对象需加*/
          width: 100%;
          word-break: keep-all; /* 不换行 */
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
          text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/
          margin-bottom: 0.5rem;
          /*font-weight: bold;*/
          font-size: 1rem;
        }
      }
      .left {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        top: 0.5rem;
        /*border: 1px solid #9db2ca;*/
        img {
          height: 100%;
        }
      }
    }
    //  首页作品
    .work-mobile-item {
      width: 47%;
      margin: 0.4rem 0 0 0;
      font-size: 1rem;
      flex-direction: column;
      display: inline-block;
      border: 0.1rem solid #ccc;
      border-radius: 5px;
      box-shadow: 0.1rem 0.1rem 0.5rem #ccc;
      .bottom {
        //height: 3rem;
        //line-height: 3rem;
        text-align: left;
        //border-top: 2px solid #ccc;
        //padding: 0 10px;
        height: 2.5rem;
        line-height: 2.5rem;
        border-top: 1px solid #ccc;
        padding: 0 0.65rem;
        img {
          width: 1.3rem;
          position: relative;
          top: -0.7rem;
          left: -0.1rem;
        }
        span:nth-of-type(1) {
          width: 4rem;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .left {
        height: 8rem;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .htext {
        height: 5.2rem;
        flex: 1.6;
        font-size: 1rem;
        text-align: left;
        line-height: 1.2rem;
        width: 90%;
        margin: 0 auto;
        /*padding-left: 10px;*/
        strong {
          color: #201718;
          font-size: 0.8rem;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
          -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
          -webkit-line-clamp: 1; /** 显示的行数 **/
          overflow: hidden; /** 隐藏超出的内容 **/
        }
        p {
          width: 10rem;
          margin-bottom: 0.5rem;
          color: #c8c8c8;
          font-size: 0.75rem;
          letter-spacing: 0.01rem;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
          -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
          -webkit-line-clamp: 1; /** 显示的行数 **/
          overflow: hidden; /** 隐藏超出的内容 **/
        }
        i {
          color: #cecece;
          font-size: 1rem;
          position: relative;
          top: 0.1rem;
        }
        span {
          color: #cecece;
          font-size: 0.68rem;
        }
      }
    }
    //  部落列表
    .forum-item {
      .left {
        height: 6rem;
        flex: 0.75;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
      .htext {
        flex: 1.6;
        font-size: 0.8rem;
        text-align: left;
        padding-left: 10px;
        letter-spacing: 0.1rem;
        line-height: 1.2rem;
        p {
          margin-bottom: 0.5rem;
          /*font-weight: bold;*/
          font-size: 1.1rem;
        }
        i:nth-of-type(1) {
          font-size: 0.94rem;
        }
      }
    }
    //  用户列表
    .user-mobile-item {
      height: 5rem;
      display: block;
      border-bottom: 1px solid #d5d5d5;
      padding: 0.5rem;
      flex-direction: row;
      .htext {
        float: left;
        width: 70%;
        position: relative;
        top: 1rem;
        left: 1rem;
        font-size: 1rem;
        text-align: left;
        padding-left: 10px;
        span {
          color: #ccc;
        }
      }
      .left {
        float: right;
        height: 3rem;
        width: 12%;
        margin: 1rem 2rem 0 0;
        border-radius: 50%;
        flex: 1;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
    }
  }
}