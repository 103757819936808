#article-list {
  .clear {
    clear: both;
  }

  body {
    background-color: white;
  }

  .redclick {
    color: red !important;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: $blue;
    text-decoration: none;
  }

  .comment-top {
    font-size: 25px;
    color: #333;
    border-bottom: 1px solid #e3e3e3;
    height: auto;
    padding-bottom: 14px;
    margin-top: 20px;
  }

  .comment {
    border-bottom: 1px solid #e3e3e3;
    height: auto;
    padding-bottom: 20px;
    .top {
      margin-top: 10px;
      height: 49px;
      line-height: 49px;
      width: 100%;
    }

    img {
      width: 48px;
      width: 48px;
    }

    span {
      margin-left: 20px;
      font-size: 16px;
      color: #333;
    }

    .body {
      margin-top: 10px;
      font-size: 14px;
    }

    .author {
      margin-top: 16px;
      font-size: 14px;
    }

    .updated {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .line {
    width: 100%;
    height: 20px;
    border-bottom: 1px solid #e3e3e3;
  }

  .hand {
    cursor: pointer;
    cursor: hand;
  }

  .new {
    border-radius: 10px;
    -webkit-box-shadow: #666 0px 0px 15px;
    -moz-box-shadow: #666 0px 0px 15px;
    box-shadow: #666 0px 0px 15px;
    height: auto !important;
    height: 200px;
    min-height: 200px;
    margin-top: 20px;
    margin-right: 2%;
    .topimg {
      .clear {
        height: 20px;
      }
    }
    .img-responsive {
      width: 100%;
      height: 90%;
    }

    .topcontent {
      height: 152px;
      min-height: 152px;
      .title {
        font-size: 20px;
        color: #000;
        margin-right: 20px;
        margin-bottom: 12px;
      }

      .clear {
        height: 20px;
      }

      .author {
        font-size: 15px;
        color: #999;
        margin-bottom: 100px;
      }
      .content {
        font-size: 15px;
        color: #333;
      }
    }
    .detail {
      height: 48px;
      line-height: 48px;
      width: 93%;
      border-top: 1px solid #e3e3e3;
      .share {
        float: right;
        height: 100%;
        width: 160px;
        .num {
          margin-right: 8px;
          font-size: 16px;
          color: #999;
          float: left;
        }
      }
      .data-area {
        //width: 240px;
        float: left;
        color: #999;
        span {
          width: 80px;
          float: left;
          i {
            font-size: 18px;
          }
          label {
            font-weight: normal;
            margin-left: 5px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.article-page {

  .article {
    img {
      max-width: 100%;
    }
  }
  
  .article-info {
    color: #999;
    padding: 10px 0 50px 0;
    font-size: 14px;
    span {
      margin-right: 20px;
    }
  }

  .share {
    float: right;
    position: relative;
    margin-right: -50px;
    .share-title {
      margin: 10px 20px 0 0;
      float: left;
    }
    .social-share {
      float: right;
    }
  }

  .praise-area {
    text-align: center;
    a {
      color: $blue;
      width: 38px;
      height: 38px;
      border-radius: 19px;
      border: 1px solid $blue;
      display: inline-block;
      i {
        font-size: 24px;
        line-height: 36px;
      }
      &.rewards {
        i {
          font-style: normal;
          font-size: 20px;
          font-weight: 600;
        }
      }
      &:hover, &.has-praised {
        color: #fff;
        background-color: $blue;
      }
    }
  }

  .recommend-top {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 10px;
    color: #333;
  }

  .recommend {
    margin-top: 10px;
    .top {
      font-size: 18px;
      color: #333;
    }
    .recommend-info {
      margin-top: 10px;
      font-size: 14px;
    }
    .recommend-data {
      margin-top: 10px;
      font-size: 14px;
      padding-bottom: 10px;
      border-bottom: 1px solid #d3d3d3;
      span {
        margin-right: 20px;
      }
    }
  }
}

#gotop {
  display: none;
  width: 55px;
  height: 55px;
  position: fixed;
  right: 200px;
  bottom: 200px;
}