.ranking-page {
  .headImg {
    margin: 20px auto 0;
    width: 100%;

    height: auto;
    border-radius: 10px;
    -webkit-box-shadow: #666 0px 0px 15px;
    -moz-box-shadow: #666 0px 0px 15px;
    box-shadow: #666 0px 0px 15px;
  }

  .clear {
    clear: both
  }

  a, a:hover, a:active, a:link {
    text-decoration: none;
  }

  .tab {
    width: 1200px;
    margin: 0 auto;
  }

  .ranking-actions {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #999;
    height: 48px;
    margin: 20px auto 0;
    background: #ffffff;
  }

  .ranking-actions .same {
    width: 22%;
    height: 100%;
    float: left;
    text-align: center;
  }

  .ranking-actions .same .mid {
    margin: 0 auto;
    height: 46px;
    width: 50%;
    line-height: 46px;
    text-align: center;
    font-size: 15px;
    color: #e3e3e3;
  }

  .same .active {
    border-bottom: 2px solid #2758b9;
  }

  .same .mid a {
    color: #666;
    display: block;
    font-weight: bold;
  }

  .same .mid.active a,
  .same .mid a:hover {
    color: #2758b9;
  }

  #honorRanking-list, #pointRanking-list {
    margin-top: 30px;
  }

  .honor {
    height: 200px;
  }

  .honor .img {
    border-radius: 6px;
    height: 200px;
    -webkit-box-shadow: #666 0px 0px 10px;
    -moz-box-shadow: #666 0px 0px 10px;
    box-shadow: #666 0px 0px 10px;
  }

  .honor .img .img-top {
    height: 50px;
    width: 100%;
  }

  .honor .img .img-top .left-top {
    float: left;
    height: 48px;
    width: 48px;
    border-radius: 6px 0 0 0;
    overflow: hidden;
  }

  .honor .img .img-top .mid-top {
    float: left;
    height: 48px;
    width: 48px;
    margin-left: 16px;
    margin-top: 6px;
  }

  .honor .img .img-top .right-top {
    width: 54%;
    height: 100%;
    float: left;
    margin-left: 10px;
    margin-top: 6px;
  }

  .honor .img .first-line {
    height: 12px;
    width: 90%;
    border-bottom: 1px solid #e3e3e3;
    margin: 0 auto;
    margin-top: 6px;
  }

  .honor .img .second-line {
    height: 2px;
    width: 90%;
    border-bottom: 1px solid #e3e3e3;
    margin: 0 auto;
  }

  .honor .img .personintro {
    color: #666;
    font-size: 16px;
    width: 90%;
    height: 32px;
    line-height: 32px;
    margin: 0 auto;
    text-align: left;
  }

  .honor .img .personsig {
    color: #999;
    font-size: 14px;
    width: 90%;
    height: 42px;
    margin: 0 auto;
    text-align: left;
  }

  .honor .img .reprework {
    color: #666;
    font-size: 16px;
    width: 90%;
    height: 28px;
    line-height: 25px;
    margin: 0 auto;
    text-align: left;
  }

  .honor .img .repreworkde {
    font-size: 14px;
    width: 90%;
    height: 25px;
    line-height: 25px;
    text-align: left;
    margin: 0 auto;
  }

  .honor .img .repreworkde a {
    color: $blue;
  }

  .honor .img .img-top a {
    color: #666;
  }

  .honor .img .img-top a:hover {
    color: #2758b9;
  }

  .imgspace {
    width: 4%;
    float: left;
    height: 100%;
  }

  .ranking {
    margin-top: 20px;
    height: auto;
  }

  .ranking .top {
    //height: 40px;
    line-height: 40px;
    width: 100%;
    border: 1px solid #eeeeee;
    border-bottom: 1px solid #cfcfcf;
    border-radius: 6px 6px 0 0;
    padding: 0 15px;
    background: #ffffff;
  }

  .ranking .top .navigation {
    text-align: center;
    width: 9%;
    height: 40px;
    line-height: 40px;
    float: left;
  }

  .ranking .top .navigation .btnn {
    clear: both;
    line-height: 24px;
    height: 24px;
    margin: 0 auto;
    width: 84px;
    border-radius: 10px;
    margin-top: 6px;
  }

  .ranking .top .navigation .btnn a {
    color: inherit;
    display: block;
  }

  .ranking .top .navigation .btn-active,
  .ranking .top .navigation .btnn:hover {
    background-color: #2758b9;
    color: #fff;
  }

  .ranking > .top > .navigation > .btn-active > a:hover {
    color: #fff;
  }

  .ranking > .top > .navigation > .btn-active > a:visited {
    color: #fff;
  }

  .ranking > .top > .navigation > .btn-active > a:active {
    color: #fff;
  }

  .ranking > .top > .navigation > .btn-active > a {
    color: #fff;
  }

  .tab-content {
    background: #ffffff;
  }

  .middle-table {
    border-collapse: inherit;
    margin-bottom: 50px;
  }

  .middle-table td, th {
    text-align: center;
    font-weight: normal;
    border-bottom: 1px solid #eeeeee !important;
    border-top: none !important;
  }

  .middle-table td a {
    color: $blue;
  }

  .middle-table th {
    width: 13%;
    font-size: 18px;
    color: #333333;
  }

  .center {
    text-align: center;
  }

  .borderline {
    height: 40px;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
  }

  .line {
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  #famous-story {
    margin-top:30px;
    .navigation {
      background-color: #fff;
      color: #fff;
      background-image: none !important;
      width: 20px;
      span {
        color: #999;
      }
    }
    .rowLine {
      border: 2px solid #eeeeee;
      border-radius: 8px;
      background-color: #fff;
      .title {
        height: 40px;
        line-height: 40px;
        width: 94%;
        margin-left: 3%;
        border-bottom: 1px solid #e3e3e3;
        text-align: center;
        color: #666;
        font-size: 18px;
      }
      .body {
        width: 100%;
        height: 201px;
        .bodyInner {
          margin-left: 2%;
          width: 96%;
          height: 201px;
          .right-border {
            border-right: 1px solid #e3e3e3;
          }
          .floatLeft {
            float: left;
            width: 16.66%;
            height: 132px;
            text-align: center;
            margin-top: 33px;
            a:hover {
              text-decoration: none;
              color: #2758b9 !important;
            }
            .img {
              width: 98px;
              height: 98px;
              border: 2px solid #ddd;
            }
            p {
              margin-top: 17px;
              font-size: 16px;
              a {
                color: #666;
              }
            }
          }
        }
      }
    }
  }
}