//  个人中心
.mobile-user {
  height: 100%;
  width: 100%;

  .header {
    height: 20rem;
    background-size: 100% 100%;
    .user_center_avater {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .my_synopsis {
      color: white;
      .user_name {
        font-size: 2rem;
      }
    }
  }

  //@media only screen and (max-width: 500px) {
  .content {
    .content_achievement {
      li {
        text-align: left;
        height: 3.5rem;
        font-size: 0.8rem;
        line-height: 3.5rem;
        background: #f8f8fa;
        border-top: 0.2rem solid #ffffff;
        span:nth-of-type(1) {
          margin: 1rem;
          font-size: 0.9rem;
        }
        img {
          width: 1rem;
          position: relative;
        }
        .frame {
          display: inline-block;
          width: 6.5rem;
          height: 0.5rem;
          border-radius: 0.2rem;
          box-shadow: -0.01rem -0.01rem 0.01rem;
          background-color: #dedede;
          margin-left: 0.5rem;
        }
        &:nth-of-type(1) .frame .frame_content {
          background-color: #6b6bff;
          height: 0.5rem;
          width: 50%;
          border-radius: 0.2rem;
          position: relative;
        }
        &:nth-of-type(2) .frame .frame_content {
          background-color: #6b6bff;
          height: 0.5rem;
          width: 80%;
          border-radius: 0.2rem;
          position: relative;
        }
      }
    }
    .content_list {
      li {
        height: 3.6rem;
        text-align: left;
        font-size: 0.9rem;
        padding-left: 1.3rem;
        line-height: 3.6rem;
        border-bottom: 0.1rem solid #f2f2f2;
        i {
          color: #416ada;
          font-size: 1.2rem;
          float: right;
          margin-right: 1rem;
          font-weight: 600;
        }
        p {
          display: inline-block;
          width: 10rem;
          padding-left: 2rem;
          color: #a09e9e;
          span {
            color: #737171;
          }
        }
        .public-work {
          display: inline-block;
          width: auto;
          padding-left: 2rem;
          color: #a09e9e;
        }
        .not-public-work {
          display: inline-block;
          width: auto;
          color: #a09e9e;
        }
      }
    }
  }
  .header {
    height: 8rem !important;
    display: block !important;
    background-size: 100% 100%;
    .user_center_avater {
      margin: 0.5rem auto 0.5rem;
      border-radius: 3rem;
      width: 3rem;
      height: 3rem;
      overflow: hidden;
    }
    .my_synopsis {
      .user_name {
        font-size: 1rem;
      }
      .user_autograph {
        font-size: 0.8rem;
      }
    }
  }
  #prompt {
    width: 0;
    height: 0;
    border-left: 0.2rem solid transparent;
    border-right: 0.2rem solid transparent;
    border-top: 0.4rem solid #1d55a9;
    position: absolute;
    top: -0.5rem;
    right: -0.2rem;
  }

  //}
}

//  我的作品、帖子、部落
.mobile-work, .mobile-topic, .mobile-forum {
  height: 100%;
  width: 100%;

  @media only screen and (max-width: 500px) {
    .header {
      height: 8rem !important;
      font-size: 2rem;
      display: flex;
      flex-direction: row;
      background-size: 100% 100%;
      position: relative;
      i {
        line-height: 8rem;
        color: #fff;
        font-size: 5rem;
      }
      .iconfont {
        color: white;
        font-size: 2rem;
        line-height: 6.6rem;
        margin-left: 2rem;

      }
      .user_center_avater {
        border-radius: 3.6rem;
        width: 3.6rem;
        height: 3.6rem;
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1.8rem;
        margin-top: -2.8rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .my_name {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 1.5rem;
        color: white;
        font-size: 1rem;
      }
    }

  }
}

//  设置
.setting-mobile {
  height: 100%;
  width: 100%;
  //@media only screen and (max-width: 500px) {

  //  账号与安全
  .security-mobile {
    width: 100%;
    height: 100%;
  }
  .content {
    .set_list {
      li {
        height: 5rem;
        text-align: left;
        padding-left: 1.3rem;
        line-height: 5rem;
        border-bottom: 0.1rem solid #f2f2f2;
        font-size: 1rem;
        i {
          color: #6767de;
          font-size: 1.5rem;
          vertical-align: middle;
          line-height: 5rem;
          float: right;
          margin-right: 1rem;
          font-weight: 600;
        }
      }
    }
    .sign_out {
      width: 12rem;
      height: 3rem;
      margin: 0 auto;
      border-radius: 0.5rem;
      background-color: #83B0B6;
      text-align: center;
      line-height: 3rem;
      font-size: 1.4rem;
      margin-top: 13rem;
      border: 1px solid #2c5aba;
      a {
        color: #fffafb !important;
      }
    }
  }

  /*---------------------------------------------------------------------------------------------适配开始*/

  .header {
    height: 8rem !important;
    border-bottom: 0.1rem solid #f3f3f3;
    h3 {
      font-size: 1.5rem;
      line-height: 8rem;
      font-weight: 400;
      margin: 0 auto;
    }
  }

  .footer {
    height: 3.5rem;
    font-size: 0.6rem;

  }

  .foot-nav dl dt img {
    width: 2rem;
    height: 2rem;
  }

  .foot-nav dl dd {
    margin-top: 0;
  }

  //}
}

//  账号与安全
.security-mobile {
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 500px) {

    .header {
      height: 8rem;
      border-bottom: 0.1rem solid #f2f2f2;
      h3 {
        font-size: 1.5rem;
        line-height: 8rem;
        line-height: 10rem;
        font-weight: normal;
        display: inline-block;
        margin: 0;
        vertical-align: middle;
      }
      i {
        font-size: 1.3rem;
        margin-right: 2rem;
        vertical-align: middle;
        color: #6767de;
      }
    }

    .content {
      .secrecy_list {
        li {
          height: 5rem;
          text-align: left;
          padding-left: 1.3rem;
          line-height: 5rem;
          border-bottom: 0.1rem solid #f2f2f2;
          font-size: 1rem;
          input {
            width: 50%;
            margin-left: 1rem;
            height: 80%;
            font-size: 1rem;
          }
          span {
            color: #6767de;
            font-size: 1rem;
            font-weight: 100;
            float: right;
            margin-right: 1rem;
          }
          .edit {
            font-size: 1.3rem;
            position: relative;
            top: 0.1rem;
          }
          .change-password {
            font-size: 1.3rem;
            float: right;
            margin-right: 1rem;
            color: #6767de;
            font-weight: 600;
          }
        }
      }
    }

    .footer {
      height: 3.5rem;
      font-size: 0.6rem;
      dl {
        dt {
          img {
            width: 2rem;
            height: 2rem;
          }
        }
        dd {
          margin-top: 0;
        }
      }
    }
  }
}

//  天才榜
.rank-mobile {
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 500px) {
    .header {
      height: 7.4rem !important;
      img {
        height: 100%;
      }

    }

    .content {
      ul {
        width: 100%;
        position: fixed;
        z-index: 2;
        background-color: white;
        &:after {
          content: "\0020";
          display: block;
          height: 0;
          clear: both;
        }
        li {
          width: 50%;
          height: 4.5rem;
          line-height: 4.5rem;
          text-align: center;
          float: left;
          font-size: 0.9rem;
          &:nth-of-type(1) i {
            font-size: 2rem;
            position: relative;
            top: 0.35rem;
            left: -0.8rem;
          }
          &:nth-of-type(2) i {
            font-size: 1.7rem;
            position: relative;
            top: 0.28rem;
            left: -1rem;
          }
        }
      }
      .my_ranking {
        margin-top: 80px;
        height: 2rem;
        text-align: left;
        font-size: 0.9rem;
        .my_portrait {
          display: inline-block;
          width: 1.8rem;
          height: 1.8rem;
          border-radius: 50%;
          border: 1px solid #ccc;
          overflow: hidden;
          position: relative;
          top: 0.6rem;
          left: 0.2rem;
          margin: 0 1rem;
          img {
            width: 100%;
          }
        }
        img {
          top: 0.2rem;
          width: 1rem;
        }
      }
      table {
        border-collapse: separate;
        border-spacing: 20px 24px;
        font-size: 0.9rem;
      }
    }
    .bgon {
      background-color: #83B0B6;
      color: #fcfffd;
    }

  }
}