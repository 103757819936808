#mobile {
  height: 100%;
  width: 100%;

  img {
    max-width: 100%;
  }
  i, em, b {
    font-style:normal;
    font-weight:normal;
  }
  :-moz-placeholder {color:#ccc;}
  ::-moz-placeholder {color:#ccc;}
  ::-webkit-textarea-placeholder {color:#ccc;}
  :-ms-textarea-placeholder {color:#ccc;}

  /*img{
      width:100%;
      vertical-align:top;
  }  */
  input,textarea,select{
    -webkit-appearance:none;
    font-size:1.2rem;
    border:0;
    background:transparent;
    font-family:"黑体","Microsoft YaHei","Helvetica Neue",Helvetica,STHeiTi,sans-serif;
    outline:none;
  }
  input,a,span{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  /*-----------------------------------------------------------------------------------去除a标签的默认样式*/
  a{
    color: black;
    text-decoration: none;
  }

  .page {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 100%;
    .content {
      position: relative;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  .on {
    background-color: #83b0b6;
    color: #f8f8ff;
  }

  .nav_on {
    color: #83b0b6;
  }

  //@media only screen and (max-width: 500px) {
    //  页脚
    .footer {
      height: 3.5rem;
      font-size: 0.8rem;

      .foot-nav {
        display: flex;
        flex-direction: row;
        flex: 1;
        display: -webkit-box;
        height: 100%;
        line-height: 1rem;
        text-align: center;
        & * {
          //display: block;
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          flex: 1;
        }
        dl {
          flex: 1;
          height: 100%;
          width: 100%;
          margin-bottom: 0;
          dt {
            margin-top: 0.125rem;
            width: 100%;
            img {
              margin: 0 auto;
              width: 1.8rem;
              height: 1.8rem;
            }
          }
          dd {
            margin-top: 0.2rem;
            height: 1rem;
          }
        }
      }
    }
  //}
}