@import "nav";

.mobile-front {

  height: 100%;

  i, em, b {
    font-style: normal;
    font-weight: normal;
  }
  :-moz-placeholder {
    color: #ccc;
  }
  ::-moz-placeholder {
    color: #ccc;
  }
  ::-webkit-textarea-placeholder {
    color: #ccc;
  }
  :-ms-textarea-placeholder {
    color: #ccc;
  }

  /*img{
      width:100%;
      vertical-align:top;
  }  */
  input, textarea, select {
    -webkit-appearance: none;
    font-size: 1.2rem;
    border: 0;
    background: transparent;
    font-family: "黑体", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, sans-serif;
    outline: none;
  }
  input, a, span {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  /*-----------------------------------------------------------------------------------去除a标签的默认样式*/
  a {
    color: black;
    text-decoration: none;
  }

  /*------------------------------------------------------------------------------------flex最外框*/
  .page {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 100%;
    @media only screen and (max-width: 500px) {
      .logo, .user_avatar {
        flex: 0 0 3.5rem;

      }
      .content {
        .content_nav dl {
          font-size: 0.8rem;
        }
        .content_text {
          box-shadow: #7b7b7b 0 1px 7px;
        }
      }

      /*----------------------------------------------------------------------------------------flex头部*/
      .swiper-container {
        width: 100%;
        /* padding-top: 10px;*/
        padding-bottom: 0.5rem;
        height: 150px;

      }
      .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 300px;
        height: 150px;
        border-radius: 5px;
      }
    }
    /*---------------------------------------------------------------------------------------flex内容区*/
    .content {
      position: relative;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      /*模块导航栏*/
      .content_nav {
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;
        padding: 0 15px;
        dl {
          flex: 1;
          text-align: center;
          font-size: 20px;
          dt {
            width: 100%;
            height: 2.5rem;

          }
          dd {
            margin-bottom: 0.5rem;

          }
          &:nth-of-type(1) dt {
            background: url(../img/article.png) no-repeat center;
          }
          &:nth-of-type(2) dt {
            background: url(../img/selected_works.png) no-repeat center;
          }
          &:nth-of-type(3) dt {
            background: url(../img/selected_card.png) no-repeat center;
          }
        }
      }
      .on {
        background-color: #83b0b6;
        color: #f8f8ff;
      }

      .nav_on {
        color: #83b0b6;
      }
    }
  }

}

.mobile-search {
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 500px) {
    .logo, .user_avatar {
      flex: 0 0 3.5rem;

    }
    /*----------------------------------------------------------------------------------------------模板适配样式*/
    .content {
      ul {
        width: 100%;
        height: 3.4rem;
        font-size: 1.2rem;
        position: fixed;
        z-index: 2;
        top: 3.2rem;
        li {
          width: 20%;
          height: 3rem;
          line-height: 3rem;
          text-align: center;
          float: left;
          background-color: white;
        }
      }
      div {
        display: block;
      }
      .user-mobile-item {
        display: block;
        .left {
          height: 85px;
          flex: 1;
          overflow: hidden;
          margin-right: 0.2rem;
          img {
            width: 100%;
          }
        }
        .htext {
          /*	flex: 1.6;*/
          font-size: 1rem;
          text-align: left;
          padding-left: 10px;
          p {
            margin-bottom: 0.5rem;
            /*font-weight: bold;*/
            font-size: 1.1rem;
          }
        }
      }
      .mobile_title {
        margin-top: 3rem;
        height: 5rem;
        width: 100%;
        text-align: center;
        line-height: 5rem;
        font-size: 1.3rem;
      }
    }
    #content_top:after {
      content: "\0020";
      display: block;
      height: 0;
      clear: both;
    }

    .footer {
      height: 3.5rem;
      font-size: 0.6rem;
    }

    .foot-nav dl dt img {
      width: 1.8rem;
      height: 1.8rem;
    }

    .foot-nav dl dd {
      margin-top: 0;
    }
    #bottom_style {
      width: 20%;
      position: absolute;
      top: 3rem;
      height: 0.2rem !important;
      background: dodgerblue;
    }
  }
  .content {
    margin-top: 3.2rem;
  }
  #article_modular, #topic_modular, #work_modular, #forum_modular {
    display: none;
  }
}

.mobile-match {
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 500px) {
    .match_head {
      display: none;
    }

    .content {
      .about_match {
        margin-bottom: 2rem;
        text-align: left;
        line-height: 1.5rem;
        h4 {
          text-indent: 1rem;
          line-height: 2rem;

        }
        p {
          width: 90%;
          margin: 0 auto;
          text-indent: 2rem;
          font-size: 0.9rem;
        }
      }
    }
  }
}