// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "user";
@import "user2";
@import "work";
@import "scratch";
@import "topic";
@import "forum";
@import "front_page";
@import "rank";
@import "page";
@import "hacker";
@import "mobile/content-list";
@import "mobile/front";
@import "mobile/user";
@import "mobile/message";
@import "mobile/forum";
@import "mobile/topic";
@import "mobile/page";
@import "mobile/footer";
@import "scratch_game";

@media (min-width: 1200px) {
  .min-container {
    width: 1130px;
  }

  .max-container {
    width: 1230px;
  }
}

code, kbd, samp {
  font-family: consolas, courier, sans-serif !important;
}

.hide {
  display: none;
}

.row.no-gap {
  margin-left: 0;
  margin-right: 0;
}

.row.no-gap > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-resize {
  resize: none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.breadcrumb {
  background: #ffffff;
  font-size: 16px;
  padding-top: 0;
  margin-bottom: 10px;
}

.breadcrumb > li + li:before {
  content: '>';
  font-size: 22px;
  color: $blue;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

body {
  font-family: "Microsoft Yahei", sans-serif !important;
  background: #fafafa !important;
  padding-right: 0 !important;
}

.navbar {
  margin-bottom: 0;
}

.shadow-box {
  -webkit-box-shadow: 0 0 5px #888888;
  box-shadow: 0 0 5px #888888;
  background: #ffffff;
}

.btn-bluewhite {
  border: #2758b9 1px solid;
  color: #2758b9;
  border-radius: 8px;
  padding: 3px 15px;
  background: #fff;
  &:hover, &:active, &:focus {
    background: #2758b9;
    color: #ffffff;
  }
}

.btn {
  margin-top: 5px;
  margin-bottom: 5px;
  border-width: 1px;
}

.btn-blue {
  color: #ffffff;
  background: #2758b9;
  border-radius: 8px;
  padding: 3px 15px;
  margin: 0 1px;
  &:hover, &:active, &:focus {
    background: #2758b9;
    color: #ffffff;
  }
}

.btn-red {
  color: #ffffff;
  background: #FA2A00;
  border-radius: 8px;
  padding: 3px 15px;
  margin: 0 1px;
  &:hover, &:active, &:focus {
    background: #FA2A00;
    color: #ffffff;
  }
}

/*a  upload */
.a-upload {
  padding: 4px 10px;
  height: 34px;
  line-height: 26px;
  position: relative;
  cursor: pointer;
  color: $blue;
  background: #ffffff;
  border: 1px solid $blue;
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  *display: inline;
  *zoom: 1
}

.a-upload input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer
}

.a-upload:hover {
  color: #ffffff;
  background: $blue;
  border-color: $blue;
  text-decoration: none
}

//.top-menu
.top-menu {
  min-height: 64px;
  //padding-bottom: 1px;

  .navbar-header {
    .navbar-brand {
      padding: 10px 15px;
      color: #333333;
      &:hover {
        color: $blue;
      }
    }
  }
  .collapse {
    & > .nav > li > a {
      height: 63px !important;
      line-height: 35px;
      font-size: 16px;
      color: #333333;
      border-bottom: 2px solid transparent;

    }

    .menu {
      & > li > a {
        &.active, &:hover {
          color: $blue;
          border-color: $blue;
        }
      }
    }
    ul {
      &.navbar-right {
        .message-icon {
          padding-top: 10px;
        }
      }
    }

  }
}

.pc {
  .footer {
    padding: 40px 0;
    background: #dfe3ec;
    margin-top: 80px;
    text-align: center;

    .wechat-qrcode-box {
      text-align: center;
    }
    .footer1 {
      margin: 0 100px;
      border-bottom: 1px solid #666;
      .codepku-brand {
        color: #999;
        font-size: 20px;
      }

      li {
        display: inline-block;
        list-style: none;
        text-decoration: none;
        margin-right: 10px;
        &.footer-link-item {
          margin-right: 10%;
          a {
            color: #666;
            &:hover {
              color: #999;
            }
          }
        }
      }
      .codepku-other-link {
      }
      .codepku-link {
        ul {
          padding-left: 0;
          margin-bottom: 0;
        }
        li a {
          margin-right: 5px;
          display: block;
          width: 35px;
          height: 35px;
          border: 1px solid #D0D6D9;
          background: #ececec;
          border-radius: 50%;
          padding: 5px;
          color: #464646;
          i {
            font-size: 20px;
          }
        }
      }
      .codepku-friend-link {
        color: #666;
        margin-bottom: 40px;
        li a {
          display: block;
          color: #666;
          text-align: center;
          &:hover {
            color: #999;
          }
        }
        p {
          color: #666;
        }
        hr {
          background: #666;
          height: 1px;
          border: none;
          margin-top: 0px;
          margin-bottom: 5px;
        }
      }
    }

    .footer2 {
      padding-top: 20px;
      .mid p {
        color: #666;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    .footer1 {
      margin: 0;
      li {
        &.footer-link-item {
          margin-right: 3%;
        }
      }
    }
  }
}

@media (max-width: 500px){
  .pc .footer{
    padding:10px 0;
  }
  .pc .footer .container-fluid .footer1{
    margin:0;

  }
  .pc .footer .container-fluid .footer1 .codepku-other-link{
    padding:0;

  }
  .pc .footer .container-fluid .footer1 .codepku-other-link ul{
    padding:0;
  }

  .pc .footer .container-fluid .footer1 .codepku-other-link ul li{
    margin-right:10px;
  }
  .pc .footer .container-fluid .footer1 .codepku-friend-link{
    margin-bottom:10px;
  }
  .pc .footer .container-fluid .footer1 .codepku-link{
    display:none;
  }
  .pc .footer .container .footer2 .mid a{
    display:block;
  }

}

.common-red {
  color: #f15a24;
}

.common-blue {
  color: $blue;
}

.top-menu {
  min-height: 64px;
}

.top-menu-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.top-menu .navbar-header .navbar-brand {
  padding: 10px 15px;
  color: #333333;
}

.top-menu .navbar-header .navbar-brand:hover {
  color: #2758b9;
}

.top-menu .collapse > .nav > li > a {
  height: 63px !important;
  line-height: 35px;
  font-size: 16px;
  color: #333333;
  border-bottom: 2px solid transparent;
}

.top-menu .collapse .menu > li > a.active, .top-menu .collapse .menu > li > a:hover {
  color: #2758b9;
  border-color: #2758b9;
}

.top-menu .collapse ul.navbar-right .search {
  display: inline-block;
  height: 35px;
  width: auto;
  margin-top: 14px;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
}

.top-menu .collapse ul.navbar-right .search input {
  margin-top: 1px;
  height: 31px;
  outline: none;
  width: 65px;
  font-size: 16px;
  border: none;
  color: #666;
  margin-left: 8px;
  transition: width 0.5s;
}

.top-menu .collapse ul.navbar-right .search input:focus {
  width: 175px;
}

.top-menu .collapse ul.navbar-right .search i {
  margin-right: 8px;
}

.top-menu .collapse ul.navbar-right .message-icon {
  padding-top: 10px;
}

#gotop {
  display: none;
  width: 55px;
  height: 55px;
  position: fixed;
  right: 200px;
  bottom: 200px;
  cursor: pointer;
  background: url('../img/gotop.png') no-repeat -70px 0px;
}

//.nav-search-page {
//  @media (min-width: 950px) {
//    .col-md-8 {
//      width: 83.33333333%;
//    }
//
//    .col-md-4 {
//      width: 16.66666667%;
//    }
//  }
//}
