.clear {
    clear: both;
}

body {
    background-color: white;
}

.redclick {
    color: red !important;
}

a {
    text-decoration: none;
}

.hidden {
    dispaly: none;
}

a:hover {
    text-decoration: none;
}

.likeaddone {
    position: absolute;
    left: 110px;
    top: 133px;
    font-size: 16px;
    color: red;
}

.comment-top {
    font-size: 25px;
    color: #333;
    border-bottom: 1px solid #e3e3e3;
    height: auto;
    padding-bottom: 14px;
    margin-top: 20px;
}

.comment {
    border-bottom: 1px solid #e3e3e3;
    height: auto;
    padding-bottom: 20px;
    .top {
        margin-top: 10px;
        height: 49px;
        line-height: 49px;
        width: 100%;
    }
}

.new1 {
    .line {
        width: 100%;
        height: 20px;
        border-bottom: 1px solid #e3e3e3;
    }

    .hand {
        cursor: pointer;
    }
    min-height: 100px;
    width: 100%;
    padding: 0.5rem 1rem;
    .leftnew {
        padding-right: 0px;
        float: left;
        width: 9.5rem;
        min-height: 100px;
        .img {
            width: 7.7rem;
            height: 5.6rem;
            overflow: hidden;
        }
    }
    .rightnew {
        text-align: left;
        height: 100%;
        overflow: hidden;
        .topcontent {
            min-height: 52px;
            .title1 {
                font-size: 20px;
                color: #000;
                margin-right: 20px;
                margin-bottom: 0px;
            }
            .author1 {
                font-size: 14px;
                color: #999;
                margin-top: 0.45rem;
                margin-bottom: 0px;
                height: 23px;
                line-height: 23px;
                img{
                    height: 20px;
                    width: 20px;
                    border-radius: 20px;
                    margin-right: 5px;
                }
            }
            .body1 {
                font-size: 14px;
                color: #999;
                height: 40px;
                margin-top: 0.3rem;
                margin-bottom: 0px;
            }
        }
        .line {
            border-bottom: 1px solid #e3e3e3;
            height: 1.2rem;
            width: 93%;
        }
        .detail {
            height: 48px;
            line-height: 48px;
            width: 100%;
            .share {
                float: right;
                height: 100%;
                width: 180px;
                overflow: hidden;
                .num {
                    color: #999;
                    font-size: 0.8rem;
                }
            }
            .num {
                margin-right: 1rem;
                color: #999;
                font-size: 0.8rem;
            }
            .lastnum {
                color: #999;
                font-size: 0.8rem;
            }
        }
    }
    .detail-xs {
        height: 48px;
        line-height: 48px;
        width: 100%;
        padding-left: 1rem;
        .share {
            float: right;
            height: 100%;
            width: 180px;
            overflow: hidden;
            .num {
                color: #999;
                font-size: 0.8rem;
            }
        }
        .num {
            margin-right: 1rem;
            color: #999;
            font-size: 0.8rem;
        }
        .lastnum {
            color: #999;
            font-size: 0.8rem;
        }
    }
}
.new1:hover{
    background-color: #fff;
}

html {
    font-size: 62.5%;
    /* 10÷16=62.5% */
}

@media only screen and (min-width: 481px) {
    html {
        font-size: 94%!important;
        /* 15.04÷16=94% */
    }
}

@media only screen and (min-width: 561px) {
    html {
        font-size: 109%!important;
        /* 17.44÷16=109% */
    }
}

@media only screen and (min-width: 740px) {
    html {
        font-size: 125%!important;
        /* 20÷16=125% */
    }
}

@media (min-width: 1200px) {
    .lg-left {
        width: 35.4rem !important;
        padding: 0px !important;
        float: left !important;
    }
    .lg-right {
        width: 17.5rem;
        padding-left: 2.5rem;
        padding-right: 0px;
        float: left;
    }
}
