.mobile-topic-show {
  width: 100%;
  height: 100%;
  * {
    box-sizing: content-box;
  }
  /*------------------------------------------------------------------------------------flex最外框*/
  .page {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 100%;
    .content {
      position: relative;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      overflow: auto;
      -webkit-overflow-scrolling: touch;

    }
  }

  /*---------------------------------------------------------------------------------------------适配开始*/
  @media only screen and (max-width: 500px) {
    .header {
      border-bottom: 0.0625rem solid #F3F3F3;
      font-size: 1rem;
      .card_top {
        width: 100%;
        line-height: 3.2rem;
      }
      i {
        float: left;
        font-size: 1.2rem;
        color: #5c7dca;
        margin-left: 1.5rem;
        font-weight: 600;
      }
    }

    .content {
      .tribe_label {
        padding: 0.4rem 1rem;
        display: flex;
        flex-direction: row;
        border-bottom: 0.0625rem solid #f3f3f3;
        font-size: 0.8rem;
        img {
          flex: 1;
          width: 4rem;
          height: 4rem;
        }
        .tribe_label_title {
          flex: 7;
          line-height: 2rem;
          text-align: left;
          padding-left: 0.5rem;
          h4 {
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0.1rem;
          }
          .icon-jing {
            font-size: 1.6rem;
            position: relative;
            top: 0.2rem;
            color: #2d53b8;
            font-weight: 500;
          }
          .nick {
            color: #666;
          }
          .info {
            float: right;
            margin-right: 0.2rem;
            i {
              font-size: 1rem;
              margin: 0 0.08rem;
              &:nth-of-type(1) {
                position: relative;
                top: 0.05rem;
                margin-right: 0.2rem;
              }
              &:nth-of-type(3) {
                font-size: 0.8rem;
                margin: 0 0.25rem;
              }
            }
            span {
              color: #354fd8;
            }
          }
        }
      }
      .period_details {
        text-align: left;
        padding: 0.6rem 1rem;
        //h4 {
        //  &:nth-of-type(1) {
        //    letter-spacing: 0.05rem;
        //    font-weight: 500;
        //  }
        //  &:nth-of-type(2) {
        //    color: #636363;
        //    font-weight: 500;
        //  }
        //}
        //p {
        //  line-height: 1.5rem;
        //  text-indent: 2rem;
        //  font-size: 0.8rem;
        //  color: #636363;
        //  letter-spacing: 0.05rem;
        //  margin-bottom: 1rem;
        //}
        //img {
        //  width: 100%;
        //}
      }
      .new_comment {
        text-align: left;
        .the_new_titel {
          font-size: 0.8rem;
          padding: 0.5rem 1rem;
          border-bottom: 0.05rem solid #f3f3f3;
        }
        .comment_moble {
          padding: 0.5rem 1rem;
          .comment_content {
            display: flex;
            flex-direction: row;
            line-height: 1.2rem;
            font-size: 0.85rem;
            img {
              flex: 0.5;
              width: 1.6rem;
              height: 1.6rem;
              margin: 0.3rem 0.5rem 0 0;
            }
            div {
              flex: 7;
              i {
                &:nth-of-type(1) {
                  position: relative;
                  top: 0.03rem;
                  margin-right: 0.2rem;
                  font-size: 0.85rem;
                }
                &:nth-of-type(2) {
                  position: relative;
                  top: 0.03rem;
                  margin-right: 0.2rem;
                  font-size: 0.8rem;
                }
              }
              span:nth-of-type(1), :nth-of-type(2) {
                color: #676767;
              }
              span:nth-of-type(3) {
                color: #2053c6;
              }
              .reply {
                float: right;
                margin-right: 1.85rem;
                color: #2957b9;
              }
              p {
                color: #2d2d2d;
              }
            }
          }
          .reply_comment {
            margin: 0.5rem 1rem 1rem 2.2rem;
            border: 0.01rem solid #f3f3f3;
            width: 86%;
            padding-bottom: 0.5rem;
            /*display: none;*/
            &:after {
              content: "\0020";
              display: block;
              height: 0;
              clear: both;
            }
            img {
              display: block;
              width: 1.6rem;
              height: 1.6rem;
              margin: 0.5rem 0.5rem 0.15rem 0.5rem;
            }
            div {
              font-size: 0.85rem;
              padding: 0 0.5rem;
              p {
                color: #2d2d2d;
                line-height: 1.2rem;
              }
              i {
                &:nth-of-type(1) {
                  position: relative;
                  top: 0.03rem;
                  margin-right: 0.2rem;
                  font-size: 0.85rem;
                }
                &:nth-of-type(2) {
                  position: relative;
                  top: 0.03rem;
                  margin-right: 0.2rem;
                  font-size: 0.8rem;
                }
              }
              span:nth-of-type(1), :nth-of-type(2) {
                color: #676767;
              }
              span:nth-of-type(3) {
                color: #2053c6;
              }
              .second_reply {
                float: right;
                color: #2957b9;
              }
            }
          }
        }
      }
      .write_comment {
        width: 100%;
        display: none;
        position: fixed;
        bottom: 3.76rem;
        background: white;
        .this_reply_titel {
          margin: 0 auto;
          width: 90%;
          border: 0.01rem solid #f3f3f3;
          height: 10rem;
          div {
            border-bottom: 0.01rem solid #f3f3f3;
            height: 20%;
            line-height: 2rem;
            color: #2858BA;
          }
          textarea {
            width: 96%;
            resize: none;
            height: 70%;
            margin: 0 auto;
            padding-top: 0.5rem;
          }
        }
        .reply_button {
          width: 100%;
          &:after {
            content: "\0020";
            display: block;
            height: 0;
            clear: both;
          }
          div {
            float: right;
            width: 5rem;
            height: 1.5rem;
            margin: 0.5rem 1rem 0.5rem 0;
            background-color: #2858ba;
            color: white;
            text-align: center;
            line-height: 1.5rem;
            border-radius: 0.3rem;
          }
        }
      }
      .choice_column {
        width: 100%;
        display: none;
        position: fixed;
        bottom: 3.76rem;
        background-color: #fff;
        font-size: 0.8rem;
        border-top: 0.0625rem solid #c8c8c8;
        &:after {
          content: "\0020";
          display: block;
          height: 0;
          clear: both;
        }
        div {
          float: left;
          width: 49%;
          height: 3.5rem;
          margin: 0.5rem 0;
          line-height: 2.8rem;
          i {
            color: #2957b7;
            font-size: 0.8rem;
            position: relative;
            right: 0.35rem;
            top: 0.03rem;
          }
          span {
            color: #636363;
          }
          &:nth-of-type(1) {
            border-right: 0.01rem solid #e2e2e2;
            i {
              display: inline-block;
              transform: rotate(180deg);
              transform-origin: center;
              top: -0.1rem;
            }
          }
        }
      }
    }
    .footer {
      height: 3.5rem;
      font-size: 0.8rem;
      padding-top: 0.2rem;
      border-top: 0.0625rem solid #c8c8c8;
    }
    /*尾部新样式*/
    .footer .foot-nav dl dd.selected {
      color: #ea5b21;
    }
    .footer .foot-nav dl dt {
      height: 50%;
      color: #2e59b8;
      line-height: inherit;
    }

    .footer .foot-nav dl dd {
      color: #666666;
    }

    .footer .foot-nav dl dt i {
      font-size: 1.5rem;
      position: relative;
      top: 0.35rem;
    }

    .footer .foot-nav dl:nth-of-type(3) dt i {
      top: 0.5rem;
    }
  }
}