.scratch-work-page {
  .category-bar {
    height: 80px;
    margin-top: 10px;
    padding: 0 10px;
    .category-list {
      margin: 0;
      overflow: hidden;
      padding: 22px 0;
      .category-item {
        float: left;
        list-style: none;
        line-height: 36px;
        padding: 0 30px;
        font-size: 20px;
        height: 36px;
        a {
          color: #666666;
          text-decoration: none;
          display: block;
        }
        &:first-child {
          border-right: 1px solid #666666;
        }

        &.active, &:hover {
          a {
            color: $blue;
            border-bottom: 2px solid $blue;
          }
        }
      }
    }
  }

  .work-list-box {
    padding: 0 20px;
    margin-top: 10px;
    .tag-choose-bar {
      height: 70px;
      padding: 20px 10px;
      a {
        font-size: 16px;
        padding: 1px 20px;
        margin-right: 40px;
        border-radius: 8px;

        &.active {
          background: $blue;
          color: #ffffff;
        }
      }
    }

    .work-list {
      .no-work {
        font-size: 36px;
        color: #ff7272;
        text-align: center;
        padding: 40px;
      }
      .work-item {
        .data-area {
          z-index: 2;
          font-size: 16px;
          color: #333333;
          cursor: auto;
          span {
            display: inline-block;
            width: 31.5%;
            text-align: center;
            a i, i {
              font-size: 24px;
            }
            a i {
              color: #333333;
            }
            i {
              color: #6c1111;
            }
            &.view {
              i {
                color: #333333;
              }
            }

            &:hover {
              a > i {
                color: #6c1111;
              }
            }

            label {
              display: inline;
              margin-bottom: 0;
              font-weight: normal;
              margin-left: 5px;
            }
          }
        }
      }

      .work-item {
        margin-bottom: 20px;
        background: #f2f2f2;
        padding: 0 10px 10px;
        border-radius: 8px;
        position: relative;

        &:hover {
          -webkit-box-shadow: 0 0 15px #999;
          box-shadow: 0 0 15px #999;
          .image-area .hover-area {
            display: block;
          }
          .author-area .author-name {
            color: $blue;
          }
          .description-area {
            color: #444444;
          }
        }

        .title-area {
          font-size: 15px;
          padding: 5px 0;
          position: relative;
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .image-area {
          margin: 0 -10px;
          z-index: 2;
          position: relative;
          .hover-area {
            z-index: 3;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, .6);
            color: #ffffff;
            padding: 10px 20px;
            display: none;
            border-radius: 8px 8px 0 0;
            .date {
              font-size: 16px;
              p {
                margin: 0;
              }
              .year {
                font-size: 18px;
              }
            }
            .work-link {
              text-align: center;
              padding: 15px;
              a {
                padding: 2px 6px;
                color: $blue;
                background: #ffffff;
                text-decoration: none;
                font-size: 16px;

                &:hover {
                  color: #ffffff;
                  background: $blue;
                }
              }
            }
            .share {
              position: absolute;
              bottom: 10px;
              right: 20px;

              & > * {
                display: inline-block;
              }
              label {
                margin: 0;
              }
            }
          }
          img {
            width: 100%;
            height: auto;
            border-radius: 8px 8px 0 0;
            max-height: 203px;
          }
        }
        .description-area {
          font-size: 14px;
          margin-top: 5px;
          padding: 10px 0;
          height: 60px;
          color: #999;
        }

        .data-area {
          z-index: 2;
          font-size: 16px;
          color: #999;
          cursor: auto;
          span {
            display: inline-block;
            width: 31%;
            label {
              display: inline;
              margin-bottom: 0;
              font-weight: normal;
              margin-left: 5px;
            }
          }
        }

        .author-area {
          margin: 0 -10px;
          padding: 10px 10px 0;
          border-top: 1px solid #999;
          img {
            width: 20px;
            height: 20px;
          }
        }

        .border-decoration {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          border-top: 10px solid #05286d;
          border-bottom: 10px solid transparent;
          border-left: 10px solid #05286d;
          border-right: 10px solid transparent;
          border-radius: 8px 0 0 0;
          text-align: center;
          z-index: 3;
        }

      }
    }
  }
}

.work-migration-page {
  p {
    &.description {
      margin-top: 30px;
      padding: 20px;
      font-weight: bold;
      font-size: 18px;
      span {
        font-size: 24px;
      }
    }
  }
  .work-list {
    padding: 20px;
    p {
      &.tip {
        padding-left: 10px;
        padding-top: 10px;
        span {
          font-weight: bold;
        }
      }
    }
    .col-md-3 {
      margin-bottom: 15px;
    }
    .select-work {
      margin-left: 2px;
      margin-top: 3px;
    }
    .work-item {
      background: #fff;
      padding: 10px;
      border: 1px solid #eee;
      padding-left: 16px;
      a {
        img {
          width: 226px;
          height: 175px;
          margin-bottom: 10px;
        }
        p.work-title {
          color: #999;
          font-size: 14px;
          display: inline-block;
          margin-bottom: 2px;
        }
        span {
          color: #999;
          font-size: 14px;
        }
      }
      .is-migrated-icon {
        position: absolute;
        right: 17px;
        bottom: 60px;
        img {
          width: 100px;
          height: auto;
        }
      }
    }
    .submit {
      text-align: center;
      input {
        padding: 9px 40px;
        border-radius: 4px;
      }
    }
  }
}

.show-scratch {
  float: right;
  list-style: none;
  line-height: 36px;
  padding: 0 30px;
  font-size: 14px;
  height: 36px;
  a {
    color: #666666;
    text-decoration: none;
    display: block;
  }
  &:first-child {
    border-right: 1px solid #666666;
  }

  &.active, &:hover {
    a {
      color: $blue;
      border-bottom: 2px solid $blue;
    }
  }
}

#game-list {

  .works {
    width: 100%;
    min-height: 200px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    -webkit-box-shadow: #666 0px 0px 10px;
    -moz-box-shadow: #666 0px 0px 10px;
    box-shadow: #666 0px 0px 10px;
  }

  .works .center {
    margin: 0 auto;
    width: 94%;
    height: auto;
  }

  .works .center .moreworks {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .works .center .moreworks .text {
    float: right;
    font-size: 16px;
  }

  .works .center .moreworks .text a {
    color: #666666;
    text-decoration: none;
  }

  .works .center .moreworks .text a:hover {
    color: #2758b9;
    text-decoration: underline;
  }

  .works .center .work {
    width: 100%;
    min-height: 300px;
  }

  .works .center .work .top {
    width: 100%;
    min-height: 230px;
  }

  .works .center .work .top .img {
    float: left;
    width: 23.98%;
    height: 203px;
    border-radius: 6px;
  }

  .works .center .work .top .topMid {
    float: left;
    width: 39%;
    margin-left: 1.78%;
    height: 235px;
    text-align: left;
    border-right: 1px solid #e3e3e3;
  }

  .works .center .work .top .topMid .smblue {
    color: #3097d1;
    font-size: 15px;
  }

  .works .center .work .top .topMid .lgblue {
    color: #2758b9;
    font-size: 30px;
  }

  .works .center .work .top .topMid .deepgray {
    color: #333;
    font-size: 16px;
  }

  .works .center .work .top .topMid .gray {
    color: #333;
    font-size: 15px;
  }

  .works .center .work .top .topMid a:hover {
    color: #2758b9;
    text-decoration: none;
  }

  .works .center .work .top .topMid a {
    text-decoration: none;
    color: inherit;
  }

  .works .center .work .top .topRight {
    width: 33%;
    margin-left: 1.78%;
    height: 235px;
    text-align: left;
    float: left;
  }

  .works .center .work .top .topRight .center {
    margin-left: 2.643%;
    width: 97.357%;
    min-height: 152px;
  }

  .works .center .work .top .topRight .center .starline {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    color: #666666;
  }

  .works .center .work .top .topRight .center .starline .star {
    height: 14px;
    float: left;
    margin-left: 10px;
    margin-top: 8px;
    background-color: #2758b9;
    border-radius: 15px;
  }

  .works .center .work .top .topRight .center .starline .percent {
    float: left;
    margin-left: 10px;
  }

  .works .center .work .top .topRight .p1 {
    font-size: 20px;
  }

  .works .center .work .top .topRight .p2 {
    font-size: 24px;
    color: #2758b9;
  }

  .works .center .work .top .topRight .stars {
    width: auto;
    float: left;
    position: relative;
    font-size: 24px;
    margin-left: 10px;
  }

  .works .center .work .top .topRight .stars .star-color {
    color: #2758b9;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    height: 28px;
    top: 0;
    left: 0;
    white-space: nowrap;
    float: left;
  }

  .works .center .work .description {
    width: 100%;
    min-height: 100px;
    margin-top: 20px;
    text-align: left;
  }

  .works .center .work .description .p1 {
    font-size: 16px;
  }

  .works .center .work .description .p2 {
    font-size: 16px;
    color: #666666;
  }

  .works .center .work .description a:hover {
    color: #2758b9;
    text-decoration: underline;
  }

  .works .center .work .description a {
    text-decoration: none;
    color: #2758b9;
  }

  .works .center .space {
    width: 100%;
    margin-top: 40px;
    height: 40px;
    border-top: 1px solid #eeeeee;
  }

  .clear {
    clear: both;
  }

  .right {
    float: right;
  }

  .left {
    float: left;
  }

  .margin7 {
    margin-left: -4.68px;
  }
}

.summer-final-game {
  .scroling {
    width: 100%;
    margin-top: 10px;
    .scroling-blocks {
      width: 100%;
      zoom: 1;
      .scroling-block {
        width: 100%;
        height: 250px;
        padding: 10px;
        zoom: 1;
        border: 1px solid #e3e3e3;
        margin-bottom: 25px;
        p {
          margin-bottom: 5px;
          font-size:14px;
        }
        .left-img {
          height: 100%;
          width: 324px;
          padding-right: 20px;
          border-radius: 10px;
          float: left;
          position: relative;
          .show-img {
            max-width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 10px;
            z-index: 0;
            top: 0;
            left: 0;
          }
          .show-img-hover {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 30px;
            width: 304px;
            z-index: 10;
            background-color: #000;
            opacity: 0.3;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          .show-img-tip {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            width: 304px;
            z-index: 20;
            color: #fff;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding-left: 10px;
            i{
              margin: 0px 5px 0px 10px;
              color: #32a2e3;
            }
          }
        }
        .right-block {
          overflow:hidden;
          height: 100%;
          color: #999;
          font-size: 16px;
          .title {
            width: 100%;
            height: 28px;
            color: #000;
            font-size: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .author {}
          .work {
            width: 100%;
            height: 65px;
            border-bottom: 1px dashed #e3e3e3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            margin-bottom: 10px;
          }
          .final-score {
            height: 28px;
            margin-top: 3px;
            width: 100%;
            line-height: 30px;
            zoom: 1;
            position: relative;
            .red-score {
              color: red;
              font-size: 20px;
            }
            .watch-detail {
              height: 100%;
              width: 86px;
              position: absolute;
              bottom: 0;
              right: 0;
              background-color: #32a2e3;
              color: #fff;
              font-size: 16px;
              text-align: center;
              text-decoration: none;
            }
          }
          .final-score:after {
            clear: both;
            content: '.';
            display: block;
            width: 0;
            height: 0;
            visibility: hidden;
          }
        }
      }
      .scroling-block:after {
        clear: both;
        content: '.';
        display: block;
        width: 0;
        height: 0;
        visibility: hidden;
      }
    }
    .scroling-blocks:after {
      clear: both;
      content: '.';
      display: block;
      width: 0;
      height: 0;
      visibility: hidden;
    }
    .c999 {
      color: #999;
    }
    .c666 {
      color: #666;
      font-size: 16px;
    }
  }
}
