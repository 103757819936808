#codepku-front-page {
  @media (min-width: 768px) {
    .highlight a {
      height: 270px;
    }
  }
  @media (min-width: 992px) {
    .highlight a {
      height: 350px;
    }
  }
  @media (min-width: 1200px) {
    .highlight a {
      height: 450px;
    }
  }
  .highlight a {
    width: 100%;
    min-height: 130px;
    display: block;
    background-size: cover;
    background-position: center;
  }

  .tag-choose-bar {
    height: 70px;
    padding: 20px 10px;
    a {
      font-size: 16px;
      padding: 1px 20px;
      margin-right: 40px;
      border-radius: 8px;

      &.active {
        background: $blue;
        color: #ffffff;
      }
    }
  }

  .clear {
    clear: both;
    width: 100%;
  }

  .marl10 {
    margin-left: 10px;
  }

  html,
  body {
    background-color: #f4f4f4;
  }

  .indexImgs .indexImg {
    width: 100%;
    height: 430px;
    text-align: center;
  }
  .indexImgs .indexImg .imgBg1 {
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .indexImgs .indexImg .imgBg1 .img {
    width: 100%;
    height: 430px;
  }
  .indexImgs .indexImg .imgBg2 {
    z-index: 20;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #000;
    opacity: 0.67;
    height: 430px;
  }
  .indexImgs .indexImg .imgBg3 {
    z-index: 30;
    margin: 0 auto;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 430px;
    margin-top: 20px;
  }
  .indexImgs .indexImg .imgBg3 .txtImg {
    width: 1200px;
    height: 340px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 1200px 340px;
    position: relative;
  }
  .indexImgs .indexImg .imgBg3 .txtImg .txt {
    height: 100px;
    position: absolute;
    top: 250px;
    left: 0;
    width: 1200px;
    z-index: 50;
    text-align: left;
    //margin-top: 10px;
  }
  .indexImgs .indexImg .imgBg3 .txtImg .txt .txt1 {
    margin-left: 80px;
    color: #fff;
    font-size: 32px;
  }
  .indexImgs .indexImg .imgBg3 .txtImg .txt .txt2 {
    margin-left: 80px;
    color: #fff;
    font-size: 16px;
  }
  .indexImgs .indexImg .imgBg3 .txtImg .txt .txt3 {
    margin-left: 25px;
    color: #fff;
    font-size: 16px;
  }
  .indexImgs .indexImg .imgBg3 .txtImg .txt .txt4 {
    margin-left: 25px;
    color: #fff;
    font-size: 16px;
  }
  .indexImgs .indexImg .imgBg3 .txtImg .txt .detail {
    float: right;
    margin-right: 60px;
  }
  .indexImgs .indexImg .imgBg3 .txtImg .txt .detail .icon {
    color: #0F3774;
    margin-left: 20px;
  }
  .indexImgs .indexImg .imgBg3 .txtImg .txt .detail .num {
    margin-left: 10px;
    font-size: 16px;
    color: #fff;
  }
  .indexImgs .indexImg .imgBg3 .imgTitle {
    text-align: center;
    height: 90px;
    position: absolute;
    top: 250px;
    background-color: #000;
    opacity: 0.6;
    width: 1200px;
    z-index: 20;
  }

  .imgnews {
    width: 100%;
    height: auto;
    margin-right: -2%;
  }
  .imgnews .imgnew {
    width: 30.2%;
    margin-right: 2.9%;
    border-radius: 10px;
    -webkit-box-shadow: #666 0px 0px 10px;
    -moz-box-shadow: #666 0px 0px 10px;
    box-shadow: #666 0px 0px 10px;
    height: 240px;
    float: left;
    position: relative;
  }
  .imgnews .imgnew:hover .grayImg {
    opacity: 0.5;
  }
  .imgnews .imgnew:hover .txt {
    opacity: 1;
  }
  .imgnews .imgnew .backgroundImgDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: 10px;
    overflow: hidden;
  }
  .imgnews .imgnew .backgroundImgDiv .backgroundImg {
    width: 100%;
    height: 100%;
  }
  .imgnews .imgnew .grayImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: #000;
    opacity: 0;
    border-radius: 10px;
  }
  .imgnews .imgnew .txt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    border-radius: 10px;
    text-align: center;
    opacity: 0;
  }
  .imgnews .imgnew .txt .leftBlue {
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 40px;
    background-color: #2758b9;
    border-top-left-radius: 10px;
    float: left;
  }
  .imgnews .imgnew .txt .sign {
    position: absolute;
    top: 0;
    left: 12px;
    width: 90px;
    height: 40px;
    background-color: #000;
    opacity: 0.6;
    color: #fff;
    float: left;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
  }
  .imgnews .imgnew .txt .center {
    position: absolute;
    top: 0;
    left: 12px;
    min-height: 200px;
    width: 90%;
    margin: 0 auto;
  }
  .imgnews .imgnew .txt .center .title {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    min-height: 56px;
    font-size: 25px;
    color: #fff;
    text-align: left;
  }
  .imgnews .imgnew .txt .center .subtitle {
    position: absolute;
    top: 122px;
    left: 0;
    width: 100%;
    min-height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    text-align: left;
  }
  .imgnews .imgnew .txt .center .author {
    position: absolute;
    top: 162px;
    left: 0;
    border-left: 1px solid #eeeeee;
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    font-size: 14px;
    color: #fff;
    text-align: left;
  }
  .imgnews .imgnew .txt .center .bottom {
    position: absolute;
    top: 192px;
    left: 0;
    width: 100%;
    min-height: 50px;
    line-height: 40px;
    text-align: left;
  }
  .imgnews .imgnew .txt .center .bottom .firsticon {
    color: #0F3774;
  }
  .imgnews .imgnew .txt .center .bottom .icon {
    color: #0F3774;
    margin-left: 4%;
  }
  .imgnews .imgnew .txt .center .bottom .num {
    margin-left: 10px;
    font-size: 16px;
    color: #fff;
  }

  .workArticle {
    background-color: #fff;
    width: 96.5%;
    height: auto;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: #666 0px 0px 1px;
    -moz-box-shadow: #666 0px 0px 1px;
    box-shadow: #666 0px 0px 1px;
    text-align: center;
  }
  .workArticle .menu {
    width: 92%;
    margin: 0 auto;
    text-align: left;
    height: 40px;
    border-bottom: 1px solid #eeeeee;
  }
  .workArticle .menu .normal {
    height: 40px;
    width: auto;
    line-height: 40px;
    float: left;
    color: #999;
  }
  .workArticle .menu .normal a {
    color: #999;
  }
  .workArticle .menu .normal .txt {
    font-size: 14px;
    margin-left: 3px;
  }
  .workArticle .menu .menuactive {
    border-bottom: 1px solid #2758b9;
    color: #6581c7;
  }
  .workArticle .menu .menuactive a {
    color: #6581c7;
  }
  .workArticle .menu a {
    text-decoration: none;
  }
  .workArticle .WorkArticleTab {
    width: 95%;
    margin: 0 auto;
    height: auto;
  }
  .workArticle .WorkArticleTab #latestWorks,
  .workArticle .WorkArticleTab #latestArticles {
    width: 100%;
    height: auto;
    margin-left: 1.1%;
  }
  .workArticle .WorkArticleTab #latestWorks .work,
  .workArticle .WorkArticleTab #latestArticles .work {
    width: 31.1%;
    margin-right: 2%;
    min-height: 350px;
    border: 1px solid #e3e3e3;
    float: left;
    margin-bottom: 20px;
    border-radius: 7px;
    text-align: center;
    position: relative;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg,
  .workArticle .WorkArticleTab #latestArticles .work .workbg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .img,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .img {
    height: 175px;
    width: 100%;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .img .realImg,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .img .realImg {
    width: 100%;
    height: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .title,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .title {
    width: 92%;
    margin: 0 auto;
    font-size: 18px;
    color: #000;
    text-align: left;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .detail,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .detail {
    width: 92%;
    min-height: 40px;
    line-height: 40px;
    margin: 0 auto;
    text-align: left;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .detail .num,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .detail .num {
    width: 92%;
    margin: 0 auto;
    font-size: 16px;
    color: #999;
    text-align: left;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .detail .firstIcon,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .detail .firstIcon {
    color: #999;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .detail .icon,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .detail .icon {
    color: #999;
    margin-left: 5%;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .declaration,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .declaration {
    width: 92%;
    margin: 0 auto;
    font-size: 14px;
    color: #999;
    text-align: left;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .line,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .line {
    height: 6px;
    border-bottom: 1px solid #e3e3e3;
    clear: both;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .authorTime,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .authorTime {
    min-height: 50px;
    line-height: 50px;
    width: 92%;
    margin: 0 auto;
    width: 92%;
    margin: 0 auto;
    text-align: left;
    color: #999;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .authorTime .author,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .authorTime .author {
    margin-left: 8px;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .authorTime .img,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .authorTime .img {
    width: 24px;
    height: 24px;
  }
  .workArticle .WorkArticleTab #latestWorks .work .workbg .authorTime .time,
  .workArticle .WorkArticleTab #latestArticles .work .workbg .authorTime .time {
    float: right;
  }
  .workArticle .WorkArticleTab #latestWorks .work .gray,
  .workArticle .WorkArticleTab #latestArticles .work .gray {
    position: absolute;
    top: 0;
    left: 0;
    height: 175px;
    width: 100%;
    z-index: 20;
    background-color: #000;
    opacity: 0.6;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: #fff;
  }
  .workArticle .WorkArticleTab #latestWorks .work .graytxt,
  .workArticle .WorkArticleTab #latestArticles .work .graytxt {
    position: absolute;
    top: 0;
    left: 0;
    height: 175px;
    width: 100%;
    z-index: 30;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    text-align: center;
  }
  .workArticle .WorkArticleTab #latestWorks .work .graytxt .data,
  .workArticle .WorkArticleTab #latestArticles .work .graytxt .data {
    margin: 0 auto;
    margin-top: 16px;
    width: 92%;
    text-align: left;
    color: #fff;
  }
  .workArticle .WorkArticleTab #latestWorks .work .graytxt .year,
  .workArticle .WorkArticleTab #latestArticles .work .graytxt .year {
    margin: 0 auto;
    height: 30px;
    line-height: 30px;
    width: 92%;
    text-align: left;
    color: #fff;
    font-size: 18px;
  }
  .workArticle .WorkArticleTab #latestWorks .work .intoDetail,
  .workArticle .WorkArticleTab #latestArticles .work .intoDetail {
    margin-top: 15px;
    width: 100%;
    text-align: center;
  }
  .workArticle .WorkArticleTab #latestWorks .work .shares,
  .workArticle .WorkArticleTab #latestArticles .work .shares {
    margin: 0 auto;
    margin-top: 15px;
    height: 40px;
    line-height: 40px;
    width: 92%;
    text-align: right;
  }
  .workArticle .WorkArticleTab #latestWorks .work .shares .share,
  .workArticle .WorkArticleTab #latestArticles .work .shares .share {
    color: #fff;
    font-size: 18px;
    margin-top: 5px;
  }
  .workArticle .WorkArticleTab #latestWorks .line,
  .workArticle .WorkArticleTab #latestArticles .line {
    clear: both;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid #e3e3e3;
  }
  .workArticle .WorkArticleTab #latestWorks .new,
  .workArticle .WorkArticleTab #latestArticles .new {
    text-align: left;
    min-height: 200px;
    width: 100%;
  }
  .workArticle .WorkArticleTab #latestWorks .new .topcontent,
  .workArticle .WorkArticleTab #latestArticles .new .topcontent {
    height: 152px;
    min-height: 152px;
  }
  .workArticle .WorkArticleTab #latestWorks .new .topcontent .title,
  .workArticle .WorkArticleTab #latestArticles .new .topcontent .title {
    font-size: 20px;
    color: #000;
    margin-right: 20px;
    margin-bottom: 12px;
  }
  .workArticle .WorkArticleTab #latestWorks .new .topcontent .author,
  .workArticle .WorkArticleTab #latestArticles .new .topcontent .author {
    font-size: 15px;
    color: #999;
    margin-bottom: 100px;
  }
  .workArticle .WorkArticleTab #latestWorks .new .detail,
  .workArticle .WorkArticleTab #latestArticles .new .detail {
    height: 48px;
    line-height: 48px;
    width: 93%;
    color: #999;
  }
  .workArticle .WorkArticleTab #latestWorks .new .detail .share,
  .workArticle .WorkArticleTab #latestArticles .new .detail .share {
    float: right;
    height: 100%;
    width: 160px;
  }
  .workArticle .WorkArticleTab #latestWorks .new .detail .share .num,
  .workArticle .WorkArticleTab #latestArticles .new .detail .share .num {
    margin-right: 8px;
    font-size: 16px;
    color: #999;
    float: left;
  }
  .workArticle .WorkArticleTab #latestWorks .new .detail .num,
  .workArticle .WorkArticleTab #latestArticles .new .detail .num {
    margin-right: 2%;
    font-size: 16px;
    color: #999;
  }

  .new {
    .data-area {
      //width: 240px;
      float: left;
      span {
        width: 80px;
        float: left;
        i {
          font-size: 18px;
        }
        label {
          font-weight: normal;
          color: $blue;
          margin-left: 5px;
          margin-bottom: 0;
        }
      }
    }
  }

  //a {
  //  text-decoration: none;
  //}
  //
  //a:hover {
  //  color: #2758b9;
  //}

  .blueColor {
    color: #2758b9 !important;
  }

  .nameblue {
    font-weight: bold;
    color: #2758b9;
  }

  .orangeColor {
    color: #f15a24;
  }

  .rightContent {
    width: 100%;
    height: auto;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    text-align: center;
    background-color: #fff;
  }
  .rightContent a {
    text-decoration: none;
  }
  .rightContent a:hover {
    color: $blue;
  }
  .rightContent .intelligenceCenter {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #e3e3e3;
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  .rightContent .intelligenceCenter .text {
    font-size: 18px;
    color: #2758b9;
    margin-left: 3px;
  }
  .rightContent .intelligences {
    margin: 0 auto;
    width: 90%;
    text-align: left;
    margin-top: 8px;
  }
  .rightContent .intelligences .icon {
    color: #2758b9;
    float: left;
    margin-top: 3px;
  }
  .rightContent .intelligences .intelligence {
    font-size: 14px;
    color: #333;
    margin-left: 3px;
    float: left;
    width: 90%;
    display: inline-block;
  }
  .rightContent .tribeMenus {
    height: 40px;
    border-bottom: 1px solid #e3e3e3;
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  .rightContent .tribeMenus .tribeMenu {
    height: 40px;
    width: auto;
    line-height: 40px;
    float: left;
    margin-left: 10px;
  }
  .rightContent .tribeMenus .tribeMenu a {
    color: #999;
    font-size: 18px;
  }
  .rightContent .tribeMenus .active {
    border-bottom: 1px solid #2758b9;
  }
  .rightContent .tribeMenus .active a {
    color: #2758b9;
  }
  .rightContent .tribe {
    height: 55px;
    width: 91%;
    margin: 0 auto;
    margin-top: 8px;
  }
  .rightContent .tribe .imgNum {
    width: 21px;
    height: 21px;
    float: left;
  }
  .rightContent .tribe .img {
    width: 40px;
    height: 40px;
    float: left;
    margin-left: 15px;
  }
  .rightContent .tribe .tex {
    float: left;
    height: 55px;
    margin-left: 20px;
  }
  .rightContent .tribe .auth {
    text-align: left;
    font-size: 16px;
    margin-bottom: 0px;
  }
  .rightContent .tribe .levelRank {
    text-align: left;
    margin-bottom: 0px;
  }
  .rightContent .tribe .levelRank .level {
    color: #999;
    font-size: 15px;
  }
  .rightContent .tribe .levelRank .rank {
    color: #999;
    font-size: 15px;
    float: right;
  }
  .rightContent .recommendedUser {
    height: 40px;
    border-bottom: 1px solid #e3e3e3;
    width: 90%;
    margin: 0 auto;
    text-align: left;
  }
  .rightContent .recommendedUser .img {
    float: left;
  }
  .rightContent .recommendedUser .text {
    height: 40px;
    width: auto;
    line-height: 40px;
    float: left;
    margin-left: 10px;
  }
  .rightContent .recommendedUser .text a {
    font-size: 18px;
    color: #2758b9;
  }
  .rightContent .user {
    min-height: 60px;
    width: 91%;
    margin: 0 auto;
    margin-top: 8px;
  }
  .rightContent .user .imgNum {
    width: 21px;
    height: 21px;
    float: left;
  }
  .rightContent .user .img {
    width: 40px;
    height: 40px;
    float: left;
    margin-left: 11px;
  }
  .rightContent .user .texts {
    float: left;
    height: 60px;
    margin-left: 15px;
  }
  .rightContent .user .authorTitle {
    text-align: left;
    margin-bottom: 0px;
  }
  .rightContent .user .author {
    font-size: 16px;
  }
  .rightContent .user .title {
    font-size: 14px;
    color: #333;
    float: right;
  }
  .rightContent .user .works {
    text-align: left;
    margin-bottom: 0px;
  }
  .rightContent .user .masterpiece {
    color: #999;
    font-size: 15px;
    text-align: left;
  }
  .rightContent .user .workMasterpiece {
    color: #889cd2;
    font-size: 15px;
  }
  .rightContent .triangle-right {
    width: 0;
    height: 0;
    border-width: 3px 0 3px 3px;
    border-style: solid;
    border-color: transparent transparent transparent #6c6;
  }

  .ad {
    width: 100%;
    height: 140px;
    border-radius: 10px;
  }

  .spaceing {
    width: 100%;
    height: 25px;
    clear: both;
  }

  .hidden {
    display: none;
  }

  .hand {
    cursor: pointer;
    cursor: hand;
  }

  @media (min-width: 1400px) {
    .row {
      width: 1200px;
    }
  }
  .redclick {
    color: #DC143C;
  }

  .regeistLand {
    min-height: 120px;
    line-height: 100px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
    background-color: #fff;
  }
  .regeistLand a {
    text-decoration: none;
  }
  .regeistLand .img {
    width: 128px;
    height: 43px;
    display: inline-block;
  }

  .shadow {
    -webkit-box-shadow: #666 0px 0px 10px;
    -moz-box-shadow: #666 0px 0px 10px;
    box-shadow: #666 0px 0px 10px;
  }

  .bigger {
    width: 160%;
    height: 160%;
  }
  .feedback {
    width: 100%;
    height: 950px;
    text-align: center;
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    .fdb {
      background-color: #fff;
      position: fixed;
      top: 8rem;
      margin: 0 auto;
      margin-left: 33.59375%;
      width: 32.8125%;
      height: 30rem;
      text-align: center;
      border-radius: 6px;
      border: 2px solid #e3e3e3;
      #remove {
        float: right;
        margin-right: 0.4rem;
        border: 0px solid;
        background-color: #fff;
      }
      .title {
        margin-top: 1.2rem;
        font-size: 1rem;
        color: #428ce1
      }
      .center {
        margin: 0 auto;
        width: 91.4285%;
      }
      .fbbody {
        margin-top: 1rem;
        height: 13rem;
        border-radius: 0.4rem;
        width: 100%;
        border: 2px solid #e3e3e3;
        margin-bottom: 0.6rem;
      }
      textarea {
        resize: none;
        outline: none;
        margin-top: 0.1rem;
        margin-left: 1%;
        margin-right: 1%;
        width: 98%;
        height: 98%;
        border: 0px solid;
      }
      .otherInfo {
        background-color: #fff;
        width: 100%;
        height: 2.5rem;
        border-radius: 6px;
        border: 1px solid #e3e3e3;
        margin-top: 0.8rem;
        text-align: left;
        input {
          border: none;
          height: 100%;
        }
      }
      .submitline {
        margin-top: 1rem;
        text-align: right;
        .submitbutton {
          width: 4rem;
          height: 2rem;
          border: 1px solid #2758b9;
          color: #2758b9;
          border-radius: 10px;
          margin-top: 0.5rem;
          font-size: 1rem;
          background-color: #fff;
        }
      }
      .selected {
        border: 2px solid #428ce1 !important;
      }
    }
  }

  .zhao {
    width: 100%;
    height: 950px;
    text-align: center;
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: 120;
  }

  #feedbackButton {
    width: 55px;
    height: 55px;
    position: fixed;
    left: 45px;
    display: none;
    bottom: 50px;
    background-color: #2758b9;
    font-size: 18px;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
  }

  .forrelative {
    position: relative;
    width: 100%;
    height: auto;
    .bodecen {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2
    }
  }

  .work-list {
    .no-work {
      font-size: 36px;
      color: #ff7272;
      text-align: center;
      padding: 40px;
    }
    .work-item {
      .data-area {
        z-index: 2;
        font-size: 16px;
        color: #333333;
        cursor: auto;
        span {
          display: inline-block;
          width: 31.5%;
          text-align: center;
          a i, i {
            font-size: 24px;
          }
          a i {
            color: #333333;
          }
          i {
            color: #6c1111;
          }
          &.view {
            i {
              color: #333333;
            }
          }

          &:hover {
            a > i {
              color: #6c1111;
            }
          }

          label {
            display: inline;
            margin-bottom: 0;
            font-weight: normal;
            margin-left: 5px;
          }
        }
      }
    }

    .work-item {
      margin-bottom: 20px;
      background: #f2f2f2;
      padding: 0 10px 10px;
      border-radius: 8px;
      position: relative;

      &:hover {
        -webkit-box-shadow: 0 0 15px #999;
        box-shadow: 0 0 15px #999;
        .image-area .hover-area {
          display: block;
        }
        .author-area .author-name {
          color: $blue;
        }
        .description-area {
          color: #444444;
        }
      }

      .title-area {
        font-size: 16px;
        padding: 5px 0;
        position: relative;
        font-weight: 600;
      }
      .image-area {
        margin: 0 -10px;
        z-index: 2;
        position: relative;
        .hover-area {
          z-index: 3;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(0, 0, 0, .6);
          color: #ffffff;
          padding: 10px 20px;
          display: none;
          border-radius: 8px 8px 0 0;
          .date {
            font-size: 16px;
            p {
              margin: 0;
            }
            .year {
              font-size: 18px;
            }
          }
          .work-link {
            text-align: center;
            padding: 15px;
            a {
              padding: 2px 6px;
              color: $blue;
              background: #ffffff;
              text-decoration: none;
              font-size: 16px;

              &:hover {
                color: #ffffff;
                background: $blue;
              }
            }
          }

          .share {
            position: absolute;
            bottom: 10px;
            right: 20px;

            & > * {
              display: inline-block;
            }
            label {
              margin: 0;
            }
          }
        }

        img {
          width: 100%;
          height: auto;
          border-radius: 8px 8px 0 0;
        }
      }
      .description-area {
        font-size: 14px;
        margin-top: 5px;
        padding: 10px 0;
        height: 60px;
        color: #999;
      }

      .data-area {
        z-index: 2;
        font-size: 16px;
        color: #999;
        cursor: auto;
        span {
          display: inline-block;
          width: 31%;
          label {
            display: inline;
            margin-bottom: 0;
            font-weight: normal;
            margin-left: 5px;
          }
        }
      }

      .author-area {
        margin: 0 -10px;
        padding: 10px 10px 0;
        border-top: 1px solid #999;
        img {
          width: 20px;
          height: 20px;
        }
      }

      .border-decoration {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 10px solid #05286d;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #05286d;
        border-right: 10px solid transparent;
        border-radius: 8px 0 0 0;
        text-align: center;
        z-index: 3;
      }

    }
  }

  .col-lg-34 {
    width: 28.33% !important;
  }

  .col-lg-86 {
    width: 71.67% !important;
  }

  .link-item {
    padding: 15px 0;
    color: #666666;
    position: relative;
    border-bottom: 1px solid #e2e2e2;

    &:last-child {
      border-bottom: hidden;
    }
  }
}

html {
  font-size: 62.5%;
  /* 10÷16=62.5% */
}

@media only screen and (min-width: 481px) {
  html {
    font-size: 94% !important;
    /* 15.04÷16=94% */
  }
}

@media only screen and (min-width: 561px) {
  html {
    font-size: 109% !important;
    /* 17.44÷16=109% */
  }
}

@media only screen and (min-width: 740px) {
  html {
    font-size: 125% !important;
    /* 20÷16=125% */
  }
}

// 移动端
.codePhone {
  width: 100%;
  text-align: center;
  .code-navbar {
    background-color: #fff;
    height: 60px;
    width: 97%;
    line-height: 60px;
    position: relative;
    margin: 0 auto;

    font-size: 16px;
    text-align: left;
    .logo {
      width: 21%;
    }
    .a {
      display: inline-block;
      height: 100%;
      line-height: 60px;
      text-align: center;
      color: #666;
      margin-left: 3%;
      margin-right: 3%;
      letter-spacing: 2px;
      padding-left: 0;
      padding-right: 0;
    }
    button {
      outline: none;
      background-color: #fff;
      border: none;
    }
    .person {
      float: right;
      width: 10%;
      margin-top: 12px;
    }
    .load-regit {
      float: right;
      color: #666;
    }
  }
  .more-navbar {
    position: absolute;
    top: 60px;
    left: 0%;
    height: 50px;
    width: 100%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    line-height: 50px;
    text-align: left;
    background-color: #fff;
    z-index: 100;
    border-top: 1px solid #e3e3e3;
    a {
      display: inline-block;
      margin-right: 2%;
      color: #666;
      font-size: 17px;
    }
    a:last-child {
      margin-right: 0;
    }
  }
  .code-second-navbar {
    background-color: #2758b9;
    height: 50px;
    width: 100%;
    line-height: 50px;
    color: #fff;
    font-size: 18px;
    vertical-align: middle;
    .index1 {
      margin-left: 3%;
    }
    .div1 {
      height: 36px;
      background-color: #5382e0;
      width: auto;
      padding-left: 2%;
      padding-right: 2%;
      margin-top: 7px;
      line-height: 36px;
      margin-right: 3%;
      border-radius: 8px;
      position: relative;
      .to-change {
        position: absolute;
        top: 100%;
        width: 100%;
        height: 7px;
        left: 0;
        background-color: #2758b9;
      }
    }
    .div1-active {
      background-color: #666;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .second-menu {
    background-color: #666;
    width: 100%;
    min-height: 201px;
    text-align: center;
    .a-district {
      width: 90%;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #666;
      margin: 0 auto;
      text-align: left;
      font-size: 16px;
      .a {
        color: #fff;
        margin-left: 2%;
      }
      a {
        text-decoration: none;
      }
      a:active {
        text-decoration: none;
      }
    }
  }
  .text-district {
    width: 97%;
    margin: 0 auto;
    min-height: 200px;
    margin-top: 10px;
    font-size: 16px;
    color: #666;
    .banner-text {
      min-height: 200px;
      width: 100%;
      padding-bottom: 50px;
      border-bottom: 1px solid #e3e3e3;
      .banner {
        width: 100%;
        height: 236px;
        overflow: hidden;
        position: relative;
        .banner-Img {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }
        .banner-gray {
          width: 100%;
          height: 80px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #000;
          opacity: 0.5;
          z-index: 10;
        }
        .banner-text-text {
          width: 96%;
          margin-left: 2%;
          height: 80px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 20;
          font-size: 26px;
          color: #fff;
          text-align: left;
        }
      }
      .subtitle {
        width: 100%;
        margin-top: 20px;
        .name {
          color: #333;
        }
      }
      .real-text {
        margin-top: 10px;
        width: 100%;
        min-height: 40px;
        text-align: left;
      }
      .sub-lan {
        margin-top: 10px;
        width: 100%;
        text-align: left;
        .logo {
          margin-left: 5%;
        }
        .num {
          margin-left: 1%;
        }
      }
    }
    .no-banner-text {
      margin-top: 25px;
      min-height: 200px;
      width: 100%;
      padding-bottom: 50px;
      border-bottom: 1px solid #e3e3e3;
      .banner {
        width: 100%;
        height: 250px;
        overflow: hidden;
        .banner-Img {
          width: 100%;
          max-height: 250px;
        }
      }
      .title {
        width: 100%;
        margin-top: 20px;
        font-size: 20px;
        text-align: left;
      }
      .subtitle {
        width: 100%;
        margin-top: 20px;
        .name {
          color: #333;
        }
      }
      .real-text {
        margin-top: 10px;
        width: 100%;
        min-height: 40px;
        text-align: left;
      }
      .sub-lan {
        margin-top: 10px;
        width: 100%;
        text-align: left;
        .logo {
          margin-left: 5%;
        }
        .num {
          margin-left: 1%;
        }
      }
    }
    .territory {
      height: 275px;
      padding: 10px 10px;
      text-align: center;
      color: #666;
      border: 1px solid #e3e3e3;
      color: #666;
      .title {
        width: 100%;
        height: 30px;
        min-height: 30px;
        text-align: left;
        position: relative;
        .span1 {
          font-size: 18px;
          margin: 0 2px;
        }
        .territory-line {
          position: absolute;
          top: 0;
          left: 81px;
          width: 4px;
          height: 100%;
          background-color: #2758b9;
          margin: 0 3px;
        }
        .span2 {
          font-size: 16px;
          margin-left: 14px;
        }
      }
      .center-district {
        width: 97%;
        margin: 0 auto;
        min-height: 200px;
        margin-top: 10px;
        .district {
          width: 18%;
          float: left;
          border: 1px solid #e3e3e3;
          min-height: 200px;
          text-align: center;
          img {
            margin: 0 auto;
            margin-top: 8px;
            height: 32px;
          }
          p {
            height: 40px;
            text-align: center;
            margin: 0 auto;
            margin-top: 6px;
          }
        }
        .no-last {
          margin-right: 2.5%;
        }
      }
    }

    .tribe-anoucement {
      height: 160px;
      width: 100%;
      padding: 10px 10px;
      text-align: left;
      border: 1px solid #e3e3e3;
      overflow: scroll;
      .title {
        font-size: 18px;
        color: #000;
      }
      li {
        list-style: none;
      }
      ul {
        padding-left: 5px;
      }
      .anouncement {
        margin-top: 10px;
        font-size: 16px;
        color: #666;
      }
    }

    .big-gods {
      min-height: 260px;
      width: 100%;
      padding: 10px 10px;
      text-align: left;
      border: 1px solid #e3e3e3;
      margin-top: 20px;
      .title {
        font-size: 18px;
        color: #000;
      }
      .big-god {
        width: 100%;
        min-height: 50px;
        margin-top: 5px;
        .p1 {
          font-size: 18px;
          margin-bottom: 5px;
        }
        .p2 {
          font-size: 16px;
          color: #666;
        }
      }
    }
  }
  .latest-posts {
    width: 100%;
    min-height: 300px;
    background-color: #fff;
    .post {
      width: 100%;
      min-height: 130px;
      padding: 10px 5px;
      color: #666;
      font-size: 16px;
      border-bottom: 1px solid #e3e3e3;
      .avatar {
        max-width: 10%;
        display: block;
        float: left;
      }
      .post-text {
        float: right;
        width: 87%;
        margin-left: 3%;
        min-height: 130px;
        text-align: left;
        .title {
          color: #333 !important;
          font-size: 18px;
        }
        .subtitle {
          word-wrap: break-word;
          margin-bottom: 5px;
        }
        .author {
          color: #000;
        }
        .school {
          margin-left: 4%;
        }
        .post-attr {
          margin-top: 5px;
          span {
            color: #2758b9;
            margin-left: 1%;
          }
          i {
            margin-left: 8%;
          }
          .span-first {
            margin-left: 0;
          }
        }
      }
    }
  }
  .recommends {
    background-color: #f5f5f5;
    width: 100%;
    color: #666;
    .tribal-popularity-list {
      background-color: #fff;
      margin-top: 5px;
      width: 100%;
      padding: 5px 5px;
      min-height: 100px;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      text-align: left;
      .title {
        font-size: 20px;
        padding-right: 8px;
        border-right: 4px solid #2758b9;
        margin-bottom: 5px;
      }
      .list {
        width: 100%;
        min-height: 40px;
        padding-bottom: 5px;
        margin-top: 5px;
        border-bottom: 1px solid #e3e3e3;
        img {
          max-width: 10%;
          float: left;
        }
        .list-text {
          margin-left: 2%;
          width: 88%;
          float: left;
          &-title {
            font-size: 18px;
            color: #363636;
          }
          &-subtitle {
            font-size: 12px;
          }
        }
      }
      .min-list {
        width: 50%;
        float: left;
        font-size: 18px;
        margin-top: 5px;
        padding-left: 5px;
        .num {
          color: #2758b9;
        }
      }
    }
    .tribal-announcement {
      background-color: #fff;
      margin-top: 10px;
      width: 100%;
      padding: 5px 5px;
      min-height: 100px;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      text-align: left;
      .title {
        font-size: 20px;
        padding-right: 8px;
        border-right: 4px solid #2758b9;
        margin-bottom: 5px;
      }
      ul {
        padding-left: 5px;
        li {
          font-size: 16px;
          margin-top: 5px;
          i {
            margin-right: 5px;
            color: #2758b9;
            font-size: 7px;
          }
        }
      }
    }
    .my-tribals {
      background-color: #fff;
      margin-top: 10px;
      width: 100%;
      padding: 5px 8px;
      min-height: 100px;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      text-align: left;
      margin-bottom: 20px;
      .title {
        font-size: 18px;
        padding-right: 8px;
        border-right: 4px solid #2758b9;
        margin-bottom: 5px;
      }
      .tribals {
        margin-top: 8px;
        padding-bottom: 0px;
        border-bottom: 1px solid #e3e3e3;
        width: 100%;
        .tribal {
          width: 50%;
          float: left;
          img {
            width: 20%;
            float: left;
          }
          .tribal-text {
            width: 75%;
            margin-left: 5%;
            float: left;
            .name {
              color: #363636;
              margin-bottom: 2px;
            }
            .author {
              font-size: 14px;
            }
          }
        }
      }
      .tribals:last-child {
        border-bottom: none;
      }
    }
  }
  .latest-works {
    background-color: #fff;
    width: 100%;
    padding: 5px 8px;
    min-height: 280px;
    text-align: left;
    margin-bottom: 20px;
    .latest-work {
      width: 47%;
      min-height: 200px;
      margin-bottom: 20px;
      border-radius: 5px;
      border: 1px solid #e3e3e3;
      .top-img {
        width: 100%;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
      .work-text {
        width: 100%;
        padding: 5px 8px;
        border-bottom: 1px solid #e3e3e3;
        min-height: 100px;
        .work-title {
          color: #000;
          font-size: 18px;
          margin-top: 5px;
          margin-bottom: 5px;
          .time {
            color: #666;
            margin-left: 1%;
            font-size: 14px;
          }
        }
        .work-attr {
          i {
            display: inline;
          }
          .ddd {
            display: inline-block;
            width: auto;
          }
          .span {
            margin-left: 2%;
            margin-right: 4%;
          }
        }
      }
      .work-author {
        width: 100%;
        min-height: 40px;
        line-height: 40px;
        padding: 0 5px;
        img {
          width: 10%;
        }
        .author {
          margin-left: 2%;
        }
      }
    }
  }
  .code-tribal-post {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
  }
  .tribal-anouncement {
    width: 100%;
    background-color: #f5f5f5;
    min-height: 500px;
    text-align: center;
    padding-top: 10px;
    &s {
      width: 96%;
      margin: 0 auto;
      background-color: #fff;
      border: 1px solid #e3e3e3;
      padding: 5px 5px;
      text-align: left;
      &-title {
        color: #000;
        font-size: 20px;
        padding-right: 10px;
        border-right: 3px solid #2758b9;
      }
      .anouncement {
        width: 100%;
        min-height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #e3e3e3;
        border-top: 1px solid #e3e3e3;
        display: block;
        color: #666;
        font-size: 16px;
      }
      .three {
        width: 100%;
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        height: 70px;
        padding: 10px;
        img {
          float: left;
        }
        .three-text {
          float: left;
          width: 85%;
          margin-left: 4%;
          &-title {
            color: #000;
            font-size: 16px;
            margin-bottom: 5px;
          }
          &-subtitle {
            color: #666;
            font-size: 14px;
          }
        }
      }
      .other-tribal-list {
        display: block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 5%;
        color: #666;
        font-size: 15px;
        span {
          color: #2758b9;
          margin-right: 2%;
        }
      }
    }
  }
  .my-bribal-manage {
    width: 100%;
    background-color: #f5f5f5;
    text-align: left;
    &-bgimg {
      width: 100%;
      max-height: 120px;
    }
    &-texts {
      background-color: #fff;
      width: 100%;
      padding: 10px;
      min-height: 100px;
      position: relative;
      &-avatar {
        position: absolute;
        left: 10px;
        top: -40px;
        max-height: 80px;
      }
      &-title {
        margin-left: 100px;
        width: 70%;
        display: inline-block;
        font-size: 20px;
        color: #666;
      }
      &-left {
        float: left;
        width: 80%;
        min-height: 100px;
        margin-top: 5px;
        color: #666;
        p {
          margin-bottom: 5px;
        }
        span {
          margin-right: 5px;
        }
        i {
          margin-right: 3px;
        }
        .name {
          color: #2758b9;
        }
        .unable {
          color: #999999;
        }
      }
      &-right {
        margin-top: 5px;
        width: 18%;
        margin-right: 2%;
        float: right;
        button {
          width: 98%;
          border-radius: 8px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          background-color: #2758b9;
          border: none;
        }
        button:last-child {
          color: #999 !important;
          background-color: #fff;
          border: 1px solid #e3e3e3;
          margin-top: 10px;
          line-height: 36px;
        }
      }
    }
    &-navibar {
      background-color: #fff;
      margin-top: 10px;
      width: 100%;
      padding: 0 10px;
      height: 40px;
      border-bottom: 1px solid #e3e3e3;
      a {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        color: #555;
        width: 20%;
        text-align: center;
        text-decoration: none;
      }
      div {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        color: #555;
        width: 20%;
        text-align: center;
      }
      .active {
        border-bottom: 1px solid #2758b9;
        color: #2758b9;
      }
      a:active {
        text-decoration: none;
      }
    }
    &-defaults {
      width: 100%;
      min-height: 200px;
      background-color: #fff;
      .code-tribal-post {
        margin-top: 0 !important;
      }
    }
    .tribal-manages {
      width: 100%;
      margin-top: 10px;
      background-color: #fff;
      padding: 10px 15px;
      min-height: 80px;
      .tribal-manage {
        width: 100%;
        border-bottom: 1px solid #e3e3e3;
        min-height: 40px;
        padding-bottom: 8px;
        img {
          float: left;
          max-height: 50px;
        }
        &-text {
          float: left;
          margin-left: 3%;
          width: 76%;
          color: #666;
          span {
            margin-right: 5px;
          }
          .name {
            font-size: 18px;
            color: #444;
          }
          .blue {
            color: #2758b9;
          }
          p {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .see-more {
    display: inline-block;
    margin: 0 auto;
    margin-top: 5px;
    color: #2758b9;
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
}

.clear {
  clear: both;
}

/* 设置滚动条的样式 */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  display: none;
}

/* 滚动槽 */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}