.mobile-page-show {
  height: 100%;
  * {
    box-sizing: unset;
  }
  .page {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 100%;
    //  flex内容区
    .content {
      position: relative;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  /*---------------------------------------------------------------------------------------------适配开始*/
  @media only screen and (max-width: 500px) {
    .header {
      height: 3.66rem;
      font-size: 1rem;
      display: block;
      span, .iconfont {
        display: inline-block;
      }
      .article_top {
        width: 90%;
        margin: 0 auto;
        padding: 0.5rem 0rem;
        border-bottom: 0.0625rem solid #F3F3F3;
        &:after {
          content: "\0020";
          display: block;
          height: 0;
          clear: both;
        }
        img {
          width: 6rem;
          height: 2.5rem;
          float: left;
        }
        .tx_img {
          float: right;
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 2.4rem;
          overflow: hidden;
          border: 0.1rem solid #f3f3f3;
          img {
            width: 100%;
            height: 100%;
          }

        }
      }
      &:after {
        content: "\0020";
        display: block;
        height: 0;
        clear: both;
      }
    }

    /*信息*/
    .content {
      .visit_info {
        width: 100%;
        font-size: 0.5rem;
        margin-top: 0.5rem;
        &:after {
          content: "\0020";
          display: block;
          height: 0;
          clear: both;
        }
        .topbl {
          float: left;
          margin-left: 1.5rem;
          line-height: 1.7rem;
          span {
            color: #959595;
          }
        }
        .topbr {
          float: right;
          margin-right: 1.5rem;
          line-height: 1.5rem;
          span {
            color: #2c56ba;
          }
          i {
            color: #313131;
            &:nth-of-type(1) {
              font-size: 1.2rem;
              position: relative;
              top: 0.2rem;
            }
            &:nth-of-type(2) {
              font-size: 1.2rem;
              position: relative;
              top: 0.15rem;
              left: 0.2rem;
            }
            &:nth-of-type(3) {
              font-size: 0.8rem;
              position: relative;
              top: 0.1rem;
              margin-left: 0.5rem;
            }
          }
        }
      }

      .period_details {
        text-align: left;
        padding: 0rem 1rem 0.6rem;
        h4 {
          &:nth-of-type(1) {
            letter-spacing: 0.05rem;
            font-weight: 500;
            text-align: center;
            line-height: 3rem;
            font-size: 1.2rem;
            color: #333333;
          }
          &:nth-of-type(2) {
            color: #636363;
            font-weight: 500;
          }
        }

        p {
          line-height: 1.5rem;
          text-indent: 2rem;
          font-size: 0.8rem;
          color: #636363;
          letter-spacing: 0.05rem;
          margin-bottom: 1rem;
        }

        img {
          width: 100%;
        }

        .zan {
          width: 100%;
          height: 5.6rem;
          text-align: center;
          overflow: hidden;
          div {
            width: 2rem;
            height: 2rem;
            margin: 0.5rem auto;
            border: 0.05rem solid #ccc;
            border-radius: 2rem;
            padding: 0.3rem;
            margin-top: 0.5rem;
            i {
              font-size: 2rem;
              position: relative;
              top: -0.6rem;
              color: #ccc;
            }
            i.col_on {
              color: white;
            }
          }
          span {
            color: #999999;
          }
        }
      }

      .new_comment {
        text-align: left;
        .the_new_titel {
          font-size: 0.8rem;
          padding: 0.5rem 1rem;
          border-bottom: 0.05rem solid #f3f3f3;
        }

        .comment_moble {
          padding: 0.5rem 1rem;
          .comment_content {
            display: flex;
            flex-direction: row;
            line-height: 1.2rem;
            font-size: 0.85rem;
            img {
              flex: 0.5;
              width: 1.6rem;
              height: 1.6rem;
              margin: 0.3rem 0.5rem 0 0;
            }
            div {
              flex: 7;
              i {
                &:nth-of-type(1) {
                  position: relative;
                  top: 0.03rem;
                  margin-right: 0.2rem;
                  font-size: 0.85rem;
                }
                &:nth-of-type(2) {
                  position: relative;
                  top: 0.03rem;
                  margin-right: 0.2rem;
                  font-size: 0.8rem;
                }
              }
              span {
                &:nth-of-type(1), :nth-of-type(2) {
                  color: #676767;
                }
                &:nth-of-type(3) {
                  color: #2053c6;
                }
              }

              .reply {
                float: right;
                margin-right: 1.85rem;
                color: #2957b9;
              }

              p {
                color: #2d2d2d;
              }
            }
          }

          .reply_comment {
            margin: 0.5rem 1rem 1rem 2.2rem;
            border: 0.01rem solid #f3f3f3;
            width: 86%;
            padding-bottom: 0.5rem;

            &:after {
              content: "\0020";
              display: block;
              height: 0;
              clear: both;
            }

            img {
              display: block;
              width: 1.6rem;
              height: 1.6rem;
              margin: 0.5rem 0.5rem 0.15rem 0.5rem;
            }

            div {
              font-size: 0.85rem;
              padding: 0 0.5rem;
              p {
                color: #2d2d2d;
                line-height: 1.2rem;
              }
              i {
                &:nth-of-type(1) {
                  position: relative;
                  top: 0.03rem;
                  margin-right: 0.2rem;
                  font-size: 0.85rem;
                }
                &:nth-of-type(2) {
                  position: relative;
                  top: 0.03rem;
                  margin-right: 0.2rem;
                  font-size: 0.8rem;
                }
              }
              span {
                &:nth-of-type(1), :nth-of-type(2) {
                  color: #676767;
                }
                &:nth-of-type(3) {
                  color: #2053c6;
                }
              }

              .second_reply {
                float: right;
                color: #2957b9;
              }
            }
          }
        }
      }

      /*评论*/
      /*回复模块*/
      .block_confirm {
        .speak {
          width: 100%;
          display: block;
          background: white;
          .speak_title {
            margin: 0 auto;
            width: 90%;
            border: 0.01rem solid #f3f3f3;
            height: 10rem;
            div {
              border-bottom: 0.01rem solid #f3f3f3;
              height: 20%;
              line-height: 2rem;
              color: #2858BA;
            }
          }

          .speak_button {
            width: 100%;
            &:after {
              content: "\0020";
              display: block;
              height: 0;
              clear: both;
            }
            div {
              float: right;
              width: 5rem;
              height: 1.5rem;
              margin: 0.5rem 3rem 0.5rem 0;
              background-color: #2858ba;
              color: white;
              text-align: center;
              line-height: 1.5rem;
              border-radius: 0.3rem;
            }
          }
        }
      }

      /*回复模块*/
      .write_comment {
        width: 100%;
        display: none;
        position: fixed;
        bottom: 3.35rem;
        background: white;
        .this_reply_titel {
          margin: 0 auto;
          width: 90%;
          border: 0.01rem solid #f3f3f3;
          height: 10rem;
          div {
            border-bottom: 0.01rem solid #f3f3f3;
            height: 20%;
            line-height: 2rem;
            color: #2858BA;
          }
          textarea {
            width: 96%;
            resize: none;
            height: 70%;
            margin: 0 auto;
            padding-top: 0.5rem;
          }
        }

        .reply_button {
          width: 100%;
          &:after {
            content: "\0020";
            display: block;
            height: 0;
            clear: both;
          }
          div {
            float: right;
            width: 5rem;
            height: 1.5rem;
            margin: 0.5rem 1rem 0.5rem 0;
            background-color: #2858ba;
            color: white;
            text-align: center;
            line-height: 1.5rem;
            border-radius: 0.3rem;
          }
        }
      }
    }

    .dz_on {
      background-color: #69a5e1;
    }

    #speak_text {
      width: 96%;
      resize: none;
      height: 70%;
      margin: 0 auto;
      padding-top: 0.5rem;
    }

    /*尾部新样式*/
    .footer {
      height: 3.125rem !important;
      .foot-nav {
        div {
          line-height: 3.12rem;
          i {
            clear: both;
            font-size: 0.6rem;
            color: #2656b8;
            position: relative;
            left: -0.5rem;
          }
          &:nth-of-type(1) i {
            transform: rotate(180deg);
          }
          span {
            display: inline-block;
            color: #636363;
          }
        }
        .praise {
          border-right: 0.05rem solid #e2e2e2;
        }
      }
    }
  }

}