.scratch-loading {
  position: absolute;
  top: 200px;
  left: 10%;
  width: 80%;
}

.fs-editor {
  .navbar-static-top {
    display: none;
  }
  .footer {
    display: none;
  }
  #view-scratch-page {
    .scratch-page-content-right {
      display: none;
    }
    .panel-heading {
      display: none;
    }
    .panel-body .footer-area {
      display: none;
    }
    .scratch-page-bottom {
      display: none;
    }
    .scratch-player {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100% !important;
    }
  }
}

#scratch-form-modal {
  .modal-content {
    .modal-header {
      background: $blue;
      color: #fff;
      span {
        color: #fff;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        background: #a5a4a2;
        display: block;
        font-size: 19px;
      }
    }
    .modal-body {
      padding: 10px 25px 25px 25px;
      label {
        font-weight: 500;
      }
      input[name="point_price"] {
        width: 120px;
        display: inline-block;
        height: 28px;
      }
      .submit-work {
        text-align: center;
        .btn-blue {
          padding: 3px 18px;
          margin-left: 20px;
          border-radius: 6px;
        }
      }
    }
  }
}

#view-scratch-page {
  margin-bottom: 100px;
  #scratch-editor-content {
    margin-top: 20px;
    .panel {
      .panel-heading {
        border-bottom: 0;
        padding: 20px 40px 0 40px;
        .panel-title {
          display: inline-block;
        }
        span {
          font-size: 12px;
          margin-left: 30px;
        }
        a {
          &.delete-work {
            padding: 3px 13px;
            border-radius: 10px;
          }
        }

      }
      .panel-body {
        padding: 0 30px 20px 30px;
        .footer-area {
          padding-top:15px;
          .praise-area {
            text-align: center;
            //padding: 5px;
            //.praise {
            //  i {
            //    font-size: 24px;
            //    color: #666;
            //  }
            //}

            a {
              color: $blue;
              width: 38px;
              height: 38px;
              border-radius: 19px;
              border: 1px solid $blue;
              display: inline-block;
              i {
                font-size: 24px;
                line-height: 36px;
              }

              &.rewards {
                i {
                  font-style: normal;
                  font-size: 20px;
                  font-weight: 600;
                }
              }
              &:hover, &.has-praised {
                color: #fff;
                background-color: $blue;
              }
            }

            span {
              &.like-count {
                font-size: 16px;
              }
            }
          }
          .share-area {
            label {
              float: left;
              padding: 5px;
              font-weight: 500;
              margin-bottom: 0;
            }
          }

          .work-description {
            word-break: break-word;
          }
        }
      }

    }
    .scratch-page-player {
      .scratch-player {
        height: 580px;
        margin-top:10px;
      }
    }

    //  other works
    .author-other-works {
      margin-top: 10px;
      padding: 20px 20px 18px 20px;
      .title {
        margin-top: 0;
        font-size: 20px;
      }
      .work-item {
        padding: 13px 0 10px 0;
        border-bottom: 1px solid #eee;
        img {
          width: 100%;
          margin-top:7px;
        }
        .right-area {
          padding-left: 0;
        }
        .work-info {
          .work-title {
            display: inline-block;
            color: #333;
            text-decoration: none;
            &:hover {
              color: $blue;
            }
          }
          p {
            margin-bottom: 0;
            &.praise-count {
              margin-bottom: 0;
              display: inline-block;
              color: $blue;
              font-size: 20px;
            }
            &.description {
              font-size: 12px;
              word-break: break-word;
            }
            span {
              color: #555;
              font-size: 15px;
              vertical-align: text-bottom;
            }
          }
        }
      }
    }

  }

  //  comment-area
  .scratch-page-bottom {
    #scratch-comments {
      padding: 10px;
      box-shadow: 0 0 5px #888;
      .btn-comment-submit {
        padding: 3px 17px;
        border-radius: 11px;
        font-size: 15px;
      }
    }
  }
}

#comment-list {
  padding: 20px 30px;
  .comments-list {
    padding-left: 0;
    li {
      list-style: none;
      padding-bottom: 20px;
      padding-top: 10px;
      border-bottom: 1px solid #eee;
      .avatar-name {
        color: #333;
        a {
          color: #333;
          &:hover {
            color: $blue;
          }
        }
        img {
          float: left;
          border-radius: 3px;
        }
        span {
          font-size: 16px;
          margin-left: 15px;
        }
      }
      .praise-reply-actions {
        color: #666;
        span {
          font-size:14px;
        }
        a {
          color: #666;
          font-size: 14px;
          &.praise-comment-btn {
            margin-left: 30px;
            i {
              font-size: 20px;
            }
          }
          &.has-praised {
            color: #6c1111;
            i {
              color: #6c1111;
            }
          }
          &.work-comment-reply {
            margin-left: 30px;
          }
        }
        .comment-del {
          display: none;
        }
      }
      .reply-area {
        margin-top: 10px;
        .action-submit-comment {
          float: right;
          margin-top: 10px;
          a {
            border-radius: 10px;
            padding: 4px 13px;
            font-size: 15px;
          }
        }
        .reply-body {
          height: 70px;
          resize: none;
        }
      }
      .scratch-comments-body {
        margin-top: 10px;
      }

      .reply-list {
        margin-top: 10px;
        margin-left: 50px;
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 2px 10px 10px 10px;
        .reply-item {
          .scratch-comments-body {
            padding:10px;
          }
          margin-top: 8px;
        }
      }
    }
  }
}


//  right part
.author-box {
  padding-top: 20px;
  padding-left: 15px;
  a {
    color: #666;
    &:hover {
      color: $blue;
    }
  }
  .top-part {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    img {
      width: 50px;
      height: 50px;
      float: left;
    }
    .base-info {
      float: left;
      margin-left: 6px;
      .author-nickname {
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 18px
      }
      p {
        margin-bottom: 0;
        span {
          font-size: 12px;
          &.experience {
            margin-left: 20px;
          }
        }
        a {
          width: 10%;
          text-align: center;
          font-size: 20px;
          color: #2758b9;
          vertical-align: bottom;
          padding-top: 4px;
          height: 34px;
          margin-left: 10px;
        }
      }
    }
  }
  .tribe-area {
    ul {
      padding:10px 0 0px 0px;
      height:85px;
      margin-bottom: 0;
      overflow: hidden;
      li {
        a {
          color: #666;
          &:hover {
            color: $blue;
          }
        }
        list-style: none;
        padding:1px 0;
        .forum-name {
          padding-right:0;
        }
        .forum-role {

        }
        span {
          &.forum-role {
            color: $blue;
          }
        }
      }
    }

    .fade-btn {
      border-bottom:1px solid #eee;
      a {
        color: #e2e2e2;
        font-size: 20px;
        display: block;
        text-align: center;
      }
    }
  }
  .other-info {
    padding: 0 24px 0 0px;
    .col-md-4 {
      padding:0;
      text-align: center;
    }
    label {
      font-size: 16px;
      font-weight: 500;
    }
    p {
      font-size: 24px;
      color: $blue;
      a {
        color: $blue;
      }
    }
  }
}
