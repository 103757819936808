#user-edit {
  box-shadow: 0px 0px 5px #888888;
  margin-top:30px;
  .edit-box {
    label {
      font-weight:inherit;
    }
    padding:40px 70px;

    //头像
    .avatar {
      &:hover {
        a {
          display: block;
        }
      }
      display: inline-block;
      img {
        border-radius: 12px;
        width:180px;
      }
      a {
        display: none;
        position: absolute;
        top: 50px;
        left: 60px;
        i {
          color: #999;
        }
      }
    }
    .introduce {
      padding:15px 0;
      border-bottom: 1px solid #eee;
      textarea {
        &[disabled] {
          background-color: #fff;
          border:0;
        }
        resize: none;
        overflow: hidden;
        border-color: #eee;
        font-size: 14px;
      }
    }
    .nickname,.real-name,.sex,.birthday {
      padding:15px 0;
      border-bottom: 1px solid #eee;
      input{
        &[disabled] {
          background-color: #fff;
          border:0;
        }
        margin-right:50px;
        height: 36px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.6;
        color: #555555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccd0d2;
        border-radius: 4px;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      }
      select {
        height: 36px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.6;
        color: #555555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccd0d2;
        border-radius: 4px;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        margin-right:50px;
      }
    }
    .birthday {
      input {
        margin-right:65px;
      }
    }
    .modify-btn {
      margin-top:20px;
      .btn-bluewhite {
        padding:0px 12px;
        margin-right:30px;
      }
    }
  }
}

//account setting styles
#account-setting {
  box-shadow: 0px 0px 5px #888888;
  margin-top:30px;
  a {
    color: $blue;
    font-size: 14px;
    &:hover {
      text-decoration: none;
      font-size: 15px;
    }
    &.btn {
      color: #fff;
    }
  }
  #checkAuthModal {
    .modal-content {
      width: 392px;
      .modal-header {
        background: #2758b9;
        color: #fff;
        padding:10px 10px 10px 30px;
        button {
          &.close {
          }
          span {
            width: 22px;
            height: 22px;
            border-radius: 11px;
            color: #2758b9;
            background: #fff;
            display: block;
          }
        }
        .modal-title {
          font-size:17px;
        }
      }
      .modal-body {
        p.remind-text {
          font-size:12px;
          color: #777;
        }
        #check-auth-form {
          width:80%;
          margin:auto;
          .auth-get-code {
            padding:3px 10px;
          }
          .auth-submit {
            padding:3px 10px;
            margin-top:20px;
            font-size:16px;
          }
          .verify-code {
            width: 170px;
          }
        }
        p.auth-question {
          font-size:14px;
          color: #777;
          margin-top:20px;
          text-align: center;
        }
      }
    }
  }
  .account-setting-box {
    padding:40px 70px;

    .account-item {
      padding: 20px 0;
      border-bottom: 1px solid #eee;

      .col-md-2 {
        text-align: right;
        width: 12%;
        label {
          font-weight:500;
        }
      }

      .btn-form {
        padding: 2px 15px;
        font-size:15px;
        margin-top:10px;
      }
      #change-mobile-form , #change-email-form{
        .form-control {
          height:32px;
          width: 185px;
        }
        .form-inline {
          margin-top:10px;
        }
        .verify-code {
          width: 90px;
        }
      }
      #change-pass-form {
        .form-control {
          width:185px;
          height: 32px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

#avatar-choose-modal {
  .modal-header {
    padding-top:3px;
    padding-bottom:3px;
    color: #fff;
    background: #2758b9;
    text-align: center;
  }
  .upload-logo {
    text-align: center;
  }
  .image-area > img {
    max-width:100%;
    height:auto;
  }
  .jcrop-holder {
    margin:auto;
  }
  .modal-content {
    min-height:300px;
  }
  .nav > li > a:hover, .nav > li > a:focus {
    background: none;
    color: $blue;
  }

  .nav-tabs {
    border: none;
    margin-bottom: 20px;
    margin-left:18px;
    li > a {
      color: #555555;
      margin-right: 2px;
      line-height: 1.6;
      border: none;
      border-radius: 0;
      padding: 0 0 0 30px;

    }
    li:first-child a {
      padding: 0 30px 0 0;
      border-right: 1px #666666 solid;
    }

    li.active > a, li.active > a:hover, li.active > a:focus {
      background: none;
      color: $blue;
    }
  }
  .tab-content {
    max-height: 500px;
    overflow-y: auto;
    overflow-x:hidden;
  }
  .logo-box {
    cursor: pointer;
    float: left;
    margin: 0 2% 40px;
    width: 16%;
    .logo-box-image {
      border: 3px solid transparent;
      img {
        width: 100%;
        height: auto;
      }

      &.checked {
        border: 2px solid $blue;
      }
    }
  }

  .choose-confirm,.choose-default-avatar{
    margin-top:25px;
    text-align: center;
    a {
      padding:5px 30px;
    }
  }
}

#message-list {
  margin-top:20px;
  min-height:500px;
  .date-msg-list {
    padding:10px 15px 15px 15px;
    border-bottom: 1px solid #ddd;
    margin:0 30px;
    .type-msg-list {
      margin-bottom: 24px;
      a {
        color: $blue;
      }
      .label-blue {
        font-size: 16px;
        background-color: $blue;
      }
    }

  }
  p {
    &.no-record {
      text-align: center;
      font-size: 40px;
      color: #999;
      padding-top: 100px;
    }
  }
}
i.message-count {
  position: absolute;
  top: 8px;
  right: 2px;
  width: 20px;
  height: 19px;
  display: inline-block;
  color: #fff;
  background-color: #6074bf;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  border-radius: 10px;
}